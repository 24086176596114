import { Box, Collapse, List, ListItem } from '@mui/material'
import useCommonStore from '../../store/useCommonStore'
import { routes } from '../../routes'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
// import i18n from '../../config/i18n'
// import { EN, VI } from '../../config/const'

const ItemBarMenu = ({ path, isOpenSideBarMenu, label, icon }) => {
  const { t } = useTranslation(["menu"])
  const [isActivePath, setIsActivePath] = useState(false)

  return (
    <ListItem key={path} style={{ padding: 0, borderBottom: '1px solid #d4d8d9' }}>
      <NavLink
        to={path}
        className={({ isActive }) => {
          setIsActivePath(isActive)
          return `flex h-full items-center justify-center w-full border-2 border-white hover:border-[#858d8e] ${isActive && 'bg-[#858d8e] !border-[#858d8e]'
            }`
        }}
      >
        <Collapse orientation='horizontal' in={isOpenSideBarMenu} collapsedSize={48}>
          <Box className='flex'>
            <Box className='w-[48px] h-[48px] flex items-center justify-center'>
              {icon(isActivePath ? 'white' : undefined)}
            </Box>
            <span
              className={clsx(`whitespace-nowrap flex items-center`, { 'text-white': isActivePath })}
              style={{ fontWeight: isActivePath ? 600 : 500, minWidth: '150px' }}
            >
              {t(label)}
            </span>
          </Box>
        </Collapse>
      </NavLink>
    </ListItem>
  )
}

const SideBarMenu = () => {
  const { isOpenSideBarMenu } = useCommonStore()

  // const onChangeLanguage = (lang) => {
  //   i18n.changeLanguage(lang)
  // }

  return (
    <div className='relative h-screen items-center bg-white flex flex-col justify-between'>
      <List className='!p-0'>
        {routes.map(({ icon, label, path }) => {
          return <ItemBarMenu key={path} path={path} label={label} icon={icon} isOpenSideBarMenu={isOpenSideBarMenu} />
        })}
      </List>
      {/* <div className='flex w-full gap-2'>
        <div className='p-[10px]' onClick={() => onChangeLanguage(VI)}>VI</div>
        <div className='p-[10px]' onClick={() => onChangeLanguage(EN)} >EN</div>
      </div> */}
    </div>
  )
}

ItemBarMenu.propTypes = {
  path: PropTypes.string,
  isOpenSideBarMenu: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.func
}

export default SideBarMenu
