import PropTypes from 'prop-types'

const CarIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <path
          d='M-64.18 12.217a1.907 1.907 0 0 0-1.908 1.907 1.908 1.908 0 0 0 1.908 1.908 1.908 1.908 0 0 0 1.908-1.908 1.91 1.91 0 0 0-1.908-1.907zm0 2.788a.881.881 0 0 1-.881-.881.881.881 0 0 1 .881-.88.88.88 0 0 1 .88.88.88.88 0 0 1-.88.881z'
          fill={color}
          transform='translate(75.191)'
        />
        <path
          d='M-53.614 12.217a1.907 1.907 0 0 0-1.908 1.907 1.908 1.908 0 0 0 1.908 1.908 1.908 1.908 0 0 0 1.908-1.908 1.91 1.91 0 0 0-1.908-1.907zm0 2.788a.881.881 0 0 1-.881-.881.881.881 0 0 1 .881-.88.88.88 0 0 1 .88.88.88.88 0 0 1-.88.881z'
          fill={color}
          transform='translate(75.191)'
        />
        <path
          d='m-51.068 10.641-3.427-.625-2.917-2.421a1.624 1.624 0 0 0-1.026-.368h-4.3A1.608 1.608 0 0 0-64 7.833l-1.863 2.183h-1.256a1.322 1.322 0 0 0-1.321 1.32v1.614a1.616 1.616 0 0 0 1.614 1.615h.336a2.347 2.347 0 0 1 1.864-2.748 2.347 2.347 0 0 1 2.748 1.863 2.352 2.352 0 0 1 0 .885h5.954a2.347 2.347 0 0 1 1.864-2.748 2.347 2.347 0 0 1 2.748 1.863 2.352 2.352 0 0 1 0 .885h.042a1.323 1.323 0 0 0 1.321-1.321v-1.3a1.313 1.313 0 0 0-1.119-1.303zm-10.911-1.066a.441.441 0 0 1-.44.441h-1.936a.221.221 0 0 1-.22-.22.222.222 0 0 1 .053-.144l1.281-1.5A.44.44 0 0 1-62.906 8h.487a.44.44 0 0 1 .44.44zm2.2 2.055h-.587a.44.44 0 0 1-.44-.441.44.44 0 0 1 .44-.44h.587a.441.441 0 0 1 .441.44.441.441 0 0 1-.44.441zm3.467-1.6-4.2-.009a.442.442 0 0 1-.44-.441V8.438A.44.44 0 0 1-60.511 8h2.111a.443.443 0 0 1 .273.094l1.952 1.541a.22.22 0 0 1 .037.309.218.218 0 0 1-.173.082z'
          fill={color}
          transform='translate(75.191)'
        />
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default CarIcon

CarIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
