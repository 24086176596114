import { Box } from "@mui/material"
import { listVehicleModal } from "../../../utils/common"
import BaseCheckBoxGroup from "../../../common/BaseCheckBoxGroup"

const CategoryContent = () => {
  return (
    <Box>
      <BaseCheckBoxGroup width={500} isIcon={true} childrenWidth={100} listGroup={listVehicleModal} name="categories" label="Categories" />
    </Box>
  )
}

export default CategoryContent