import PropTypes from 'prop-types'

const VolumeIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g transform='translate(-75.219 -636.78)'>
        <path
          d='m86.806 651.967-3.965-3.167a.95.95 0 0 1-.405-.779v-.642a.95.95 0 0 1 1.539-.746l5.9 4.664a.95.95 0 0 1 .361.746v1.461a.95.95 0 0 1-.36.745l-5.9 4.675a.95.95 0 0 1-1.54-.744v-.675a.949.949 0 0 1 .4-.776l3.974-3.209a.949.949 0 0 0-.004-1.553z'
          fill={color}
        />
        <rect width='7.622' height='2.626' rx='.71' transform='translate(92.379 647.441)' fill={color} />
        <rect width='7.622' height='2.609' rx='.621' transform='translate(92.379 654.225)' fill={color} />
      </g>
    </svg>
  )
}

export default VolumeIcon

VolumeIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
