import PropTypes from 'prop-types'

const ODMatrixIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g transform='translate(188.216 -1112.365)'>
        <path
          d='m-165.075 1121.953-6.038 6.039a1.48 1.48 0 0 0-.434 1.047v8.487a.582.582 0 0 1-.582.582h-.938a.582.582 0 0 1-.583-.582v-9.357a1.482 1.482 0 0 1 .434-1.048l6.653-6.652h-1.87a.583.583 0 0 1-.582-.583v-.937a.583.583 0 0 1 .582-.583h3.972a1.481 1.481 0 0 1 1.481 1.481v3.976a.583.583 0 0 1-.583.582h-.929a.583.583 0 0 1-.583-.582v-1.869zm-11.106-2.067a.583.583 0 0 1-.583.582h-1.869l3.767 3.768a.581.581 0 0 1 0 .823l-.663.664a.584.584 0 0 1-.824 0l-3.769-3.77v1.869a.582.582 0 0 1-.582.582h-.93a.582.582 0 0 1-.582-.582v-3.976a1.481 1.481 0 0 1 1.481-1.481h3.971a.583.583 0 0 1 .583.583z'
          fill={color}
        />
        <rect width='3.558' height='3.558' rx='1.223' transform='translate(-178.407 1129.941)' fill={color} />
        <rect width='3.558' height='3.558' rx='1.223' transform='translate(-170.396 1129.941)' fill={color} />
        <rect width='3.558' height='3.558' rx='1.223' transform='translate(-178.407 1134.596)' fill={color} />
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default ODMatrixIcon

ODMatrixIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
