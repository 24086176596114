import { Group, Line, RegularPolygon } from 'react-konva'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { getTriangles } from '../../../utils/common'
import { useDragArea } from '../../../hooks/useDragArea'

const MovementEdit = ({ zone, listStroke }) => {
  const [listMovements, setListMovements] = useState([])
  const { handleAddNewPoints } = useDragArea({ zone })

  const handleUpdateListMovement = useCallback(() => {
    if (zone && zone.pointsLine && zone.pointsLine.length > 0) {
      const list = []

      for (let i = 0; i < zone.pointsLine.length - 1; i++) {
        list.push(
          getTriangles({
            startPoint: zone.pointsLine[i],
            endPoint: zone.pointsLine[i + 1]
          })
        )
      }

      setListMovements(list)
    }
  }, [zone])

  useEffect(() => {
    handleUpdateListMovement()
  }, [handleUpdateListMovement])

  return (
    <Group>
      {listMovements.map(item => {
        return item.list.map((triangle, index) => (
          <RegularPolygon
            key={index}
            x={triangle.x}
            y={triangle.y}
            sides={3}
            radius={8}
            fill='#fff'
            rotation={(item.lineAngle * 180) / Math.PI + (zone.direction !== 'right' ? 90 : 270)}
          />
        ))
      })}
      {listStroke.map((stroke, index) => (
        <Line
          key={index}
          points={stroke.flat()}
          stroke='transparent'
          strokeWidth={10}
          onDblClick={handleAddNewPoints(index)}
          fillEnabled={false}
        />
      ))}
    </Group>
  )
}

export default MovementEdit

MovementEdit.propTypes = {
  zone: PropTypes.object.isRequired,
  listStroke: PropTypes.array
}
