import PropTypes from 'prop-types'

const LevelOfServiceIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <path
          d='M-2011.792-1590.887h-1.283a.643.643 0 0 1-.642-.643v-1.924H-2015v-4.489a1.909 1.909 0 0 1 .148-.737l2.127-5.133a1.94 1.94 0 0 1 .416-.624 1.916 1.916 0 0 1 1.361-.564h7.294a1.916 1.916 0 0 1 1.361.564 1.922 1.922 0 0 1 .416.624l.711 1.714h-1.388l-.507-1.223a.664.664 0 0 0-.14-.208.638.638 0 0 0-.453-.188h-7.294a.645.645 0 0 0-.454.188.644.644 0 0 0-.138.208l-1.431 3.453h2.267v1.282h-2.371a.641.641 0 0 0-.589.387 1.213 1.213 0 0 0-.053.256v3.207h3.012v1.282h-.446v1.924a.642.642 0 0 1-.641.644zm0-4.973a.8.8 0 0 1-.8-.8.8.8 0 0 1 .8-.8.8.8 0 0 1 .8.8.8.8 0 0 1-.8.801z'
          transform='translate(2017.723 1610.056)'
          stroke='transparent'
          strokeMiterlimit='10'
          fill={color}
        />
        <path
          d='M-2011.793-1590.888h-1.283a.642.642 0 0 1-.641-.642v-1.924H-2015v-4.49a1.9 1.9 0 0 1 .148-.736l2.127-5.132a1.911 1.911 0 0 1 .416-.625 1.915 1.915 0 0 1 1.361-.563h7.294a1.918 1.918 0 0 1 1.361.563 1.926 1.926 0 0 1 .416.625l.992 2.392h-1.388l-.788-1.9a.659.659 0 0 0-.14-.208.645.645 0 0 0-.453-.188h-7.294a.645.645 0 0 0-.454.188.64.64 0 0 0-.138.208l-1.431 3.453h3.564v1.282h-3.668a.641.641 0 0 0-.588.387 1.215 1.215 0 0 0-.053.255v3.208h4.309v1.282h-1.743v1.924a.642.642 0 0 1-.643.641zm0-4.972a.8.8 0 0 1-.8-.8.8.8 0 0 1 .8-.8.8.8 0 0 1 .8.8.8.8 0 0 1-.8.801zm2.385-.159h-.461v-1.283h.461v1.282z'
          transform='translate(2022.61 1613.532)'
          stroke='transparent'
          strokeMiterlimit='10'
          fill={color}
        />
        <g>
          <path
            d='M93.109 430.173a.8.8 0 1 1-.8-.8.8.8 0 0 1 .8.8z'
            fill={color}
            transform='translate(-75.219 -409.002)'
          />
          <path d='M94.231 429.531h5.132v1.283h-5.132z' fill={color} transform='translate(-75.219 -409.002)' />
          <path
            d='M100.486 430.173a.8.8 0 1 0 .8-.8.8.8 0 0 0-.8.8z'
            fill={color}
            transform='translate(-75.219 -409.002)'
          />
          <path
            d='m104.348 428.153-2.127-5.132a1.923 1.923 0 0 0-1.777-1.187H93.15a1.918 1.918 0 0 0-1.361.564 1.94 1.94 0 0 0-.416.623l-2.127 5.132a1.923 1.923 0 0 0-.147.737v4.49h1.283v1.92a.643.643 0 0 0 .642.641h1.283a.643.643 0 0 0 .641-.641v-1.92h7.7v1.92a.643.643 0 0 0 .641.641h1.283a.643.643 0 0 0 .642-.641v-1.92h1.286v-4.49a1.923 1.923 0 0 0-.152-.737zm-11.79-4.64a.642.642 0 0 1 .592-.4h7.294a.643.643 0 0 1 .454.188.636.636 0 0 1 .138.208l1.431 3.453h-11.34zm10.654 8.584h-12.83v-3.207a1.238 1.238 0 0 1 .053-.254.641.641 0 0 1 .589-.388h11.546a.641.641 0 0 1 .589.388 1.238 1.238 0 0 1 .053.254z'
            fill={color}
            transform='translate(-75.219 -409.002)'
          />
        </g>
      </g>
    </svg>
  )
}

export default LevelOfServiceIcon

LevelOfServiceIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
