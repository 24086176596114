import PropTypes from 'prop-types'

const TimeOfOccurrenceTime = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Popup_Time of occurrence_Time blocks" width="28" height="28" viewBox="0 0 28 28">
      <g data-name="Group 1194">
        <path data-name="Path 512" d="M1.244 438.748v-11.34a.648.648 0 0 1 .647-.648h1.933a.648.648 0 0 1 .648.648v.035a.648.648 0 0 1-.648.647h-.207a.648.648 0 0 0-.648.648v8.68a.648.648 0 0 0 .648.648h.206a.647.647 0 0 1 .648.647v.035a.647.647 0 0 1-.648.647H1.891a.647.647 0 0 1-.647-.647" transform="translate(2.767 -418.76)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 513" d="M25.254 427.408v11.34a.648.648 0 0 1-.648.648h-1.932a.648.648 0 0 1-.648-.648v-.035a.647.647 0 0 1 .648-.647h.206a.648.648 0 0 0 .648-.648v-8.68a.648.648 0 0 0-.648-.648h-.206a.647.647 0 0 1-.648-.647v-.035a.648.648 0 0 1 .648-.648h1.932a.648.648 0 0 1 .648.648" transform="translate(-1.123 -418.76)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 514" d="M25.072 435.088h-5.517a.615.615 0 0 1-.615-.615v-.211a.615.615 0 0 1 .615-.615h5.517a.615.615 0 0 1 .615.615v.211a.615.615 0 0 1-.615.615" transform="translate(-.545 -420.049)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 515" d="M15.6 435.088h-5.515a.615.615 0 0 1-.615-.615v-.211a.615.615 0 0 1 .615-.615H15.6a.615.615 0 0 1 .615.615v.211a.615.615 0 0 1-.615.615" transform="translate(1.227 -420.049)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 516" d="M6.132 435.088H.615a.615.615 0 0 1-.615-.615v-.211a.615.615 0 0 1 .615-.615h5.517a.615.615 0 0 1 .615.615v.211a.615.615 0 0 1-.615.615" transform="translate(3 -420.049)" style={{ fill: "#6d7374" }} />
      </g>
      <path data-name="Rectangle 668" style={{ fill: "none" }} d="M0 0h28v28H0z" />
    </svg>
  )
}

export default TimeOfOccurrenceTime

TimeOfOccurrenceTime.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
