import PropTypes from 'prop-types'

const DefinitionIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <g>
          <path
            d='M3.453-152.723h1.459v2.918a.729.729 0 0 0 .729.729h6.565v5.106H8.559a.729.729 0 0 0-.729.73v4.377a.729.729 0 0 0 .729.729h8.753a.729.729 0 0 0 .729-.729v-4.377a.729.729 0 0 0-.729-.73h-3.647v-5.106h6.565a.729.729 0 0 0 .729-.729v-2.918h1.459a.729.729 0 0 0 .729-.73v-4.376a.729.729 0 0 0-.729-.73h-4.376a.729.729 0 0 0-.729.73v4.376a.729.729 0 0 0 .729.73H19.5v2.188h-5.835v-2.188h1.459a.729.729 0 0 0 .729-.73v-4.376a.729.729 0 0 0-.729-.73h-4.377a.729.729 0 0 0-.729.73v4.376a.729.729 0 0 0 .729.73h1.459v2.188H6.371v-2.188H7.83a.729.729 0 0 0 .729-.73v-4.376a.729.729 0 0 0-.729-.73H3.453a.729.729 0 0 0-.729.73v4.376a.729.729 0 0 0 .729.73z'
            transform='translate(3.064 164.347)'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
}

export default DefinitionIcon

DefinitionIcon.propTypes = {
  color: PropTypes.string
}
