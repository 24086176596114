import PropTypes from 'prop-types'

const HeatMapIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <path
          d='M-175.808 1016.483h2.005a.5.5 0 0 1 .5.5v2.005a.5.5 0 0 1-.5.5h-2.005a.5.5 0 0 1-.5-.5v-2.005a.5.5 0 0 1 .5-.5z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-175.3 1021.3h1a.6.6 0 0 1 .6.6v1a.6.6 0 0 1-.6.6h-1a.6.6 0 0 1-.6-.6v-1a.6.6 0 0 1 .6-.6z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-170.685 1017.091h.588a.6.6 0 0 1 .6.6v.588a.6.6 0 0 1-.6.6h-.588a.6.6 0 0 1-.6-.6v-.588a.6.6 0 0 1 .6-.6z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-170.685 1021.505h.588a.6.6 0 0 1 .6.6v.588a.6.6 0 0 1-.6.6h-.588a.6.6 0 0 1-.6-.6v-.588a.6.6 0 0 1 .6-.6z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-179.4 1017.205h.36a.6.6 0 0 1 .6.6v.36a.6.6 0 0 1-.6.6h-.36a.6.6 0 0 1-.6-.6v-.36a.6.6 0 0 1 .6-.6z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-174.954 1026.168h.3a.5.5 0 0 1 .5.5v.3a.5.5 0 0 1-.5.5h-.3a.5.5 0 0 1-.5-.5v-.3a.5.5 0 0 1 .5-.5z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-170.54 1026.168h.3a.5.5 0 0 1 .5.5v.3a.5.5 0 0 1-.5.5h-.3a.5.5 0 0 1-.5-.5v-.3a.5.5 0 0 1 .5-.5z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-175.147 1012.629h.684a.6.6 0 0 1 .6.6v.684a.6.6 0 0 1-.6.6h-.684a.6.6 0 0 1-.6-.6v-.684a.6.6 0 0 1 .6-.6z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-179.4 1013.073h.362a.317.317 0 0 1 .317.317v.362a.317.317 0 0 1-.317.317h-.362a.317.317 0 0 1-.317-.317v-.362a.317.317 0 0 1 .317-.317z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-179.4 1021.9h.362a.317.317 0 0 1 .317.317v.362a.317.317 0 0 1-.317.317h-.362a.317.317 0 0 1-.317-.317v-.362a.317.317 0 0 1 .317-.317z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-179.4 1026.315h.362a.317.317 0 0 1 .317.317v.362a.317.317 0 0 1-.317.317h-.362a.317.317 0 0 1-.317-.317v-.362a.317.317 0 0 1 .317-.317z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-166.147 1026.347h.339a.3.3 0 0 1 .3.3v.338a.3.3 0 0 1-.3.3h-.339a.3.3 0 0 1-.3-.3v-.338a.3.3 0 0 1 .3-.3z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-170.625 1012.926h.468a.411.411 0 0 1 .411.411v.468a.411.411 0 0 1-.411.411h-.468a.411.411 0 0 1-.411-.411v-.468a.411.411 0 0 1 .411-.411z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-166.173 1013.033h.391a.344.344 0 0 1 .343.343v.391a.343.343 0 0 1-.343.342h-.391a.343.343 0 0 1-.343-.342v-.391a.344.344 0 0 1 .343-.343z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-166.2 1021.792h.44a.387.387 0 0 1 .387.387v.44a.387.387 0 0 1-.387.387h-.44a.387.387 0 0 1-.387-.387v-.44a.387.387 0 0 1 .387-.387z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-166.23 1017.288h.506a.444.444 0 0 1 .444.444v.506a.444.444 0 0 1-.444.444h-.506a.444.444 0 0 1-.445-.444v-.506a.444.444 0 0 1 .445-.444z'
          transform='translate(188.951 -1003.84)'
          fill={color}
        />
        <path
          d='M-180.277 1008.84h-1.08a.115.115 0 0 0-.116.115v1.621a.741.741 0 0 1-.741.742h-1.621a.115.115 0 0 0-.116.115v1.081a.116.116 0 0 0 .116.115h2.932a.742.742 0 0 0 .742-.741v-2.933a.115.115 0 0 0-.116-.115z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-161.246 1012.514v-1.081a.114.114 0 0 0-.115-.115h-1.621a.742.742 0 0 1-.742-.742v-1.621a.115.115 0 0 0-.115-.115h-1.081a.115.115 0 0 0-.115.115v2.933a.741.741 0 0 0 .741.741h2.933a.114.114 0 0 0 .115-.115z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-164.92 1031.544h1.081a.114.114 0 0 0 .115-.115v-1.621a.742.742 0 0 1 .742-.742h1.621a.114.114 0 0 0 .115-.115v-1.081a.115.115 0 0 0-.115-.115h-2.933a.741.741 0 0 0-.741.741v2.933a.114.114 0 0 0 .115.115z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
        <path
          d='M-183.951 1027.87v1.081a.115.115 0 0 0 .116.115h1.621a.742.742 0 0 1 .741.742v1.621a.115.115 0 0 0 .116.115h1.08a.115.115 0 0 0 .116-.115v-2.929a.742.742 0 0 0-.742-.742h-2.932a.116.116 0 0 0-.116.112z'
          fill={color}
          transform='translate(188.951 -1003.84)'
        />
      </g>
      <path data-name='Rectangle 408' fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default HeatMapIcon

HeatMapIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
