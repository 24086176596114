import { useMemo } from 'react'
import { Circle, Group, Text } from 'react-konva'
import PropTypes from 'prop-types'
import { dragBoundFunc } from '../../utils/zone'
import useVideoZoneStore from '../../store/useVideoZoneStore'
import useModalStore from '../../store/useModalStore'

const width = 10
const Dot = props => {
  const { zone, stage, point, index, nameGroup, handlePointDragMove } = props

  const { dotIndexDelete, setDotIndexDelete } = useVideoZoneStore(state => state.paramsZone)
  const { deletePointDot } = useVideoZoneStore(state => state.editZone)

  const { setStatusModal } = useModalStore()

  const positionPoint = useMemo(() => {
    const x = point[0] - width + 20 / 2
    const y = point[1] - width + 20 / 2

    return {
      x,
      y
    }
  }, [point])

  const DotContent = useMemo(() => {
    if (index === dotIndexDelete) {
      return (
        <>
          <Circle radius={15} strokeWidth={3} stroke='white' />
          <Circle radius={10} fill='red' />
          <Text
            text='X'
            fontSize={14}
            align='center'
            offsetX={5}
            offsetY={6}
            verticalAlign='middle'
            fontFamily='Arial'
            fill='white'
          />
        </>
      )
    } else {
      return (
        <>
          <Circle width={width} height={width} fill='white' stroke='black' strokeWidth={3} />
        </>
      )
    }
  }, [index, dotIndexDelete])

  const handleDoubleClick = () => {
    if (index !== dotIndexDelete) {
      setDotIndexDelete(index)
    }
  }

  const handleClickDot = () => {
    if (dotIndexDelete === index) {
      if (
        (zone.type === 'zone' && zone.pointsLine.length === 3) ||
        zone.type === 'traffic' ||
        (zone.type !== 'zone' && zone.pointsLine.length === 2)
      ) {
        setStatusModal('confirmDeleteZone')
      } else {
        deletePointDot({ indexPosition: index })
        setDotIndexDelete(-1)
      }
    }
  }

  return (
    <Group
      name='dots'
      x={positionPoint.x}
      y={positionPoint.y}
      draggable
      onDragMove={handlePointDragMove(nameGroup, index)}
      dragBoundFunc={pos => dragBoundFunc(stage.width(), stage.height(), width, pos)}
      onDblClick={handleDoubleClick}
      onMouseUp={handleClickDot}
    >
      {DotContent}
    </Group>
  )
}

Dot.displayName = 'Dot'

Dot.propTypes = {
  stage: PropTypes.any,
  nameGroup: PropTypes.string,
  point: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  zone: PropTypes.object.isRequired,

  handlePointDragMove: PropTypes.func.isRequired
}

export default Dot
