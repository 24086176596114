import PropTypes from 'prop-types'

const ArrowHideIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='13.682' height='12.535' viewBox='0 0 13.682 12.535'>
      <path
        d='m1489.89 83.783 4.853 4.853 4.853-4.853'
        transform='rotate(90 789.264 -699.213)'
        fill='none'
        stroke={color}
        strokeLinecap='round'
        strokeWidth='2px'
      />
      <path
        d='m1489.89 83.783 4.853 4.853 4.853-4.853'
        transform='rotate(90 792.264 -696.213)'
        fill='none'
        stroke={color}
        strokeLinecap='round'
        strokeWidth='2px'
      />
    </svg>
  )
}

export default ArrowHideIcon

ArrowHideIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
