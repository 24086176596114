import { produce } from 'immer'

export const createParamsZone = set => ({
  drawType: '', // Draw Type: zoneGate | movement
  hideZone: '',
  selectedZone: {},
  page: 1,

  displaySetting: {
    gate: true,
    zone: true,
    movement: true,
    traffic: true,
    grid: false,
    anonymization: false
  },

  flagSetting: {
    category: true,
    color: true,
    speed: true,
    acceleration: true,
    licensePlate: true,
    id: true,
    stationaryDuration: true,
    trajectoryTails: true,
    boundingBoxes: true,
    durationOfOccurrence: true,
    score: true
  },

  trajectorySettings: {
    car: true,
    van: true,
    light: true,
    heavy: true,
    bus: true,
    motorcycle: true,
    bicycle: true,
    pedestrian: true
  },

  dotIndexDelete: -1,

  setDotIndexDelete: value =>
    set(
      produce(state => {
        state.paramsZone.dotIndexDelete = value
      })
    ),
  updatePageSetting: () =>
    set(
      produce(state => {
        if (state.paramsZone.page === 4) {
          state.paramsZone.page = 1
        } else {
          state.paramsZone.page += 1
        }
      })
    ),
  updateFlagSetting: key =>
    set(
      produce(state => {
        state.paramsZone.flagSetting = {
          ...state.paramsZone.flagSetting,
          [key]: !state.paramsZone.flagSetting[key]
        }
      })
    ),
  updateTrajectorySettings: key =>
    set(
      produce(state => {
        state.paramsZone.trajectorySettings = {
          ...state.paramsZone.trajectorySettings,
          [key]: !state.paramsZone.trajectorySettings[key]
        }
      })
    ),
  updateDisplaySetting: key =>
    set(
      produce(state => {
        state.paramsZone.displaySetting = {
          ...state.paramsZone.displaySetting,
          [key]: !state.paramsZone.displaySetting[key]
        }
      })
    ),
  setDrawType: value =>
    set(
      produce(state => {
        state.paramsZone.drawType = value
      })
    ),
  setHideZone: value =>
    set(
      produce(state => {
        state.paramsZone.hideZone = value
      })
    ),

  setSelectedZone: value =>
    set(
      produce(state => {
        state.paramsZone.selectedZone = value
      })
    ),

  // Get distance movement zone
  startPointSelect: {
    x: 0,
    y: 0
  },
  setStartPointSelect: value =>
    set(
      produce(state => {
        state.paramsZone.distanceMove = value
      })
    )
})
