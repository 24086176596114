import { produce } from 'immer'
import { create } from 'zustand'

const useCommonStore = create(set => ({
  status: 'default',
  isOpenSideBarMenu: true,
  isFullFlow: false,
  areaShow: [],
  isPointerEventNone: false,

  updateStatusPointerEvent: value =>
    set(
      produce(state => {
        state.isPointerEventNone = value
      })
    ),
  setAreaShow: value =>
    set(
      produce(state => {
        state.areaShow = value
      })
    ),
  setStatus: value =>
    set(
      produce(state => {
        state.status = value
      })
    ),
  setIsOpenSideBarMenu: value =>
    set(
      produce(state => {
        state.isOpenSideBarMenu = value
      })
    ),
  setIsOpenFullFlow: value =>
    set(
      produce(state => {
        state.isFullFlow = value
      })
    )
}))

export default useCommonStore
