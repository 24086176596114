import PropTypes from 'prop-types'

const ControlOperatorsIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <path
          d='M-2000.36-1586.507h-10.788a3.856 3.856 0 0 1-3.852-3.852v-10.788a3.856 3.856 0 0 1 3.852-3.852h10.788a3.856 3.856 0 0 1 3.852 3.852v10.788a3.856 3.856 0 0 1-3.852 3.852zm-6.03-8.135a.555.555 0 0 0-.554.554l-.568 5.467a.554.554 0 0 0 .553.554h2.061a.555.555 0 0 0 .554-.554l-.646-5.467a.554.554 0 0 0-.553-.554zm.636-8.8a3.777 3.777 0 0 0-3.773 3.773 3.777 3.777 0 0 0 3.773 3.773 3.777 3.777 0 0 0 3.773-3.773 3.777 3.777 0 0 0-3.773-3.77z'
          transform='translate(2021.754 1611.754)'
          fill={color}
          stroke='transparent'
          strokeMiterlimit='10'
        />
      </g>
    </svg>
  )
}

export default ControlOperatorsIcon

ControlOperatorsIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
