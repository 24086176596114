import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import BaseCheckGate from '../../../common/BaseCheckGate'
import BaseRadioGroup from '../../../common/BaseRadioGroup'
import { listTimeModeModal } from '../../../utils/common'
import { getTimeModeDescription } from '../../../utils/modal'
import BaseColorGroupForm from '../../../common/BaseColorGroupForm'

const ODMatrixContent = () => {
  const { watch } = useFormContext()

  const data = watch()
  const { time_mode } = data

  return (
    <Box>
      <p className='text-[#444c4d] mt-[12px] font-bold text-[18px]'>Operation mode</p>
      <div className='flex justify-around mt-[8px]'>
        <BaseCheckGate name='origin_element' label='Origin elements' />
        <BaseCheckGate name='destination_element' label='Destination elements' />
      </div>

      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listTimeModeModal}
        name='time_mode'
        label='Time mode'
      />

      <p className='text-[#444c4d] mt-[12px]'>{getTimeModeDescription(time_mode).text}</p>

      <BaseColorGroupForm
        name='colors'
        title='Coloring'
        description='Choose the desired color and range values of your widget.'
      />
    </Box>
  )
}

export default ODMatrixContent
