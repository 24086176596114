import PropTypes from 'prop-types'
import { Box, TextField, Typography } from '@mui/material'

const BaseSetTextField = ({ label, direction = 'row', customClassNameInput = '' }) => {
  return (
    <Box
      sx={{
        mt: '8px',
        display: 'flex',
        alignItems: direction === 'row' ? 'center' : 'normal',
        gap: '8px',
        flexDirection: direction
      }}
    >
      <Typography>{label}</Typography>
      <TextField placeholder='0d00h00m' size='small' className={customClassNameInput} />
    </Box>
  )
}

export default BaseSetTextField

BaseSetTextField.propTypes = {
  label: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  customClassNameInput: PropTypes.string.isRequired
}
