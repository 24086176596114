import PropTypes from 'prop-types'

const TimeOfOccurrenceIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g transform='translate(-73.349 -145.368)'>
        <path
          d='M9.852 21.25v-2.7a6.265 6.265 0 1 0-1.934 0v2.7a8.952 8.952 0 0 1-7.655-6.71 8.976 8.976 0 0 1 .989-6.72 8.7 8.7 0 0 1 3.27-3.22 8.878 8.878 0 0 1 4.317-1.113A9.039 9.039 0 0 1 13.514 4.8l.8-.8-.711-.71 1.244-1.244 2.665 2.664-1.243 1.244-.712-.712-.622.622a8.717 8.717 0 0 1 2.755 5.253 8.972 8.972 0 0 1-1.244 5.854 8.716 8.716 0 0 1-6.593 4.279zM8 2.666v-.888H6.228V0h5.331v1.778H9.782v.886a6.494 6.494 0 0 0-.889-.064A6.274 6.274 0 0 0 8 2.666z'
          transform='translate(80.463 150.47)'
          fill={color}
          stroke='transparent'
          strokeMiterlimit='10'
        />
        <rect width='1.365' height='4.37' rx='.525' transform='translate(90.094 167.995)' fill={color} />
        <rect width='1.365' height='4.37' rx='.514' transform='translate(87.238 167.995)' fill={color} />
        <rect width='1.971' height='6.313' rx='.742' transform='rotate(90 -28.899 121.473)' fill={color} />
        <rect width='1.971' height='4.398' rx='.649' transform='rotate(135 16.94 98.007)' fill={color} />
        <path
          d='M92.119 164.23a1.136 1.136 0 1 0-2.271 0 41.427 41.427 0 0 0 .783 4.336.361.361 0 0 0 .7 0 41.406 41.406 0 0 0 .788-4.336z'
          transform='translate(-1.612 -2.185)'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default TimeOfOccurrenceIcon

TimeOfOccurrenceIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
