import PropTypes from 'prop-types'

const OperatorIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <path
        d='M12.037 11.7h11.519a.715.715 0 0 0 .716-.716v-4.3a.716.716 0 0 0-.716-.716H12.037a.717.717 0 0 0-.716.716v1.435H8.455a.716.716 0 0 0-.716.716v6.449h-.011v1.432h.011v6.448a.716.716 0 0 0 .716.716h2.866v1.434a.717.717 0 0 0 .716.716h11.519a.716.716 0 0 0 .716-.716v-4.3a.715.715 0 0 0-.716-.716H12.037a.716.716 0 0 0-.716.716v1.432H9.172v-5.73h2.149v1.433a.717.717 0 0 0 .716.716h11.519a.716.716 0 0 0 .716-.716v-4.3a.716.716 0 0 0-.716-.716H12.037a.717.717 0 0 0-.716.716v1.434H9.172v-5.73h2.149v1.432a.716.716 0 0 0 .716.715z'
        fill={color}
      />
    </svg>
  )
}

export default OperatorIcon

OperatorIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
