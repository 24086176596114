import PropTypes from 'prop-types'

const GridIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <path
          d='M21.275-36.2H5.186a2.465 2.465 0 0 0-2.462 2.462v16.089a2.465 2.465 0 0 0 2.462 2.462h16.089a2.465 2.465 0 0 0 2.462-2.462v-16.085a2.465 2.465 0 0 0-2.462-2.466zm.821 2.462v3.94h-4.761v-4.761h3.94a.821.821 0 0 1 .825.825zM10.768-23.227v-4.926h4.926v4.926zm4.926 1.641v4.761h-4.926v-4.761zm-6.568-1.641H4.365v-4.926h4.761zm1.641-6.567v-4.761h4.926v4.761zm6.567 1.641H22.1v4.926h-4.765zm-12.149-6.4h3.94v4.761h-4.76v-3.94a.821.821 0 0 1 .821-.823zm-.821 16.91v-3.94h4.762v4.761h-3.94a.821.821 0 0 1-.821-.823zm16.91.821h-3.94v-4.761H22.1v3.94a.821.821 0 0 1-.825.819z'
          fill={color}
          transform='translate(2.77 41.689)'
        />
      </g>
    </svg>
  )
}

export default GridIcon

GridIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
