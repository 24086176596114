import PropTypes from 'prop-types'

const MovementIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <path
          d='M24.667-226.824c-9.412-10.11-18.29-2.823-12.681.539 7.691 4.608 1.433 9.59-2.117 7.349'
          transform='translate(-.758 242.654)'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.781'
          fill='none'
        />
        <path
          d='m23.948-229.232-2.356 3.659a.662.662 0 0 0 .53 1.02l4.144.165a.857.857 0 0 0 .782-1.271l-1.964-3.536a.662.662 0 0 0-1.136-.037z'
          transform='translate(.678 242.957)'
          fill={color}
        />
        <path
          d='M7.93-224.158 5.574-220.5a.662.662 0 0 0 .53 1.02l4.144.164a.855.855 0 0 0 .782-1.27l-1.964-3.537a.663.663 0 0 0-1.136-.035z'
          transform='translate(-1.302 243.584)'
          fill={color}
        />
        <path
          d='m15.8-234.116-1.546 4.069a.663.663 0 0 0 .731.889l4.088-.7a.855.855 0 0 0 .5-1.4l-2.654-3.053a.664.664 0 0 0-1.119.195z'
          transform='translate(-.221 242.338)'
          fill={color}
        />
        <path
          d='m13.433-222.295 3.082-3.073a.663.663 0 0 0-.3-1.11l-4.015-1.043a.855.855 0 0 0-1.035 1.074l1.165 3.874a.663.663 0 0 0 1.103.278z'
          transform='translate(-.602 243.203)'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default MovementIcon

MovementIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
