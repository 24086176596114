import PropTypes from 'prop-types'

const SetOperatorsIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <path
          d='M-2000.36-1586.507h-10.788a3.856 3.856 0 0 1-3.852-3.852v-10.788a3.856 3.856 0 0 1 3.852-3.852h10.788a3.856 3.856 0 0 1 3.852 3.852v10.788a3.856 3.856 0 0 1-3.852 3.852zm-8.15-15.407a3.409 3.409 0 0 0-3.4 3.406v2.044a3.409 3.409 0 0 0 3.4 3.4h.062v.062a3.409 3.409 0 0 0 3.4 3.4h2.048a3.409 3.409 0 0 0 3.406-3.4v-2.044a3.41 3.41 0 0 0-3.406-3.406h-.061v-.061a3.41 3.41 0 0 0-3.406-3.406zm5.511 10.991h-2.044a2.077 2.077 0 0 1-2.074-2.075v-.062h.652a3.409 3.409 0 0 0 3.406-3.4v-.652h.061a2.077 2.077 0 0 1 2.074 2.075v2.037a2.077 2.077 0 0 1-2.076 2.076zm-3.467-3.467h-.652v-.652a2.077 2.077 0 0 1 2.074-2.075h.652v.652a2.077 2.077 0 0 1-2.073 2.074zm-1.982 0h-.062a2.077 2.077 0 0 1-2.074-2.075v-2.044a2.077 2.077 0 0 1 2.074-2.075h2.044a2.077 2.077 0 0 1 2.074 2.075v.061h-.652a3.409 3.409 0 0 0-3.4 3.406v.651z'
          transform='translate(2021.754 1611.754)'
          fill={color}
          strokeLinecap='round'
          strokeWidth='2px'
        />
      </g>
    </svg>
  )
}

export default SetOperatorsIcon

SetOperatorsIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
