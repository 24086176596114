import { Box } from '@mui/material'
import BaseRadioGroup from '../../../common/BaseRadioGroup'
import { listOperatorAttributeTrajectory, listTimeModeModal } from '../../../utils/common'
import { getTimeModeDescription } from '../../../utils/modal'
import { useFormContext } from 'react-hook-form'

const TableContent = () => {
  const { watch } = useFormContext()

  const data = watch()
  const { time_mode } = data

  return (
    <Box>
      <BaseRadioGroup
        name='operator_attribute'
        label='Operator attribute'
        listGroup={listOperatorAttributeTrajectory}
      />

      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listTimeModeModal}
        name='time_mode'
        label='Time mode'
      />

      <p className='text-[#444c4d] mt-[12px]'>{getTimeModeDescription(time_mode).text}</p>

      <Box mt='24px'>
        <p className='text-[#444c4d] font-bold text-[18px]'>Operator attribute</p>
        <p className='text-[#444c4d] mt-[8px]'>
          The table data will be loaded once the creation of widgets is finished
        </p>
      </Box>
    </Box>
  )
}

export default TableContent
