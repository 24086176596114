import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactFlow, { useEdgesState, addEdge, MiniMap, Panel, useReactFlow } from 'reactflow'
import PropTypes from 'prop-types'

import useVideoZoneStore from '../../store/useVideoZoneStore'
import useFlowZoneStore from '../../store/useFlowZoneStore'
import { NodeArea } from './NodeLabel/NodeArea'
import { NodeElement } from './NodeLabel/NodeElement'
import { AreaModal } from '../../modals/AreaModal/AreaModal'
import useModalStore from '../../store/useModalStore'
import useCommonStore from '../../store/useCommonStore'
import ProgrammingElementModal from '../../modals/ProgrammingElementModal/ProgrammingElementModal'
import SideBarFlow from './SideBarFlow/SideBarFlowContent'
import ZoomIn from '../../assets/icons/ZoomIn'
import ZoomOut from '../../assets/icons/ZoomOut'
import EdgeCustom from './EdgeCustom/EdgeCustom'
import { v4 } from 'uuid'

const connectionLineStyle = { stroke: '#000' }
const snapGrid = [20, 20]

const defaultViewport = { x: 0, y: 0, zoom: 1.5 }

const nodeColor = node => {
  switch (node.type) {
    case 'input':
      return '#6ede87'
    case 'output':
      return '#6865A5'
    default:
      return '#ff0072'
  }
}

const edgeTypes = {
  edge_custom: EdgeCustom,
};

export const FlowZone = forwardRef((props, ref) => {
  const { nodes, onNodesChange } = props

  const { zoomIn, zoomOut } = useReactFlow();
  const { setHideZone, selectedZone } = useVideoZoneStore(state => state.paramsZone)
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const { isShowFull, selectedDeleteEdgeId, setSelectedDeleteEdgeIdId } = useFlowZoneStore()
  const { isFullFlow } = useCommonStore()
  const { statusModal } = useModalStore()

  const refMenuOption = useRef(null)
  const [isFixed, setIsFixed] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [isInSide, setIsInSide] = useState(true)
  const [position, setPosition] = useState({ top: 0, left: 0 })

  const nodeTypes = useMemo(() => ({ nodeArea: NodeArea, nodeElement: NodeElement }), [])

  const onConnect = useCallback(params => {
    setEdges(eds => addEdge({ ...params, type: 'edge_custom', id: v4() }, eds))
  }, [setEdges])

  const onDragOver = useCallback(event => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const handleMouseEnter = () => {
    setHideZone(selectedZone.id)
  }

  useEffect(() => {
    if (!refMenuOption) return

    setPosition({ top: 12, left: refMenuOption.current.getBoundingClientRect().left })
  }, [refMenuOption])

  useEffect(() => {
    const handleMouseMove = e => {
      if (isDragging) {
        const rect = refMenuOption.current.getBoundingClientRect()
        const mouseX = e.clientX
        setIsInSide(mouseX >= rect.left && mouseX <= rect.left + rect.width)
      }
    }

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove)
    } else {
      window.removeEventListener('mousemove', handleMouseMove)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [isDragging, refMenuOption])

  useEffect(() => {
    if (!isDragging && isInSide) {
      setPosition({ top: 12, left: refMenuOption.current.getBoundingClientRect().left })
    }

    if (!isDragging) {
      setIsFixed(!isInSide)
    }
  }, [isDragging, isInSide, refMenuOption])

  useEffect(() => {
    if (isFixed) return
    if (!refMenuOption.current) return

    const positionX = isFullFlow
      ? refMenuOption.current.getBoundingClientRect().left - 500
      : refMenuOption.current.getBoundingClientRect().left + 500
    setPosition({ top: 12, left: positionX })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullFlow])

  const [width, setWidth] = useState('267px')

  useEffect(() => {
    if (isShowFull) {
      setWidth('267px')
    } else {
      setTimeout(() => setWidth('70px'), 500)
    }
  }, [isShowFull])

  return (
    <div
      className={`h-screen relative transition-all duration-500`}
      style={{ width: isFullFlow ? '1000px' : '500px' }}
      ref={ref}
      onMouseOver={handleMouseEnter}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        connectionLineStyle={connectionLineStyle}
        snapGrid={snapGrid}
        edgeTypes={edgeTypes}
        defaultViewport={defaultViewport}
        onEdgesChange={onEdgesChange}
        onInit={props.setReactFlowInstance}
        onEdgeDoubleClick={(_e, params) => {
          setSelectedDeleteEdgeIdId(params.id)
        }}
        onPaneClick={() => {
          if (selectedDeleteEdgeId) {
            setSelectedDeleteEdgeIdId("")
          }
        }}
        onDragOver={onDragOver}
        className='bg-[#e5e5e5] flow-zone'
      >
        <MiniMap
          nodeStrokeColor={n => {
            if (n.type === 'input') return '#0041d0'
            if (n.type === 'output') return '#ff0072'
          }}
          nodeColor={node => nodeColor(node)}
          nodeBorderRadius={2}
          style={{
            border: "1px solid #707070"
          }}
        />
        <Panel position='bottom-right' style={{
          height: '100px',
        }}>
          <div className='flex flex-col'>
            <div className='w-[50px] h-[50px] border-y-[1px] border-r-[1px] rounded-tr-md border-[#707070] flex justify-center items-center bg-[#fff]' onClick={zoomIn}>
              <ZoomIn />
            </div>
            <div className='w-[50px] h-[50px] border-b-[1px] border-r-[1px] rounded-br-md border-[#707070] flex justify-center items-center bg-[#fff]' onClick={zoomOut}>
              <ZoomOut />
            </div>
          </div>
        </Panel>
      </ReactFlow>

      <div
        className={`absolute top-0 h-auto pt-3 left-0 ${isInSide && isDragging ? 'bg-slate-400' : ''}`}
        style={{ width: width }}
        ref={refMenuOption}
      >
        <SideBarFlow
          setIsFixed={setIsFixed}
          isFixed={isFixed}
          setIsDragging={setIsDragging}
          position={position}
          setPosition={setPosition}
          isShowFull={isShowFull}
        />
      </div>
      {statusModal.areaModal && <AreaModal />}
      {statusModal.programmingEleModal && <ProgrammingElementModal />}
    </div>
  )
})

FlowZone.displayName = 'FlowZone'

FlowZone.propTypes = {
  nodes: PropTypes.any,
  onNodesChange: PropTypes.any,
  handleDragEnd: PropTypes.any,
  setReactFlowInstance: PropTypes.any
}
