import { Box, Switch, Typography, styled } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'

const IOSSwitch = styled(props => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(
  ({ theme }) => ({
    width: 43,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: theme.palette.mode === 'dark' ? '#6d7374' : '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : '#65C466',
          opacity: 1,
          border: 0
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5
        }
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff'
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
      }
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500
      })
    }
  })
)

const BaseSwitchForm = ({ name, title, description, label }) => {
  const { control } = useFormContext()

  return (
    <Box>
      {title && <p className='text-[#444c4d] mt-[12px] font-bold text-[18px]'>{title}</p>}
      {description && <p className='text-[#444c4d] mt-[8px]'>{description}</p>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Box display='flex' gap='10px' mt='10px' alignContent='center' alignItems='center'>
            <IOSSwitch checked={value} onChange={e => onChange(e.target.checked)} />
            <Typography>{label}</Typography>
          </Box>
        )}
      />
    </Box>
  )
}

export default BaseSwitchForm

BaseSwitchForm.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
