import { Box } from '@mui/material'
import Layout from '../../Layout/Layout'

const Definition = () => {
  return (
    <Layout>
      <Box>Definition</Box>
    </Layout>
  )
}

export default Definition
