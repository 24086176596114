import PropTypes from 'prop-types'

const PropertyFiltersIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <path
          d='M-2000.36-1586.507h-10.788a3.856 3.856 0 0 1-3.852-3.852v-10.788a3.856 3.856 0 0 1 3.852-3.852h10.788a3.856 3.856 0 0 1 3.852 3.852v10.788a3.856 3.856 0 0 1-3.852 3.852zm-10.832-7a.867.867 0 0 0-.867.866v.812a.868.868 0 0 0 .867.867h.913a.868.868 0 0 0 .867-.867v-.812a.867.867 0 0 0-.867-.866zm3.689.392a.7.7 0 0 0-.694.694v.372a.694.694 0 0 0 .694.693h6.707a.694.694 0 0 0 .693-.693v-.372a.694.694 0 0 0-.693-.694zm-3.689-3.648a.867.867 0 0 0-.867.866v.812a.867.867 0 0 0 .867.866h.913a.867.867 0 0 0 .867-.866v-.812a.867.867 0 0 0-.867-.866zm3.689.392a.694.694 0 0 0-.694.693v.372a.7.7 0 0 0 .694.694h6.707a.694.694 0 0 0 .693-.694v-.372a.694.694 0 0 0-.693-.693zm-3.689-3.58a.868.868 0 0 0-.867.867v.812a.867.867 0 0 0 .867.866h.913a.867.867 0 0 0 .867-.866v-.812a.868.868 0 0 0-.867-.867zm3.689.393a.694.694 0 0 0-.694.693v.372a.7.7 0 0 0 .694.694h6.707a.694.694 0 0 0 .693-.694v-.372a.694.694 0 0 0-.693-.693z'
          transform='translate(2021.754 1611.754)'
          fill={color}
          strokeLinecap='round'
          strokeWidth='2px'
        />
      </g>
    </svg>
  )
}

export default PropertyFiltersIcon

PropertyFiltersIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
