import { memo, useEffect, useMemo, useState } from 'react'
import { Box, Collapse, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'

// eslint-disable-next-line react/display-name
const BaseSetting = memo(({ classCustom, listData }) => {
  return (
    <Box className={`rounded-md overflow-hidden ${classCustom}`}>
      {listData.map((dataItem, index) => {
        return <ItemAction dataItem={dataItem} index={index} key={index} countData={listData.length} />
      })}
    </Box>
  )
})

// eslint-disable-next-line react/display-name
const ItemAction = memo(({ dataItem, index, countData }) => {
  const [isShowCollapse, setIsShowCollapse] = useState(false)
  const [classChild, setClassChild] = useState('')

  useEffect(() => {
    if (isShowCollapse) {
      setClassChild('')
    } else {
      let classRounded
      if (dataItem.type === 'hover') {
        classRounded = `${index === 0 && 'rounded-tr-md'} ${index === countData - 1 && 'rounded-b-md'}`
      } else if (dataItem.type === 'click') {
        classRounded = index === 0 || index === countData - 1 ? 'rounded-r-md' : ''
      } else {
        classRounded = ''
      }
      setTimeout(() => setClassChild(classRounded), 500)
    }
  }, [isShowCollapse, index, countData, dataItem.type])

  const classActive = useMemo(() => {
    if (dataItem.type === 'hover' && dataItem.active) {
      return 'custom-active'
    }
    return ''
  }, [dataItem.active, dataItem.type])

  return (
    <Collapse orientation='horizontal' in={isShowCollapse} collapsedSize={48} timeout={500} key={index}>
      <Box
        className={`flex ${
          dataItem.type ? 'rounded-r-md' : ''
        } overflow-hidden border-gray-400 cursor-pointer border-r-2`}
        onMouseOver={() => {
          if (dataItem.type !== 'hover') return
          setIsShowCollapse(true)
        }}
        onMouseOut={() => {
          if (dataItem.type !== 'hover') return
          setIsShowCollapse(false)
        }}
        onClick={dataItem.action ? dataItem.action : dataItem.callBack}
      >
        <Tooltip disableHoverListener={!dataItem.tooltip} title={dataItem.label} placement='top-end'>
          <Box
            className={`w-[48px] h-[48px] border border-r-0 border-gray-400 flex items-center justify-center relative ${
              index === countData - 1 && dataItem.type === 'hover' ? 'rounded-bl-md' : ''
            } ${classChild} ${classActive}`}
            style={{ backgroundColor: dataItem.active && dataItem.colorBg ? dataItem.colorBg : '#fff' }}
            onClick={() => {
              if (dataItem.type !== 'click') return
              setIsShowCollapse(prev => !prev)
            }}
          >
            {dataItem.icon(dataItem.active ? 'white' : undefined)}
            {/* {dataItem.type === 'click' && (
              <Box className='absolute' style={{ right: '-2px' }}>
                {isShowCollapse ? (
                  <ArrowLeftIcon style={{ width: '18px', color: 'gray' }} />
                ) : (
                  <ArrowRightIcon style={{ width: '18px', color: 'gray' }} />
                )}
              </Box>
            )} */}
            {dataItem.value && (
              <Box className='absolute' style={{ color: '#6d7374', bottom: '10px', fontWeight: 600, fontSize: '8px' }}>
                {dataItem.value}
              </Box>
            )}
            {dataItem.color && (
              <Box
                className='absolute h-[4px] bottom-1 rounded-sm'
                style={{ backgroundColor: dataItem.active ? dataItem.color : '#6d7374', width: '38px' }}
              />
            )}
          </Box>
        </Tooltip>
        {dataItem.type === 'hover' && (
          <div
            className={`px-4  items-center flex border-gray-400  ${classActive}`}
            style={{
              borderTopWidth: '1px',
              borderBottomWidth: '1px',
              fontSize: '15px',
              color: dataItem.active ? '#fff' : '#444c4d',
              backgroundColor: dataItem.active && dataItem.colorBg ? dataItem.colorBg : '#fff'
            }}
          >
            <span style={{ fontWeight: dataItem.active ? 400 : 500, textWrap: 'nowrap' }}>{dataItem.label}</span>
          </div>
        )}
        {dataItem.children && dataItem.children.length !== 0 && (
          <BaseSetting listData={dataItem.children} classCustom={'flex !rounded-none'} />
        )}
      </Box>
    </Collapse>
  )
})

BaseSetting.propTypes = {
  classCustom: PropTypes.string,
  listData: PropTypes.array
}

ItemAction.propTypes = {
  dataItem: PropTypes.object,
  index: PropTypes.number,
  classChildCustom: PropTypes.string,
  countData: PropTypes.number
}

export default BaseSetting
