import { Box } from '@mui/material'
import CloseWindowIcon from '../../assets/icons/CloseWindowIcon'
import useModalStore from '../../store/useModalStore'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

export const HeaderModalCommon = ({ icon, mainTypeElement, title, statusKeyModal, typeModal }) => {
  const { setStatusModal } = useModalStore()

  const styleColorModal = useMemo(() => {
    if (typeModal !== 'area' && mainTypeElement) {
      return {
        bgIconColor: mainTypeElement.color,
        bgTextColor: mainTypeElement.bgcolor
      }
    }

    return {
      bgIconColor: '#6d7374',
      bgTextColor: '#e1e6e6'
    }
  }, [typeModal, mainTypeElement])

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%'
      }}
    >
      <div
        className='w-[44px] h-[44px] flex items-center justify-center'
        style={{
          background: styleColorModal.bgIconColor
        }}
      >
        {icon}
      </div>
      <div
        className='flex items-center justify-between pl-[24px] pr-[16px] flex-1'
        style={{
          background: styleColorModal.bgTextColor
        }}
      >
        <div className='text-[20px] font-bold'>{title}</div>
        <div className='cursor-pointer' onClick={() => setStatusModal(statusKeyModal)}>
          <CloseWindowIcon />
        </div>
      </div>
    </Box>
  )
}

HeaderModalCommon.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.element.isRequired,
  mainTypeElement: PropTypes.object.isRequired,
  statusKeyModal: PropTypes.string.isRequired,
  typeModal: PropTypes.string.isRequired
}
