import { Box, Typography } from "@mui/material"
import PropTypes from 'prop-types'
import TimeOccurrenceNowIcon from "../../../assets/icons/TimeOccurrenceNowIcon"

const ZoneLabel = ({ data }) => {
  const { value } = data

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      gap: '4px',
      alignItems: 'center',
      bgcolor: '#fff',
    }}>
      {
        value.time_mode === 'now' && (
          <Box sx={{
            p: 1,
            bgcolor: '#fff',
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}>
            <TimeOccurrenceNowIcon />
            <Typography>
              {value.time_mode}
            </Typography>
          </Box>
        )
      }
    </Box>
  )
}

export default ZoneLabel

ZoneLabel.displayName = 'ZoneLabel'

ZoneLabel.propTypes = {
  data: PropTypes.object,
}
