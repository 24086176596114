import { memo, useMemo } from 'react'
import BaseSetting from '../BaseSetting'
import { getSettingInfo, list4 } from '../../../../utils/sidebar'
import useVideoZoneStore from '../../../../store/useVideoZoneStore'

const FlagSetting = () => {
  const { flagSetting, updateFlagSetting } = useVideoZoneStore(store => store.paramsZone)

  const menuSetting = useMemo(() => {
    return [
      {
        ...list4[0],
        children: getSettingInfo({
          list: list4[0].children,
          activeList: flagSetting,
          callBack: key => () => {
            updateFlagSetting(key)
          }
        })
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list4, flagSetting])

  return <BaseSetting listData={menuSetting} />
}

export default memo(FlagSetting)
