import PropTypes from 'prop-types'

const NotificationIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <path
          d='M20.092 15.951a5.239 5.239 0 0 1-1.861-4.009v-2.18a5.479 5.479 0 0 0-4.692-5.412v-.844a.782.782 0 1 0-1.564 0v.845a5.478 5.478 0 0 0-4.692 5.411v2.18a5.245 5.245 0 0 1-1.868 4.014 1.368 1.368 0 0 0 .89 2.408h12.9a1.368 1.368 0 0 0 .884-2.414z'
          fill={color}
          transform='translate(3.243 3.891)'
        />
        <path
          d='M14.808 28.3a2.937 2.937 0 0 0 2.873-2.346h-5.746a2.937 2.937 0 0 0 2.873 2.346z'
          transform='translate(1.191 -2.919)'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default NotificationIcon

NotificationIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
