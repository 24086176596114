import PropTypes from 'prop-types'

const IntersectionIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path style={{ fill: 'none' }} d='M0 0h32v32H0z' />
      <g>
        <path
          d='M85.763 543.671h-2.98a1.012 1.012 0 0 1-1.012-1.011v-10.98a1.012 1.012 0 0 1 1.012-1.012h10.943a1.012 1.012 0 0 1 1.012 1.012v2.994a1.012 1.012 0 0 1-1.012 1.011h-5.94a1.012 1.012 0 0 0-1.012 1.012v5.963a1.011 1.011 0 0 1-1.011 1.011z'
          fill={color}
          transform='translate(-75.219 -524.142)'
        />
        <path
          d='M99.654 549.616H88.711A1.012 1.012 0 0 1 87.7 548.6v-3.042a1.012 1.012 0 0 1 1.012-1.011H94.6a1.012 1.012 0 0 0 1.011-1.012v-5.915a1.011 1.011 0 0 1 1.011-1.011h3.028a1.012 1.012 0 0 1 1.012 1.011v10.98a1.012 1.012 0 0 1-1.008 1.016z'
          fill={color}
          transform='translate(-75.219 -524.142)'
        />
        <path
          d='M92.875 542.817h-3.36a1.012 1.012 0 0 1-1.012-1.011v-3.376a1.012 1.012 0 0 1 1.012-1.011h3.36a1.011 1.011 0 0 1 1.011 1.011v3.376a1.011 1.011 0 0 1-1.011 1.011z'
          fill={color}
          transform='translate(-75.219 -524.142)'
        />
      </g>
    </svg>
  )
}

export default IntersectionIcon

IntersectionIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
