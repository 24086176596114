import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import SideBarMenu from '../components/SideBarMenu'
import useVideoZoneStore from '../store/useVideoZoneStore'

const Layout = ({ children }) => {
  const { hideZone, selectedZone } = useVideoZoneStore(state => state.paramsZone)

  return (
    <Box className='flex relative w-screen h-screen'>
      <SideBarMenu />
      {hideZone && selectedZone.id && <MouseFollower />}
      <Box className='flex-1'>{children}</Box>
    </Box>
  )
}

const MouseFollower = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    const handleMouseMove = e => {
      setMousePosition({ x: e.clientX, y: e.clientY })
    }

    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  return (
    <div
      style={{
        position: 'absolute',
        left: mousePosition.x + 'px',
        top: mousePosition.y + 'px',
        width: '100px',
        height: '50px',
        background: 'blue',
        borderRadius: '8px',
        color: 'white'
      }}
    >
      Zone nè
    </div>
  )
}

export default Layout
