import PropTypes from 'prop-types'

const SpeedIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <path
          d='M15.393-29.566a10.275 10.275 0 0 0-6.28 2.6l2.706 2.707a6.487 6.487 0 0 1 3.574-1.481z'
          fill={color}
          transform='translate(0 36.636)'
        />
        <path
          d='m10.961-23.4-2.706-2.707a10.282 10.282 0 0 0-2.606 6.281h3.828a6.492 6.492 0 0 1 1.484-3.574z'
          fill={color}
          transform='translate(0 36.636)'
        />
        <path
          d='M22.523-19.826h3.828a10.282 10.282 0 0 0-2.606-6.281L21.039-23.4a6.492 6.492 0 0 1 1.484 3.574z'
          fill={color}
          transform='translate(0 36.636)'
        />
        <path
          d='M22.522-18.613a6.464 6.464 0 0 1-.667 2.339c.307.474.564.875.733 1.141a1.808 1.808 0 0 1-.248 2.264 1.838 1.838 0 0 1-.5.354l.631.63a.6.6 0 0 0 .429.178.6.6 0 0 0 .43-.178 10.293 10.293 0 0 0 3.02-6.728z'
          fill={color}
          transform='translate(0 36.636)'
        />
        <path
          d='M9.478-18.613H5.649a10.293 10.293 0 0 0 3.02 6.728.6.6 0 0 0 .43.178.6.6 0 0 0 .429-.178l1.841-1.84a.608.608 0 0 0 0-.859 6.509 6.509 0 0 1-1.891-4.029z'
          fill={color}
          transform='translate(0 36.636)'
        />
        <path
          d='M22.887-26.964a10.275 10.275 0 0 0-6.28-2.6v3.828a6.487 6.487 0 0 1 3.574 1.481z'
          fill={color}
          transform='translate(0 36.636)'
        />
        <path
          d='M17.348-20.557a1.909 1.909 0 0 0-2.7 0 1.909 1.909 0 0 0 0 2.7 69.884 69.884 0 0 0 6.076 4.217.607.607 0 0 0 .754-.083.605.605 0 0 0 .083-.754 69.884 69.884 0 0 0-4.213-6.08z'
          fill={color}
          transform='translate(0 36.636)'
        />
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default SpeedIcon

SpeedIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
