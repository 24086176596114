import PropTypes from 'prop-types'

const TrajectoryViewIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path
        d='M-164.958 1050.175v10.845c0 5.721-8.694 5.721-8.694 0v-9.626a2.239 2.239 0 0 0-4.478 0v10.845l.571-.572a1 1 0 0 1 1.415 0l.077.077a1 1 0 0 1 0 1.414l-2.41 2.41a1 1 0 0 1-1.415 0l-2.409-2.41a1 1 0 0 1 0-1.414l.077-.077a1 1 0 0 1 1.414 0l.571.572v-10.845c0-5.721 8.695-5.721 8.695 0v9.626a2.239 2.239 0 0 0 4.478 0v-10.845l-.572.572a1 1 0 0 1-1.414 0l-.077-.077a1 1 0 0 1 0-1.414l2.41-2.41a1 1 0 0 1 1.414 0l2.41 2.41a1 1 0 0 1 0 1.414l-.077.077a1 1 0 0 1-1.414 0z'
        fill={color}
        transform='translate(188.594 -1040.553)'
      />
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default TrajectoryViewIcon

TrajectoryViewIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
