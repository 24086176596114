export const VI = 'vi'
export const EN = 'en'
export const DEFAULT_LANGUAGE = VI

export const PROGRAMMING_ELEMENT = {
  // KEY of the operator element
  SPEED: 'SPEED',
  LICENSE_PLATE: 'LICENSE_PLATE',
  STATIONERY: 'STATIONERY',
  TIME: 'TIME',
  DURATION: 'DURATION',
  COLOR: 'COLOR',
  CATEGORY: 'CATEGORY',
  UNION: 'UNION',
  INTERSECTION: 'INTERSECTION',
  COMPLEMENT: 'COMPLEMENT',
  LEVEL: 'LEVEL',
  VOLUME: 'VOLUME',

  // KEY of the widget element
  STATISTICAL: 'STATISTICAL',
  HEATMAP: 'HEATMAP',
  DISTRIBUTION: 'DISTRIBUTION',
  TRAJECTORY: 'TRAJECTORY',
  TABLE: 'TABLE',
  ODMATRIX: 'ODMATRIX',
  VALUE: 'VALUE'
}

export const TYPE_AREA = {
  ZONE: 'zone',
  GATE: 'gate',
  MOVEMENT: 'movement',
  TRAFFIC: 'traffic'
}
