import { Box } from "@mui/material"
import PropTypes from 'prop-types'
import { listVehicleModal } from "../../../utils/common"

const CategoryLabel = ({ data }) => {
  const { value } = data

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      gap: '4px',
      bgcolor: '#fff',
      flexDirection: 'column'
    }}>
      {
        value.categories.map((cate, index) => {
          const itemCate = listVehicleModal.find((vehicle) => vehicle.value === cate)

          return (
            <Box key={cate} sx={{
              bgcolor: '#fff',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              borderTop: index === 0 ? 'none' : '1px solid #808080'
            }}>
              <div className="pt-[10px]">
                {itemCate.icon}
              </div>
              {itemCate.label}
            </Box>
          )
        })
      }
    </Box>
  )
}

export default CategoryLabel

CategoryLabel.displayName = 'CategoryLabel'

CategoryLabel.propTypes = {
  data: PropTypes.object,
}
