import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'

const BaseCheckBoxGroup = ({ width, childrenWidth, label, isIcon = false, description, name, listGroup }) => {
  const { control } = useFormContext()

  return (
    <Box>
      {label && <p className='text-[#444c4d] mt-[24px] font-bold text-[18px]'>{label}</p>}
      {description && <p className='text-[#444c4d] mt-[10px]'>{description}</p>}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: width || '100%',
                alignItems: 'center',
                alignContent: 'center',
                flexWrap: 'wrap',
                columnGap: '20px'
              }}
            >
              {listGroup &&
                listGroup.map(item => (
                  <FormControlLabel
                    key={item.value}
                    control={<Checkbox value={item.value} onChange={(e) => {
                      if (e.target.checked) {
                        onChange([
                          ...value,
                          e.target.value
                        ])
                      } else {
                        onChange([
                          ...value
                        ].filter((val) => val !== item.value))
                      }
                    }} checked={value.includes(item.value)} />}
                    label={
                      <Box
                        display='flex'
                        gap='2px'
                        alignItems='center'
                        alignContent='center'
                        width={childrenWidth || 'auto'}
                      >
                        {item.icon && <Box pt={isIcon ? '10px' : '0px'}>{item.icon}</Box>}
                        <Typography color='#444c4d'>{item.label}</Typography>
                      </Box>
                    }
                  />
                ))}
            </Box>
          )
        }}
      />
    </Box>
  )
}

BaseCheckBoxGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  listGroup: PropTypes.array.isRequired,
  width: PropTypes.number,
  childrenWidth: PropTypes.number,
  description: PropTypes.string.isRequired,
  isIcon: PropTypes.bool
}

export default BaseCheckBoxGroup
