import PropTypes from 'prop-types'

const FlagDisplaySettingIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <path fill='none' d='M0 0h32v32H0z' />
        <g>
          <g>
            <g>
              <path
                d='M3238.83 142.923h-11.11a.842.842 0 0 1-.842-.842v-8.174a.842.842 0 0 1 .842-.842h11.11a.842.842 0 0 1 .842.842v8.174a.842.842 0 0 1-.842.842zm-10.088-8.2a.579.579 0 0 0-.578.578v5.4a.579.579 0 0 0 .578.578h7.528a.579.579 0 0 0 .578-.578v-5.4a.579.579 0 0 0-.578-.578z'
                transform='translate(-3211.758 -131.131)'
                fill={color}
                stroke='transparent'
                strokeMiterlimit='10'
              />
            </g>
            <path
              d='m17.467-24.814-1.6-1.745a.36.36 0 0 0-.61.138l-.657 2.156a.464.464 0 0 0 .56.586l2.132-.542a.361.361 0 0 0 .175-.593z'
              transform='translate(-.148 35.958)'
              fill={color}
            />
          </g>
          <g>
            <path
              d='M9.564-14.069a.954.954 0 0 1-.955.953.953.953 0 0 1-.953-.953.954.954 0 0 1 .953-.955.955.955 0 0 1 .955.955z'
              transform='translate(-.927 37.269)'
              fill={color}
            />
            <path d='M10.571-14.852h6.1v1.526h-6.1z' transform='translate(-.599 37.288)' fill={color} />
            <path
              d='M17.259-14.069a.953.953 0 0 0 .953.953.954.954 0 0 0 .955-.953.955.955 0 0 0-.955-.955.954.954 0 0 0-.953.955z'
              transform='translate(.154 37.269)'
              fill={color}
            />
            <path
              d='m23.223-15.566-2.53-6.1a2.278 2.278 0 0 0-.5-.742 2.281 2.281 0 0 0-1.619-.671H9.9a2.279 2.279 0 0 0-1.619.671 2.261 2.261 0 0 0-.5.742l-2.531 6.1a2.3 2.3 0 0 0-.174.877v5.341H6.61v2.29a.764.764 0 0 0 .762.763H8.9a.765.765 0 0 0 .763-.763v-2.29h9.157v2.29a.765.765 0 0 0 .763.763h1.526a.765.765 0 0 0 .763-.763v-2.29H23.4v-5.341a2.278 2.278 0 0 0-.177-.877zM9.2-21.086a.757.757 0 0 1 .165-.247.763.763 0 0 1 .54-.224h8.675a.76.76 0 0 1 .538.224.778.778 0 0 1 .166.247l1.7 4.107H7.5zm12.671 10.213H6.61v-3.816a1.491 1.491 0 0 1 .062-.3.762.762 0 0 1 .7-.462h13.736a.764.764 0 0 1 .7.462 1.491 1.491 0 0 1 .062.3z'
              transform='translate(-1.216 36.362)'
              fill={color}
            />
          </g>
        </g>
        <path
          d='M31.751-18.965v-3.343a.51.51 0 0 0-.768-.439l-2.745 1.618a.657.657 0 0 0 .023 1.145L31-18.516a.509.509 0 0 0 .751-.449z'
          fill={color}
          transform='translate(0 36.637)'
        />
      </g>
    </svg>
  )
}

export default FlagDisplaySettingIcon

FlagDisplaySettingIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
