import { Box } from "@mui/material"
import PropTypes from 'prop-types'
import { listColorModal } from "../../../utils/common"
import { useFieldArray, useFormContext } from "react-hook-form"

export const BlockColor = ({ color }) => {
  const { control } = useFormContext()
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'colors'
  })

  return (
    <div className={`w-[40px] h-[40px] cursor-pointer rounded-md ${color === '#fff' && 'border-[1px] border-[#c7cccc]'}`} style={{
      backgroundColor: color,
      border: fields.find(col => col.value === color) ? '3px solid #808080' : 'none'
    }} onClick={() => {
      if (fields.find(col => col.value === color)) {
        remove(fields.findIndex(col => col.value === color))
      } else {
        append({ value: color })
      }
    }} />
  )
}

const ColorContent = () => {
  const { control } = useFormContext()
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'colors'
  })

  return (
    <Box mt="24px">
      <p className='text-[#444c4d] font-bold text-[18px]'>Color</p>
      <Box display="flex" gap="4px" mt="7px" >
        <div
          className="w-[40px] h-[40px] cursor-pointer rounded-md text-[30px] text-center text-[#444c4d] bg-[#d3d3d3] flex items-center justify-center"
          style={{
            border: fields.find(col => col.value === "?") ? '3px solid #808080' : 'none'
          }}
          onClick={() => {
            if (fields.find(col => col.value === "?")) {
              remove(fields.findIndex(col => col.value === "?"))
            } else {
              append({ value: "?" })
            }
          }}
        >
          ?
        </div>
        {listColorModal.map((color, index) => (
          <BlockColor key={`${color}_${index}`} color={color} />
        ))}
      </Box>
    </Box>
  )
}

BlockColor.propTypes = {
  color: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

export default ColorContent
