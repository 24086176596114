import { Box, Checkbox } from '@mui/material'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'

export const BaseCheckBox = ({ label, name }) => {
  const { control } = useFormContext()

  return (
    <Box>
      {label && <p className='text-[#444c4d] mt-[24px] font-bold text-[18px]'>{label}</p>}
      <label>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, ref, value } }) => {
            return <Checkbox ref={ref} onChange={onChange} defaultChecked={value} />
          }}
        />
        Now
      </label>
    </Box>
  )
}

BaseCheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
