import PropTypes from 'prop-types'

const ParametersConfigIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path d='M0 0h32v32H0z' fill='none' />
      <g>
        <g>
          <path
            d='M6.334-63.251a.506.506 0 0 0 .126.707l.348.241a3.062 3.062 0 0 1-2.123.857h-.95v-1.1a.505.505 0 0 0-.506-.506.505.505 0 0 0-.506.506v5.158a.505.505 0 0 0 .506.506.505.505 0 0 0 .506-.506v-1.028h.685a6.487 6.487 0 0 0 4.85-2.18l.506.352a.505.505 0 0 0 .706-.131l.347-.509-4.1-2.93z'
            transform='translate(2.236 82.54)'
            fill={color}
          />
          <path
            d='m22.393-63.11-.186-.127-3.879 3.1 1.11.8a.505.505 0 0 0 .689-.093l2.373-2.944a.506.506 0 0 0-.107-.736z'
            transform='translate(-.393 82.443)'
            fill={color}
          />
          <path
            d='m19.33-66.666-9.723-7.023a1.012 1.012 0 0 0-1.381.186l-3.2 3.978a1.012 1.012 0 0 0 .2 1.456l8.6 6.143a.507.507 0 0 0 .61-.016l4.907-3.921a.505.505 0 0 0-.02-.8zm-3.579.894a.505.505 0 0 1-.506-.506.505.505 0 0 1 .506-.506.505.505 0 0 1 .506.506.505.505 0 0 1-.506.505z'
            transform='translate(1.885 84.236)'
            fill={color}
          />
        </g>
        <g>
          <g>
            <path
              d='m27.067-76.462-1.128-.278a3.775 3.775 0 0 0-.26-.619l.525-.876a.287.287 0 0 0-.043-.352l-.814-.814a.287.287 0 0 0-.352-.043l-.876.525a3.761 3.761 0 0 0-.615-.258l-.279-1.123a.288.288 0 0 0-.279-.218h-1.152a.287.287 0 0 0-.279.218s-.192.787-.277 1.128a3.652 3.652 0 0 0-.654.279l-.909-.545a.289.289 0 0 0-.352.043l-.815.814a.289.289 0 0 0-.043.352l.562.939a3.734 3.734 0 0 0-.227.555l-1.128.279a.287.287 0 0 0-.218.279v1.152a.287.287 0 0 0 .218.279l1.128.277a3.76 3.76 0 0 0 .264.63l-.5.838a.288.288 0 0 0 .044.352l.814.814a.289.289 0 0 0 .352.043l.84-.5a3.713 3.713 0 0 0 .625.263l.277 1.128a.287.287 0 0 0 .279.218h1.152a.288.288 0 0 0 .279-.218l.277-1.128a3.737 3.737 0 0 0 .639-.271l.853.511a.287.287 0 0 0 .352-.043l.814-.814a.287.287 0 0 0 .043-.352l-.517-.863a3.708 3.708 0 0 0 .253-.605l1.128-.277a.288.288 0 0 0 .218-.279v-1.152a.287.287 0 0 0-.219-.288zm-4.7 2.871a2.019 2.019 0 0 1-2.016-2.016 2.019 2.019 0 0 1 2.016-2.016 2.019 2.019 0 0 1 2.016 2.016 2.019 2.019 0 0 1-2.013 2.016z'
              transform='translate(-.245 85.356)'
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ParametersConfigIcon

ParametersConfigIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
