import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { BaseInputSlider } from './BaseInputSlider'
import PropTypes from 'prop-types'

const listValue = [
  {
    value: 'less',
    label: 'Less'
  },
  {
    value: 'greater',
    label: 'Greater'
  }
]

const BaseValueSelect = ({ name, label }) => {
  const { control } = useFormContext()

  return (
    <Box>
      {label && <p className='text-[#444c4d] mt-[24px] font-bold text-[18px]'>{label}</p>}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => {
          const handleInput = e => {
            onChange({
              ...value,
              input: e.target.value
            })
          }

          const handleChangeType = e => {
            onChange({
              ...value,
              type: e.target.value
            })
          }

          return (
            <div className='flex items-center gap-[20px] mt-[10px]'>
              <BaseInputSlider value={value.input ?? ''} onChange={handleInput} />
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                row
                style={{
                  width: '200px',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap'
                }}
                onChange={handleChangeType}
                defaultValue={value.type ?? ''}
                name='radio-buttons-group'
              >
                {listValue.map(group => (
                  <FormControlLabel
                    key={group.value}
                    value={group.value}
                    control={<Radio className='!p-2' />}
                    className='p-0'
                    label={group.label}
                  />
                ))}
              </RadioGroup>
            </div>
          )
        }}
      />
    </Box>
  )
}

export default BaseValueSelect

BaseValueSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
