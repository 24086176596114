import { Box, Modal } from '@mui/material'
import useModalStore from '../../store/useModalStore'
import { FormProvider, useForm } from 'react-hook-form'
import { HeaderModalCommon } from '../components/HeaderModalCommon'
import { useContext, useMemo } from 'react'
import useFlowZoneStore from '../../store/useFlowZoneStore'
import { mainTypes } from '../../utils/filters'
import { TitleContentModalCommon } from '../components/TitleContentModalCommon'
import { FooterModalCommon } from '../components/FooterModalCommon'
import { HomeContextProvided } from '../../screens/Home/Home'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '720px',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  overflow: 'hidden'
}

const ProgrammingElementModal = () => {
  const { nodes, setNodes } = useContext(HomeContextProvided)

  const { statusModal, setStatusModal } = useModalStore()
  const { typeDrop, programmingElementPopup, isDropElements, setIsDropElements } = useFlowZoneStore()

  const methods = useForm({
    defaultValues: programmingElementPopup.value
  })

  const { handleSubmit } = methods

  const mainTypeElement = useMemo(() => {
    return mainTypes[programmingElementPopup.type]
  }, [programmingElementPopup])

  const handleUpdateElement = data => {
    if (programmingElementPopup.tab === 'operator') {
      setNodes(() =>
        nodes.map(node => {
          if (
            node.data.selectedOperatorElements &&
            node.data.selectedOperatorElements.id === programmingElementPopup.id
          ) {
            return {
              ...node,
              data: {
                ...node.data,
                selectedOperatorElements: {
                  ...node.data.selectedOperatorElements,
                  value: data,
                  label: data.name
                }
              }
            }
          } else {
            return { ...node }
          }
        })
      )
    } else if (programmingElementPopup.tab === 'widget') {
      const keyChild = typeDrop === 'area' ? 'selectedZone' : 'selectedOperatorElements'
      setNodes(() =>
        nodes.map(node => {
          if (node.data[keyChild] && node.data[keyChild].id === programmingElementPopup.parentId) {
            console.log(keyChild)
            return {
              ...node,
              data: {
                ...node.data,
                widgets: [...node.data.widgets].map(widget => {
                  if (widget.id === programmingElementPopup.id) {
                    return { ...widget, value: data }
                  } else {
                    return { ...widget }
                  }
                })
              }
            }
          } else {
            return { ...node }
          }
        })
      )
    }

    setStatusModal('programmingEleModal')
    if (isDropElements) {
      setIsDropElements(false)
    }
  }

  return (
    <Modal
      open={statusModal.programmingEleModal}
      onClose={() => {
        if (isDropElements) {
          setNodes(() =>
            nodes.map(node => {
              if (node.id === programmingElementPopup.parentId) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    widgets: [...node.data.widgets].filter(widget => widget.id !== programmingElementPopup.id)
                  }
                }
              } else {
                return { ...node }
              }
            })
          )
          setIsDropElements(false)
        }
        setStatusModal('programmingEleModal')
      }}
    >
      <FormProvider {...methods}>
        <Box sx={style}>
          <HeaderModalCommon
            icon={programmingElementPopup.icon}
            mainTypeElement={mainTypeElement}
            title={<>{programmingElementPopup.label}</>}
            statusKeyModal='programmingEleModal'
            typeModal='program'
          />
          <form onSubmit={handleSubmit(handleUpdateElement)}>
            <Box py={3}>
              <Box sx={{ px: 3 }}>
                <TitleContentModalCommon
                  title={programmingElementPopup.titleHeader}
                  isWidget={programmingElementPopup.type === 'widgets'}
                  isFilterShow={programmingElementPopup.key !== 'color'}
                />
              </Box>
              <Box sx={{ maxHeight: '600px', my: 1, px: 3, overflowY: 'auto' }}>
                {programmingElementPopup.content && programmingElementPopup.content}
              </Box>
              <Box sx={{ px: 3 }}>
                <FooterModalCommon />
              </Box>
            </Box>
          </form>
        </Box>
      </FormProvider>
    </Modal>
  )
}

export default ProgrammingElementModal
