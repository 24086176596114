import PropTypes from 'prop-types'

const BaseColorBoxSmall = ({ color, content, customClassName = '' }) => {
  return (
    <div
      className={`w-[20px] h-[20px] flex justify-center items-center ${customClassName}`}
      style={{
        backgroundColor: color
      }}
    >
      {content}
    </div>
  )
}

export default BaseColorBoxSmall

BaseColorBoxSmall.propTypes = {
  color: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  customClassName: PropTypes.string
}
