import PropTypes from 'prop-types'

const ColorIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path
        d='m-14.882-25.343-.209-.383-.052-.089c-1.4-2.482-3.867-3.751-7.358-3.751-4.737 0-6.737 1.949-7.03 3.773a2.923 2.923 0 0 0 2.055 3.259.3.3 0 0 1 .032.012c.066.03 1.6.737 1.6 1.886s-1.531 1.855-1.6 1.885a.176.176 0 0 1-.032.012 2.924 2.924 0 0 0-2.055 3.26c.293 1.824 2.293 3.772 7.03 3.772 2.959 0 5.2-.916 6.669-2.72 4.332-5.173.95-10.916.95-10.916zm-11.657 1.517a1.754 1.754 0 0 1-1.754-1.754 1.755 1.755 0 0 1 1.754-1.754 1.754 1.754 0 0 1 1.754 1.754 1.755 1.755 0 0 1-1.754 1.754zm1.754 10.206a2.232 2.232 0 0 1-2.232-2.233 2.232 2.232 0 0 1 2.232-2.232 2.232 2.232 0 0 1 2.233 2.232 2.235 2.235 0 0 1-2.233 2.233zm2.711-11.48a1.755 1.755 0 0 1-1.754-1.754 1.754 1.754 0 0 1 1.754-1.754 1.754 1.754 0 0 1 1.754 1.754 1.756 1.756 0 0 1-1.754 1.754zm2.392.159a1.755 1.755 0 0 1 1.754-1.759 1.754 1.754 0 0 1 1.754 1.754 1.754 1.754 0 0 1-1.754 1.754 1.756 1.756 0 0 1-1.754-1.75zm3.359 6.234a1.768 1.768 0 0 1-1.767-1.768 1.767 1.767 0 0 1 1.767-1.767 1.767 1.767 0 0 1 1.768 1.767 1.768 1.768 0 0 1-1.768 1.767z'
        transform='translate(37.595 36.636)'
        fill={color}
      />
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default ColorIcon

ColorIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
