import { Group, Rect, Text } from 'react-konva'
import useVideoZoneStore from '../../store/useVideoZoneStore'
import { createRef, useEffect, useState } from 'react'
import useCommonStore from '../../store/useCommonStore'

const padding = 10

const SelectedZonePopUp = () => {
  const { listSelected, setListSelected } = useVideoZoneStore(state => state.listZone)
  const { setEditZone } = useVideoZoneStore(state => state.editZone)
  const { setStatus } = useCommonStore()

  const textRefs = listSelected.list.map(() => createRef())

  // State to store the max width
  const [maxWidth, setMaxWidth] = useState(0)

  useEffect(() => {
    let tempMaxWidth = 0
    textRefs.forEach(ref => {
      if (ref.current) {
        const width = ref.current.getTextWidth()
        if (width > tempMaxWidth) {
          tempMaxWidth = width
        }
      }
    })
    setMaxWidth(tempMaxWidth + 2 * padding) // set the state with the max width found
  }, [listSelected, textRefs])

  const handleChooseArea = item => {
    setEditZone(item)
    setStatus('edit')
    setListSelected({
      pos: { x: 0, y: 0 },
      list: []
    })
  }

  return (
    <Group x={listSelected.pos.x} y={listSelected.pos.y}>
      <Rect width={maxWidth} height={25 * listSelected.list.length} fill='white' stroke='black' />
      {listSelected.list.map((selectedItem, index) => (
        <>
          <Text
            key={index}
            text={selectedItem.name}
            padding={5}
            offsetY={-20 * index}
            ref={textRefs[index]}
            onMouseUp={() => handleChooseArea(selectedItem)}
          />
        </>
      ))}
    </Group>
  )
}

export default SelectedZonePopUp
