import { Box } from "@mui/material"
import PropTypes from 'prop-types'

const LicensePlateLabel = ({ data }) => {
  const { value } = data

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {
        value.plates.map((plate, index) => {
          return (
            <Box key={plate.id} sx={{
              p: 1,
              bgcolor: '#fff',
              borderTop: index !== 0 ? '1px solid #808080' : 'none'
            }}>
              {plate.value}
            </Box>
          )
        })
      }
    </Box>
  )
}

export default LicensePlateLabel

LicensePlateLabel.displayName = 'LicensePlateLabel'

LicensePlateLabel.propTypes = {
  data: PropTypes.object,
}
