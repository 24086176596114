import PropTypes from 'prop-types'
import useFlowZoneStore from '../../../store/useFlowZoneStore'
import { Handle, Position } from 'reactflow'
import { memo, useContext, useMemo, useState } from 'react'
import { getElementLabel, getProgrammingElementData, mainTypes } from '../../../utils/filters'
import useModalStore from '../../../store/useModalStore'
import { HomeContextProvided } from '../../../screens/Home/Home'

const styleHandle = {
  width: '24px',
  height: '5px',
  borderRadius: '0px',
  backgroundColor: '#6d7374',
  border: 'none'
}

export const NodeElement = memo(({ data, id, isConnectable }) => {
  const { selectedOperatorElements, widgets, index } = data

  const { nodes, setNodes } = useContext(HomeContextProvided)

  const { typeDrop, selectedWidgetElements, setProgrammingElementPopup, setIsDropElements, setTypeDrop } =
    useFlowZoneStore()
  const { setStatusModal } = useModalStore()

  const [isHover, setIsHover] = useState(false)

  const mainTypestElement = useMemo(() => {
    return mainTypes[selectedOperatorElements.type]
  }, [selectedOperatorElements])

  const elementContent = useMemo(() => {
    return getProgrammingElementData(selectedWidgetElements.key)
  }, [selectedWidgetElements])

  const operatorData = useMemo(() => {
    return getProgrammingElementData(selectedOperatorElements.key)
  }, [selectedOperatorElements])

  const onDragEnter = () => {
    if (selectedWidgetElements.id && !isHover) {
      setIsHover(true)
    }
  }

  const onDragLeave = () => {
    if (selectedWidgetElements.id && isHover) {
      setIsHover(false)
    }
  }

  const onDropRight = () => {
    setNodes(() =>
      nodes.map(node => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              widgets: [
                ...node.data.widgets,
                {
                  ...elementContent,
                  ...selectedWidgetElements,
                  parentId: id
                }
              ]
            }
          }
        } else {
          return { ...node }
        }
      })
    )

    handleOpenModal({
      ...elementContent,
      ...selectedWidgetElements,
      parentId: id
    })
    setIsDropElements(true)
    if (typeDrop !== 'element') {
      setTypeDrop('element')
    }
  }

  const handleOpenModal = item => {
    setProgrammingElementPopup({ ...item })
    setStatusModal('programmingEleModal')
  }

  return (
    <div
      className='relative border-[3px] border-[#a3abac] hover:border-[#e03] rounded-lg'
      id={id}
      style={{
        background: mainTypestElement.bgcolor
      }}
    >
      <Handle
        type='target'
        position={Position.Top}
        isConnectable={isConnectable}
        style={{
          ...styleHandle,
          top: -4
        }}
      />
      <div
        onDoubleClick={() =>
          handleOpenModal({
            ...operatorData,
            ...selectedOperatorElements
          })
        }>
        <div
          className='flex rounded-md overflow-hidden'
        >
          <div
            className='w-[44px] h-[44px] flex items-center justify-center'
            style={{
              background: mainTypestElement.color
            }}
          >
            {selectedOperatorElements.icon}
          </div>
          <div className='px-[14px]'>
            <p className='leading-[44px]'>
              [{index}] {selectedOperatorElements.label}
            </p>
          </div>
        </div>
        {
          getElementLabel({
            ...operatorData,
            ...selectedOperatorElements
          })
        }
      </div>
      {(selectedWidgetElements.id || widgets.length > 0) && (
        <div
          className={`absolute top-[-3px] right-[-45px] group flex flex-col gap-[2px] w-[40px] min-h-[120px] ${selectedWidgetElements.id && 'bg-[#d3d3d3]'
            }`}
          onDrop={onDropRight}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
        >
          {widgets.map((item, index) => {
            return (
              <div
                key={`${id}_${index}`}
                className='p-1 bg-[#858d8e] rounded-md'
                onDoubleClick={() => {
                  handleOpenModal(item)
                  if (typeDrop !== 'element') {
                    setTypeDrop('element')
                  }
                }}
              >
                {item.icon}
              </div>
            )
          })}
        </div>
      )}
      <Handle
        type='source'
        position={Position.Bottom}
        id='b'
        isConnectable={isConnectable}
        style={{
          ...styleHandle,
          bottom: -4
        }}
      />
    </div>
  )
})

NodeElement.displayName = 'NodeElement'

NodeElement.propTypes = {
  data: PropTypes.object,
  idNode: PropTypes.string,
  isConnectable: PropTypes.bool,
  id: PropTypes.string
}
