import { Circle, Group, Line } from 'react-konva'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const TrafficDraw = ({ pointsTraffic }) => {
  const pointsArr = useMemo(() => {
    return pointsTraffic.reduce((acc, point) => [...acc, [point.x, point.y]], []).flat()
  }, [pointsTraffic])

  return (
    <Group>
      <Line points={pointsArr} strokeWidth={3} closed stroke='#fff' fill='#ffffff59' />
      {pointsTraffic.map((point, index) => (
        <Circle key={index} x={point.x} y={point.y} width={5} height={5} fill='white' stroke='white' strokeWidth={3} />
      ))}
    </Group>
  )
}

export default TrafficDraw

TrafficDraw.propTypes = {
  pointsTraffic: PropTypes.array.isRequired
}
