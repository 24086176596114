import PropTypes from 'prop-types'

const TimeOccurrenceWholeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Popup_Time of occurrence_Whole history" width="28" height="28" viewBox="0 0 28 28">
      <g data-name="Group 1192">
        <path data-name="Path 502" d="M11.4 199.269a3.094 3.094 0 1 1-3.094-3.094 3.094 3.094 0 0 1 3.094 3.094" transform="translate(2.013 -184.95)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 503" d="M15.477 197.992h-1.751a.474.474 0 0 1-.474-.475v-.432a.474.474 0 0 1 .474-.474h1.751a.475.475 0 0 1 .475.474v.432a.475.475 0 0 1-.475.475" transform="translate(.508 -185.032)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 504" d="M17.955 197.992h-.432a.475.475 0 0 1-.475-.475v-.432a.475.475 0 0 1 .475-.474h.432a.475.475 0 0 1 .475.474v.432a.475.475 0 0 1-.475.475" transform="translate(-.203 -185.032)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 505" d="M20.153 197.992h-.432a.475.475 0 0 1-.475-.475v-.432a.475.475 0 0 1 .475-.474h.432a.475.475 0 0 1 .475.474v.432a.475.475 0 0 1-.475.475" transform="translate(-.614 -185.032)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 506" d="M15.9 203.031h-2.174a.474.474 0 0 1-.474-.475v-.432a.474.474 0 0 1 .474-.474H15.9a.475.475 0 0 1 .475.474v.432a.475.475 0 0 1-.475.475" transform="translate(.508 -185.975)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 507" d="M18.236 203.031H17.8a.475.475 0 0 1-.475-.475v-.432a.475.475 0 0 1 .475-.474h.432a.475.475 0 0 1 .475.474v.432a.475.475 0 0 1-.475.475" transform="translate(-.255 -185.975)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 508" d="M20.153 203.031h-.432a.475.475 0 0 1-.475-.475v-.432a.475.475 0 0 1 .475-.474h.432a.475.475 0 0 1 .475.474v.432a.475.475 0 0 1-.475.475" transform="translate(-.614 -185.975)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 509" d="M20.111 200.512h-5.305a.474.474 0 0 1-.474-.475v-.432a.474.474 0 0 1 .474-.474h5.305a.475.475 0 0 1 .475.474v.432a.475.475 0 0 1-.475.475" transform="translate(.306 -185.504)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 510" d="M1.244 204.2v-11.34a.648.648 0 0 1 .647-.648h1.933a.648.648 0 0 1 .648.648v.035a.648.648 0 0 1-.648.647h-.207a.648.648 0 0 0-.648.648v8.68a.648.648 0 0 0 .648.648h.206a.647.647 0 0 1 .648.647v.035a.647.647 0 0 1-.648.647H1.891a.647.647 0 0 1-.647-.647" transform="translate(2.756 -184.208)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 511" d="M25.254 192.856V204.2a.648.648 0 0 1-.648.648h-1.932a.648.648 0 0 1-.648-.648v-.035a.647.647 0 0 1 .648-.647h.206a.648.648 0 0 0 .648-.648v-8.68a.648.648 0 0 0-.648-.648h-.206a.647.647 0 0 1-.648-.647v-.035a.648.648 0 0 1 .648-.648h1.932a.648.648 0 0 1 .648.648" transform="translate(-1.134 -184.208)" style={{ fill: "#6d7374" }} />
      </g>
      <path data-name="Rectangle 666" style={{ fill: "none" }} d="M0 0h28v28H0z" />
    </svg>
  )
}

export default TimeOccurrenceWholeIcon

TimeOccurrenceWholeIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
