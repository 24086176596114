import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import useVideoZoneStore from '../store/useVideoZoneStore'
import { useMemo } from 'react'

const BaseCheckGate = ({ name, label }) => {
  const { listZones } = useVideoZoneStore(state => state.listZone)

  const listGate = useMemo(() => {
    return listZones.filter(zone => zone.type === 'gate')
  }, [listZones])

  return (
    <Box>
      {label && <p className='text-[18px] text-[#444c4d] font-bold'>{label}</p>}
      <Controller
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              {listGate &&
                listGate.map(item => (
                  <FormControlLabel
                    key={item.value}
                    control={<Checkbox value={item.id} onChange={onChange} checked={value.includes(item.id)} />}
                    label={<Typography color='#444c4d'>{item.name}</Typography>}
                  />
                ))}
            </Box>
          )
        }}
      />
    </Box>
  )
}

export default BaseCheckGate

BaseCheckGate.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
