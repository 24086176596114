import { produce } from 'immer'
import { create } from 'zustand'

const useModalStore = create(set => ({
  statusModal: {
    confirmEditZone: false,
    confirmDeleteZone: false,
    areaModal: false,
    programmingEleModal: false
  },

  setStatusModal: key =>
    set(
      produce(state => {
        state.statusModal[key] = !state.statusModal[key]
      })
    )
}))

export default useModalStore
