import PropTypes from 'prop-types'
import useFlowZoneStore from '../../../store/useFlowZoneStore'
import { Handle, Position } from 'reactflow'
import { getNodeAreaIcon } from '../../../utils/common'
import { memo, useContext, useMemo, useState } from 'react'
import useModalStore from '../../../store/useModalStore'
import { getAreaData, getAreaLabel, getProgrammingElementData } from '../../../utils/filters'
import { HomeContextProvided } from '../../../screens/Home/Home'

const styleHandle = {
  width: '24px',
  height: '5px',
  borderRadius: '0px',
  backgroundColor: '#6d7374',
  border: 'none'
}

export const NodeArea = memo(({ data, id, isConnectable }) => {
  const { selectedZone, widgets, index } = data

  const { nodes, setNodes } = useContext(HomeContextProvided)

  const {
    setElementPopup,
    selectedWidgetElements,
    typeDrop,
    setIsDropElements,
    setProgrammingElementPopup,
    setTypeDrop
  } = useFlowZoneStore()
  const { setStatusModal } = useModalStore()

  const [isHover, setIsHover] = useState(false)

  const areaData = useMemo(() => {
    return getAreaData(selectedZone)
  }, [selectedZone])

  const widgetData = useMemo(() => {
    return getProgrammingElementData(selectedWidgetElements.key)
  }, [selectedWidgetElements])

  const onDragEnter = () => {
    if (selectedWidgetElements.id && !isHover) {
      setIsHover(true)
    }
  }

  const onDragLeave = () => {
    if (selectedWidgetElements.id && isHover) {
      setIsHover(false)
    }
  }

  const onDropRight = () => {
    setNodes(() =>
      nodes.map(node => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              widgets: [
                ...node.data.widgets,
                {
                  ...widgetData,
                  ...selectedWidgetElements,
                  parentId: id
                }
              ]
            }
          }
        } else {
          return { ...node }
        }
      })
    )

    setProgrammingElementPopup({ ...widgetData, ...selectedWidgetElements, parentId: id })
    setStatusModal('programmingEleModal')
    setIsDropElements(true)
    if (typeDrop !== 'area') {
      setTypeDrop('area')
    }
  }

  const handleOpenModal = item => {
    setElementPopup({ ...item })
    setStatusModal('areaModal')
  }

  return (
    <div className='relative bg-[#e1e6e6] border-[3px] border-[#a3abac] hover:border-[#e03] rounded-lg' id={id}>
      <Handle
        type='target'
        position={Position.Top}
        isConnectable={isConnectable}
        style={{
          ...styleHandle,
          top: -4
        }}
      />
      <div
        className='flex rounded-md overflow-hidden'
        onDoubleClick={() => handleOpenModal({ ...areaData, ...selectedZone })}
      >
        <div className='w-[44px] h-[44px] bg-[#6d7374] flex items-center justify-center'>
          {getNodeAreaIcon(areaData.type)}
        </div>
        <div className='px-[14px]'>
          <p className='leading-[44px]'>
            [{index}] {areaData.name}
          </p>
        </div>
      </div>
      {
        getAreaLabel({
          ...areaData,
          ...selectedZone
        })
      }
      {(selectedWidgetElements.id || widgets.length > 0) && (
        <div
          className={`absolute top-[-3px] right-[-45px] group flex flex-col gap-[2px] w-[40px] min-h-[120px] ${selectedWidgetElements.id && 'bg-[#d3d3d3]'
            }`}
          onDrop={onDropRight}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
        >
          {widgets.map((item, index) => {
            return (
              <div
                key={`${id}_${index}`}
                className='p-1 bg-[#858d8e] rounded-md'
                onDoubleClick={() => {
                  setProgrammingElementPopup(item)
                  setStatusModal('programmingEleModal')
                  if (typeDrop !== 'area') {
                    setTypeDrop('area')
                  }
                }}
              >
                {item.icon}
              </div>
            )
          })}
        </div>
      )}
      <Handle
        type='source'
        position={Position.Bottom}
        id='b'
        isConnectable={isConnectable}
        style={{
          ...styleHandle,
          bottom: -4
        }}
      />
    </div>
  )
})

NodeArea.displayName = 'NodeArea'

NodeArea.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  isConnectable: PropTypes.bool,
  index: PropTypes.number
}
