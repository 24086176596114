import PropTypes from 'prop-types'

const TimeOccurrenceNowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Popup_Time of occurrence_Now" width="28" height="28" viewBox="0 0 28 28">
      <g data-name="Group 1195">
        <path data-name="Path 495" d="M17.436 6.631a4.957 4.957 0 1 1-4.957-4.958 4.957 4.957 0 0 1 4.957 4.958" transform="translate(1.581 7.688)" style={{ fill: "#ed1c24" }} />
        <path data-name="Path 496" d="M17.436 6.631a4.957 4.957 0 1 1-4.957-4.958 4.957 4.957 0 0 1 4.957 4.958z" transform="translate(1.581 7.688)" style={{ strokeMiterlimit: 10, stroke: '#f2f2f2', strokeWidth: '1.663px', fill: 'none' }} />
        <path data-name="Path 497" d="M17.909 6.489A5.571 5.571 0 1 1 12.337.917a5.572 5.572 0 0 1 5.572 5.572z" transform="translate(1.722 7.829)" style={{ stroke: "#ed1c24", strokeWidth: ".763px", strokeMiterlimit: 10, fill: "none" }} />
        <path data-name="Path 498" d="M1.244 11.987V.648A.648.648 0 0 1 1.891 0h1.933a.648.648 0 0 1 .648.648v.035a.647.647 0 0 1-.648.646h-.207a.649.649 0 0 0-.648.649v8.68a.648.648 0 0 0 .648.648h.206a.647.647 0 0 1 .648.646v.035a.648.648 0 0 1-.648.649H1.891a.648.648 0 0 1-.647-.649" transform="translate(2.756 8.001)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 499" d="M25.254.647v11.34a.648.648 0 0 1-.648.648h-1.932a.648.648 0 0 1-.648-.648v-.035a.648.648 0 0 1 .648-.647h.206a.648.648 0 0 0 .648-.648v-8.68a.648.648 0 0 0-.648-.648h-.206a.647.647 0 0 1-.648-.647V.647A.648.648 0 0 1 22.674 0h1.932a.648.648 0 0 1 .648.648" transform="translate(-1.134 8.001)" style={{ fill: "#6d7374" }} />
      </g>
      <path data-name="Rectangle 665" style={{ fill: "none" }} d="M0 0h28v28H0z" />
    </svg>
  )
}

export default TimeOccurrenceNowIcon

TimeOccurrenceNowIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
