import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { createListZone, createStatusZone, createParamsZone, createEditZone } from './videoZone'

const useVideoZoneStore = create(
  devtools(set => ({
    listZone: { ...createListZone(set) },
    statusZone: { ...createStatusZone(set) },
    paramsZone: { ...createParamsZone(set) },
    editZone: { ...createEditZone(set) }
  }))
)

export default useVideoZoneStore
