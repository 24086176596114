import { Box, TextField } from '@mui/material'
import BaseRadioGroup from '../../../common/BaseRadioGroup'
import { useRef } from 'react'
import CloseWindowIcon from '../../../assets/icons/CloseWindowIcon'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { listSelectionMode } from '../../../utils/common'

export const LicensePlateContent = () => {
  const { control, register } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'plates'
  })

  const containerRef = useRef(null)

  const handleRemove = indexToRemove => {
    remove(indexToRemove)
  }

  return (
    <>
      <BaseRadioGroup
        listGroup={listSelectionMode}
        childrenWidth={120}
        label='Selection mode'
        name='selection_mode'
        width={350}
      />
      <Box className='mt-3 mb-6'>
        <p className='text-[#444c4d] font-bold text-[18px] mb-[10px]'>License plates</p>
        <Box
          className='h-[197px] p-1 rounded-[10px] mb-[10px] overflow-auto'
          style={{ border: '1px solid #c7cccc' }}
          ref={containerRef}
        >
          <Box className='flex flex-wrap' style={{ columnGap: '18px', rowGap: '4px' }}>
            {fields.map((plateItem, index) => {
              return (
                <Box key={plateItem.id} className='flex items-center h-min w-min'>
                  <TextField {...register(`plates.${index}.value`)} variant='outlined' className='h-[40px] w-[200px]' size='small' />
                  <Box
                    className='w-[20px] h-[20px] bg-[#858d8e] flex items-center cursor-pointer justify-center rounded ml-[6px]'
                    onClick={() => handleRemove(index)}
                  >
                    <CloseWindowIcon width={8} height={8} color='#fff' />
                  </Box>
                </Box>
              )
            })}
          </Box>
        </Box>
        <Box className='flex justify-between'>
          <Box className='flex items-center'>
            <Box
              className='w-[40px] h-[40px] bg-[#858d8e] rounded cursor-pointer mr-[10px] text-[#fff] font-semibold flex items-center justify-center text-xl select-none'
              onClick={() => {
                append({
                  value: ''
                })
                setTimeout(() => {
                  if (containerRef.current) {
                    containerRef.current.scrollTop = containerRef.current.scrollHeight
                  }
                }, 0)
              }}
            >
              +
            </Box>
            <span className='text-[#444c4d] select-none'>Add license plate</span>
          </Box>
          <button className='!px-[22px] !py-[11px] !h-[40px] !rounded-[20px] !bg-[#ee0033] text-[#fff]'>
            Import from file
          </button>
        </Box>
      </Box>
    </>
  )
}
