import { produce } from 'immer'
import { create } from 'zustand'

const useFlowZoneStore = create(set => ({
  isShowFull: false,

  selectedWidgetElements: {},
  selectedOperatorElements: {},

  // Value when open modal
  elementPopup: {},
  programmingElementPopup: {},

  // Status diff onDrop and onDbClick
  isDropElements: false,
  typeDrop: 'area',

  selectedDeleteEdgeId: '',

  setSelectedDeleteEdgeIdId: value =>
    set(
      produce(state => {
        state.selectedDeleteEdgeId = value
      })
    ),
  setTypeDrop: value =>
    set(
      produce(state => {
        state.typeDrop = value
      })
    ),
  setIsDropElements: value =>
    set(
      produce(state => {
        state.isDropElements = value
      })
    ),
  setProgrammingElementPopup: value =>
    set(
      produce(state => {
        state.programmingElementPopup = value
      })
    ),
  setElementPopup: value =>
    set(
      produce(state => {
        state.elementPopup = value
      })
    ),
  setSelectedOperatorElements: value =>
    set(
      produce(state => {
        state.selectedOperatorElements = value
      })
    ),
  updateStatusShowFull: () =>
    set(
      produce(state => {
        state.isShowFull = !state.isShowFull
      })
    ),
  setSelectedWidgetElements: value =>
    set(
      produce(state => {
        state.selectedWidgetElements = value
      })
    )
}))

export default useFlowZoneStore
