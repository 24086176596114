import OffsetContent from '../modals/components/OffsetContent'
import TimeBlockContent from '../modals/components/TimeBlockContent'

export const getTimeModeDescription = value => {
  switch (value) {
    case 'now':
      return {
        text: 'Selects trajectories occurring now. Returns whole trajectories.',
        content: <></>
      }
    case 'whole_history':
      return {
        text: 'Selects trajectories occuring at any time up to now.',
        content: <></>
      }
    case 'offset':
      return {
        text: 'Selects trajectories occurring in a sliding interval defined by an offset from now.',
        content: <OffsetContent />
      }
    case 'time_blocks':
      return {
        text: 'Selects trajectories occurring in the following time block.',
        content: <TimeBlockContent />
      }
    default:
      return {
        text: '',
        content: <></>
      }
  }
}
