import PropTypes from 'prop-types'

const BusIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <path
          d='M7.548 7.731a.424.424 0 0 1 .423-.422h2.958v3.38H7.548zm4.226-.422h3.381v3.38h-3.381zm4.226 0h3.381v3.38H16zm4.226 0h2.81a.837.837 0 0 1 .824.7l.486 3a7.1 7.1 0 0 1 .085.951h-2.937l-1.268-1.268zm-13.526 0v7.607a.848.848 0 0 0 .845.845h.045a2.623 2.623 0 0 1-.042-.423 2.113 2.113 0 0 1 4.226 0 2.623 2.623 0 0 1-.042.423h7.691a2.623 2.623 0 0 1-.042-.423 2.113 2.113 0 1 1 4.226 0 2.623 2.623 0 0 1-.042.423h.887a.848.848 0 0 0 .845-.845v-2.7a9.785 9.785 0 0 0-.105-1.353l-.486-3.021a1.7 1.7 0 0 0-1.67-1.395H7.548a.88.88 0 0 0-.845.867z'
          fill={color}
          fillRule='evenodd'
        />
        <path
          d='M22.973 15.338a1.479 1.479 0 1 1-1.479-1.479 1.479 1.479 0 0 1 1.479 1.479z'
          fill={color}
          fillRule='evenodd'
        />
        <path
          d='M11.14 15.338a1.479 1.479 0 1 1-1.479-1.479 1.479 1.479 0 0 1 1.479 1.479z'
          fill={color}
          fillRule='evenodd'
        />
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default BusIcon

BusIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
