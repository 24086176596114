import ZoneGateIcon from '../assets/icons/ZoneGateIcon'
import MovementIcon from '../assets/icons/MovementIcon'
import TrafficIcon from '../assets/icons/TrafficIcon'
import ZoomIn from '../assets/icons/ZoomIn'
import ZoomOut from '../assets/icons/ZoomOut'
import GridIcon from '../assets/icons/GridIcon'
import GateZoneMovementSettingIcon from '../assets/icons/GateZoneMovementSettingIcon'
import GateDisplayIcon from '../assets/icons/GateDisplayIcon'
import ZoneDisplayIcon from '../assets/icons/ZoneDisplayIcon'
import FlagDisplaySettingIcon from '../assets/icons/FlagDisplaySettingIcon'
import CategoryIcon from '../assets/icons/CategoryIcon'
import ColorIcon from '../assets/icons/ColorIcon'
import SpeedIcon from '../assets/icons/SpeedIcon'
import LicensePlateIcon from '../assets/icons/LicensePlateIcon'
import IDIcon from '../assets/icons/IDIcon'
import TrajectoryTailsIcon from '../assets/icons/TrajectoryTailsIcon'
import BoundingBoxes from '../assets/icons/BoundingBoxes'
import TrajectorySettingsIcon from '../assets/icons/TrajectorySettingsIcon'
import CarIcon from '../assets/icons/CarIcon'
import TruckIcon from '../assets/icons/TruckIcon'
import BusIcon from '../assets/icons/BusIcon'
import MotorbikeIcon from '../assets/icons/MotorbikeIcon'
import BicycleIcon from '../assets/icons/BicycleIcon'
import XeBaGacIcon from '../assets/icons/XeBaGacIcon'
import PedestrianIcon from '../assets/icons/PedestrianIcon'
import UnknownVehicleIcon from '../assets/icons/UnknownVehicleIcon'
import FullScreenIcon from '../assets/icons/FullScreenIcon'
import EnableAnonymizationIcon from '../assets/icons/EnableAnonymizationIcon'
import StationnaryDurationIcon from '../assets/icons/StationnaryDurationIcon'
import DurationOfOccurrenceIcon from '../assets/icons/DurationOfOccurrenceIcon'
import EnableScoreIcon from '../assets/icons/EnableScoreIcon'
import PlayPauseIcon from '../assets/icons/PlayPauseIcon'

export const listDraw = [
  {
    key: 'zoneGate',
    label: 'Create Zone/Gate',
    icon: color => <ZoneGateIcon color={color} />,
    colorBg: '#c1c1c1'
  },
  {
    key: 'movement',
    label: 'Create movement',
    icon: color => <MovementIcon color={color} />,
    colorBg: '#c1c1c1'
  },
  {
    key: 'traffic',
    label: 'Create Traffic',
    icon: color => <TrafficIcon color={color} />,
    colorBg: '#c1c1c1'
  }
]

export const listMenu2 = [
  {
    key: 'zoomIn',
    label: 'Zoom in',
    icon: color => <ZoomIn color={color} />,
    type: 'hover',
    action: () => console.log('Zoom in')
  },
  {
    key: 'zoomOut',
    label: 'Zoom out',
    icon: color => <ZoomOut color={color} />,
    type: 'hover',
    action: () => console.log('Zoom in')
  },
  {
    key: 'fullScreen',
    label: 'Full screen',
    icon: color => <FullScreenIcon color={color} />,
    type: 'hover',
    action: () => console.log('Full screen')
  }
]

export const listMenuPause = [
  {
    key: 'grid',
    label: 'Grid',
    icon: color => <GridIcon color={color} />,
    type: 'hover',
    action: () => console.log('Grid'),
    colorBg: '#858d8e'
  },
  {
    key: 'pause',
    label: 'Pause',
    icon: color => <PlayPauseIcon color={color} />,
    type: 'hover',
    action: () => console.log('Pause'),
    colorBg: '#858d8e'
  }
]

export const listMenuSetting = [
  {
    key: 'displaySetting',
    label: 'Gate/zone/movement display settings',
    icon: () => <GateZoneMovementSettingIcon />,
    type: 'click',
    tooltip: true,
    children: [
      {
        key: 'gate',
        label: 'gates',
        typeChild: 1,
        icon: () => <GateDisplayIcon />,
        colorBg: '#c7cccc'
      },
      {
        key: 'zone',
        label: 'zones',
        typeChild: 1,
        icon: () => <ZoneDisplayIcon />,
        colorBg: '#c7cccc'
      },
      {
        key: 'movement',
        label: 'Movement',
        typeChild: 1,
        icon: () => <MovementIcon />,
        colorBg: '#c7cccc'
      },
      {
        key: 'traffic',
        label: 'traffic',
        typeChild: 2,
        icon: () => <TrafficIcon />,
        colorBg: '#c7cccc'
      },
      {
        key: 'anonymization',
        label: 'anonymization',
        typeChild: 2,
        icon: () => <EnableAnonymizationIcon />,
        colorBg: '#c7cccc'
      }
    ]
  }
]

export const list4 = [
  {
    key: 'flagSetting',
    label: 'Flag display settings',
    icon: color => <FlagDisplaySettingIcon color={color} />,
    type: 'click',
    tooltip: true,
    children: [
      {
        key: 'category',
        label: 'category',
        icon: () => <CategoryIcon />,
        tooltip: true,
        action: () => console.log('Display Line'),
        colorBg: '#c7cccc'
      },
      {
        key: 'color',
        label: 'color',
        tooltip: true,
        icon: () => <ColorIcon />,
        action: () => console.log('Display Zone'),
        colorBg: '#c7cccc'
      },
      {
        key: 'speed',
        label: 'speed',
        tooltip: true,
        icon: () => <SpeedIcon />,
        action: () => console.log('Display Movement'),
        colorBg: '#c7cccc'
      },
      {
        key: 'licensePlate',
        label: 'license plate',
        tooltip: true,
        icon: () => <LicensePlateIcon />,
        action: () => console.log('Display Traffic'),
        colorBg: '#c7cccc'
      },
      {
        key: 'id',
        label: 'id',
        tooltip: true,
        icon: () => <IDIcon />,
        action: () => console.log('Display Traffic'),
        colorBg: '#c7cccc'
      },
      {
        key: 'stationaryDuration',
        label: 'stationary duration',
        tooltip: true,
        icon: () => <StationnaryDurationIcon />,
        action: () => console.log('Display Traffic'),
        colorBg: '#c7cccc'
      },
      {
        key: 'trajectoryTails',
        label: 'trajectory tails',
        tooltip: true,
        icon: () => <TrajectoryTailsIcon />,
        action: () => console.log('Display Traffic'),
        colorBg: '#c7cccc'
      },
      {
        key: 'boundingBoxes',
        label: 'bounding boxes',
        tooltip: true,
        icon: () => <BoundingBoxes />,
        action: () => console.log('Display Traffic'),
        colorBg: '#c7cccc'
      },
      {
        key: 'durationOfOccurrence',
        label: 'duration of occurrence',
        tooltip: true,
        icon: () => <DurationOfOccurrenceIcon />,
        action: () => console.log('Display Traffic'),
        colorBg: '#c7cccc'
      },
      {
        key: 'score',
        label: 'score',
        tooltip: true,
        icon: () => <EnableScoreIcon />,
        action: () => console.log('Display Traffic'),
        colorBg: '#c7cccc'
      }
    ]
  }
]

export const list5 = [
  {
    key: 'trajectoryDisplaySettings',
    label: 'Trajectory display settings',
    icon: () => <TrajectorySettingsIcon />,
    type: 'click',
    tooltip: true,
    children: [
      {
        key: 'car',
        label: 'car',
        icon: () => <CarIcon />,
        tooltip: true,
        action: () => console.log('Display Line'),
        color: '#ed0009'
      },
      {
        key: 'truck',
        label: 'Truck',
        tooltip: true,
        icon: () => <TruckIcon />,
        action: () => console.log('Display truck'),
        color: '#ff8900'
      },
      {
        key: 'bus',
        label: 'Bus',
        tooltip: true,
        icon: () => <BusIcon />,
        action: () => console.log('Display bus'),
        color: '#fff500'
      },
      {
        key: 'motorbike',
        label: 'motorbike',
        tooltip: true,
        icon: () => <MotorbikeIcon />,
        action: () => console.log('Display motorbike'),
        color: '#7ffc38'
      },
      {
        key: 'bicycle',
        label: 'Bicycle',
        tooltip: true,
        icon: () => <BicycleIcon />,
        action: () => console.log('Display Traffic'),
        color: '#03ffe2'
      },
      {
        key: 'xe_ba_gac',
        label: 'Xe ba gác',
        tooltip: true,
        icon: () => <XeBaGacIcon />,
        action: () => console.log('Display xe_ba_gac'),
        color: '#0093ff'
      },
      {
        key: 'pedestrian',
        label: 'Pedestrian',
        tooltip: true,
        icon: () => <PedestrianIcon />,
        action: () => console.log('Display pedestrian'),
        color: '#0000e6'
      },
      {
        key: 'unknow',
        label: 'Unknow',
        tooltip: true,
        icon: () => <UnknownVehicleIcon />,
        action: () => console.log('Display Unknow'),
        color: '#000'
      }
    ]
  }
]

export const getSettingInfo = ({ list, activeList, callBack }) => {
  return list.map(childItem => {
    const active = activeList[childItem.key]
    let labelText

    if (active) {
      labelText = (childItem.typeChild === 1 ? 'Hide' : 'Enable') + ' ' + childItem.label
    } else {
      labelText = (childItem.typeChild === 1 ? 'Show' : 'Disenable') + ' ' + childItem.label
    }

    return {
      ...childItem,
      tooltip: true,
      label: labelText,
      active,
      action: callBack(childItem.key)
    }
  })
}

export const pageSettingList = [
  {
    value: 1,
    className: 'rounded-tl-sm rounded-tr-md rounded-br-md rounded-bl-md'
  },
  {
    value: 2,
    className: 'rounded-tl-md rounded-tr-sm rounded-br-md rounded-bl-md'
  },
  {
    value: 4,
    className: 'rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-sm'
  },
  {
    value: 3,
    className: 'rounded-tl-md rounded-tr-md rounded-br-sm rounded-bl-md'
  }
]
