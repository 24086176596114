import { useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'
import BaseRadioGroup from '../../../common/BaseRadioGroup'
import { listTimeModeModal } from '../../../utils/common'
import { getTimeModeDescription } from '../../../utils/modal'

const TimeOfOccurrenceContent = () => {
  const { watch } = useFormContext()
  const time_mode = watch('time_mode')

  return (
    <Box>
      <BaseRadioGroup width={400} childrenWidth={150} listGroup={listTimeModeModal} name='time_mode' label='Type' />
      <p className='text-[#444c4d] mt-[12px]'>{getTimeModeDescription(time_mode).text}</p>
      {getTimeModeDescription(time_mode).content}
    </Box>
  )
}

export default TimeOfOccurrenceContent
