import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'

const LabelRadio = ({ icon, text, width }) => {
  return (
    <div
      className={`flex gap-[5px] items-center`}
      style={{
        width: width || 'auto'
      }}
    >
      {icon && icon}
      <p className='text-[#444c4d]'>{text}</p>
    </div>
  )
}

const BaseRadioGroup = ({ width, childrenWidth, description, listGroup, name, label }) => {
  const { control } = useFormContext()

  return (
    <Box mt='12px'>
      {label && <p className='text-[#444c4d] font-bold text-[18px]'>{label}</p>}
      {description && <p className='text-[#444c4d] mt-[10px]'>{description}</p>}
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => {
          return (
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              row
              style={{
                width: width || '100%',
                justifyContent: 'space-between',
                flexWrap: 'wrap'
              }}
              defaultValue={value}
              onChange={onChange}
              name='radio-buttons-group'
            >
              {listGroup.map(group => (
                <FormControlLabel
                  key={group.value}
                  value={group.value}
                  control={<Radio className='!p-2' />}
                  className='p-0'
                  label={<LabelRadio width={childrenWidth} icon={group.icon} text={group.label} />}
                />
              ))}
            </RadioGroup>
          )
        }}
      />
    </Box>
  )
}

export default BaseRadioGroup

LabelRadio.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string.isRequired,
  width: PropTypes.number
}

BaseRadioGroup.propTypes = {
  width: PropTypes.number,
  description: PropTypes.string,
  childrenWidth: PropTypes.number,
  name: PropTypes.string.isRequired,
  listGroup: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired
}
