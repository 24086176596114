import { Box, TextField, Typography } from '@mui/material'
import BaseRadioGroup from '../../../common/BaseRadioGroup'
import {
  listColorMapType,
  listHeatMapType,
  listOperatorAttributeHeatMap,
  listTimeModeModal,
  listValueRange
} from '../../../utils/common'
import BaseOneSlider from '../../../common/BaseOneSlider'
import { useFormContext } from 'react-hook-form'

const HeatMapContent = () => {
  const { watch } = useFormContext()

  const value_range = watch('value_range')

  return (
    <Box>
      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listHeatMapType}
        name='heatmap_type'
        label='Heatmap Type'
      />

      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listOperatorAttributeHeatMap}
        name='operator_attribute'
        label='Operator attribute'
      />

      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listTimeModeModal}
        name='time_mode'
        label='Time mode'
      />

      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listColorMapType}
        name='color_type'
        label='Color map type'
      />

      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listValueRange}
        name='value_range'
        label='Value range'
      />

      {value_range === 'manual' && (
        <Box
          sx={{
            display: 'flex',
            gap: '65px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <Typography>Min:</Typography>
            <TextField
              size='small'
              sx={{
                width: '200px',
                borderRadius: '10px'
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <Typography>Max:</Typography>
            <TextField
              size='small'
              sx={{
                width: '200px',
                borderRadius: '10px'
              }}
            />
          </Box>
        </Box>
      )}

      <BaseOneSlider label='Opacity' name='opacity' />

      <BaseOneSlider label='Dispersion radius (px)' name='dispersion' />
    </Box>
  )
}

export default HeatMapContent
