import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import BaseSlider from './BaseSlider'
import { HuePicker } from 'react-color'
import CloseWindowIcon from '../assets/icons/CloseWindowIcon'
import AddBackGroundIcon from '../assets/icons/AddBackGroundIcon'
import { v4 } from 'uuid'

const ColorPicker = ({ index, name, handleRemoveColor }) => {
  const { control } = useFormContext()

  return (
    <Box>
      <Controller
        control={control}
        name={`${name}.${index}`}
        render={({ field: { onChange, value } }) => {
          return (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  borderTop: index !== 0 ? '1px solid #808080' : 'none',
                  paddingTop: '10px',
                  marginTop: '10px',
                  width: 'fit-content'
                }}
              >
                <BaseSlider name={`${name}.${index}.interval`} />
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#858d8e',
                    borderRadius: '4px',
                    mt: '10px'
                  }}
                  onClick={() => handleRemoveColor(index)}
                >
                  <CloseWindowIcon color='#fff' width={14} height={14} />
                </Box>
              </Box>
              <HuePicker
                width='500px'
                className='color-picker'
                styles={{
                  default: {
                    picker: {
                      marginTop: '10px'
                    }
                  }
                }}
                color={value.color}
                onChange={e => {
                  onChange({
                    ...value,
                    color: e.hex
                  })
                }}
              />
            </>
          )
        }}
      />
    </Box>
  )
}

const BaseColorGroupForm = ({ name, title, description }) => {
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: name
  })

  const handleAddColor = () => {
    append({
      id: v4(),
      interval: {
        start: 0,
        end: 100
      },
      color: 'red'
    })
  }

  const handleRemoveColor = index => {
    remove(index)
  }

  return (
    <Box>
      {title && <p className='text-[#444c4d] mt-[24px] font-bold text-[18px]'>{title}</p>}
      {description && <p className='text-[#444c4d] mt-[8px]'>{description}</p>}
      {fields.map((color, index) => (
        <ColorPicker key={color.id} name={name} index={index} handleRemoveColor={handleRemoveColor} />
      ))}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          mt: '10px',
          cursor: 'pointer'
        }}
        onClick={handleAddColor}
      >
        <AddBackGroundIcon />
        <Typography>Add a new color</Typography>
      </Box>
    </Box>
  )
}

export default BaseColorGroupForm

BaseColorGroupForm.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

ColorPicker.propTypes = {
  index: PropTypes.number.isRequired,
  handleRemoveColor: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
}
