import { Box, Modal, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import CloseWindowIcon from '../assets/icons/CloseWindowIcon'
import { useState } from 'react'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  width: '688px',
  borderRadius: '8px'
}

const AutomaticModal = ({
  isOpen,
  handleClose,
  title,
  customClassName,
  handleAccept,
  handleUndo,
  handleSaveAccept
}) => {
  const [isCheckSave, setIsCheckSave] = useState(false)

  const handleCheck = e => {
    setIsCheckSave(e.target.checked)
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          ...style
        }}
        className={customClassName || ''}
      >
        <div className='flex relative justify-center p-2 bg-[#e03] rounded-tl-[8px] rounded-tr-[8px]'>
          <Typography id='modal-modal-title' variant='h6' component='h4' className='text-[#fff]'>
            {title ?? 'Data loss warning'}
          </Typography>
          <div className='absolute top-[10px] right-[10px] cursor-pointer' onClick={handleClose}>
            <CloseWindowIcon color='#fff' />
          </div>
        </div>
        <div className='px-[38px] pt-[20px]'>
          <p className='text-[18px] font-bold text-[#444c4d]'>Operation will cause data loss</p>
          <Typography mt={2}>
            This operation will change the analytic and increase its sequence number - all uncached historical data will
            be lost. Do you really want to accept this operation?
          </Typography>
        </div>
        <div className='flex justify-between items-center px-[38px] pt-[18px] pb-[31px]'>
          <div className='flex gap-[8px] items-center text-[#fff]'>
            <button
              className='px-[22px] py-[11px] rounded-full bg-[#ee0033]'
              onClick={() => {
                handleAccept()
                if (isCheckSave) {
                  handleSaveAccept()
                }
              }}
            >
              Accept operation
            </button>
            <button
              className='px-[22px] py-[11px] rounded-full bg-[#858d8e]'
              onClick={() => {
                handleUndo()
                if (isCheckSave) {
                  handleSaveAccept()
                }
              }}
            >
              Undo
            </button>
          </div>
          <label className='flex items-center gap-[8px]'>
            <input type='checkbox' onChange={handleCheck} />
            Accept automatically during this session
          </label>
        </div>
      </Box>
    </Modal>
  )
}

export default AutomaticModal

AutomaticModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  customClassName: PropTypes.string,
  children: PropTypes.element,
  handleAccept: PropTypes.func,
  handleUndo: PropTypes.func,
  handleSaveAccept: PropTypes.func
}
