import PropTypes from 'prop-types'

const ZoomOut = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g transform='translate(0 163.401)'>
        <rect width='2.509' height='11.411' rx='.741' transform='rotate(-90 -67.926 -78.221)' fill={color} />
      </g>
    </svg>
  )
}

export default ZoomOut

ZoomOut.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
