import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const useStatusAutomatic = create(
  persist(
    (set, get) => ({
      nodeLabelStatus: false,
      moveZone: false,

      setStatus: () => set({ nodeLabelStatus: !get().nodeLabelStatus }),
      setMoveZoneAuto: () => set({ moveZone: !get().moveZone })
    }),
    {
      name: '@automatic', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage) // (optional) by default, 'localStorage' is used
    }
  )
)

export default useStatusAutomatic
