import { Box } from "@mui/material"
import BaseRadioGroup from "../../../common/BaseRadioGroup"
import { listInputTypesSpeedModal } from "../../../utils/common"
import BaseSlider from "../../../common/BaseSlider"
import { BaseCheckBox } from "../../../common/BaseCheckBox"
import { useFormContext } from "react-hook-form"
import BaseValueSelect from "../../../common/BaseValueSelect"

const StationeryDurationContent = () => {
  const { watch } = useFormContext()
  const input_type = watch('input_type')

  return (
    <Box>
      <BaseRadioGroup width={350} childrenWidth={120} listGroup={listInputTypesSpeedModal} name="input_type" label="Type" />
      {
        input_type === 'interval' ? (
          <BaseSlider name='interval' label='Interval' />
        ) : (
          <BaseValueSelect name="val" label='Value' />
        )
      }
      <BaseCheckBox label="Time mode" name="time" />
    </Box>
  )
}

export default StationeryDurationContent