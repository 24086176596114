import PropTypes from 'prop-types'

const IDIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <path
          d='M3089.495 116.51h-1.118a.559.559 0 0 1-.558-.558v-1.676h-6.7v1.676a.559.559 0 0 1-.558.558h-1.116a.559.559 0 0 1-.559-.558v-1.676h-1.116v-3.906a1.677 1.677 0 0 1 .127-.641l-.708-9.586a1.674 1.674 0 0 1 1.546-1.033h1.234v1.116h-1.234a.556.556 0 0 0-.515.346l1.314 8.123h9.869l1.314-8.123a.58.58 0 0 0-.121-.182.562.562 0 0 0-.4-.164h-1.233V99.11h1.233a1.674 1.674 0 0 1 1.547 1.033l-.709 9.586a1.644 1.644 0 0 1 .128.641v3.907h-1.116v1.676a.559.559 0 0 1-.551.557zm-10.049-6.7a.56.56 0 0 0-.513.337 1.08 1.08 0 0 0-.046.221v2.791h11.166v-2.789a1.052 1.052 0 0 0-.047-.221.557.557 0 0 0-.511-.337zm8.931 2.373a.7.7 0 1 1 .7-.7.7.7 0 0 1-.7.701zm-7.815 0a.7.7 0 1 1 .7-.7.7.7 0 0 1-.7.701zm6.14-.14h-4.465v-1.116h4.465v1.115z'
          transform='translate(-3068.471 -89.364)'
          fill={color}
          stroke='transparent'
          strokeMiterlimit='10'
        />
        <g>
          <path
            d='M3084.716 108.833h-4.581a2.948 2.948 0 0 1-2.944-2.945v-4.581a2.947 2.947 0 0 1 2.944-2.944h4.581a2.948 2.948 0 0 1 2.945 2.944v4.581a2.948 2.948 0 0 1-2.945 2.945zm-3.175-8.183v5.9h1.535a2.119 2.119 0 0 0 1.8-.824 3.593 3.593 0 0 0 .615-2.19 4.818 4.818 0 0 0-.218-1.483 1.979 1.979 0 0 0-.729-1.036 2.178 2.178 0 0 0-1.284-.363zm-2.184 0v5.9h1.386v-5.9zm3.656 4.742h-.1v-3.6h.181a.784.784 0 0 1 .754.457 2.673 2.673 0 0 1 .23 1.2c.003 1.292-.356 1.944-1.065 1.944z'
            transform='translate(-3066.426 -93.51)'
            fill={color}
            stroke='transparent'
            strokeMiterlimit='10'
          />
        </g>
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default IDIcon

IDIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
