import PropTypes from 'prop-types'

const TruckIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g transform='translate(37.595)'>
        <path
          d='M-19.066 4.764h-10.873a.567.567 0 0 0-.573.572V12.2a.571.571 0 0 0 .573.572h10.873a.575.575 0 0 0 .573-.572V5.336a.571.571 0 0 0-.573-.572z'
          fill={color}
        />
        <path
          d='M-30.517 15.637a.574.574 0 0 0 .572.573h.618a2.882 2.882 0 0 1 2.449-2.289h-3.061a1.61 1.61 0 0 1-.578-.1z'
          fill={color}
        />
        <path
          d='M-13.955 16.21h.709a.575.575 0 0 0 .573-.573v-5.013c0-.9-2.192-3-3.394-3h-1.282V12.2a1.722 1.722 0 0 1-1.717 1.717h-7.068a2.883 2.883 0 0 1 2.45 2.289h4.084a2.884 2.884 0 0 1 2.821-2.318 2.889 2.889 0 0 1 2.822 2.318zm-2.455-7.177c2.93-.143 2.81 2.884 2.81 2.884h-2.81z'
          fill={color}
        />
        <circle cx='1.728' cy='1.728' r='1.728' transform='translate(-18.507 15.039)' fill={color} />
        <circle cx='1.728' cy='1.728' r='1.728' transform='translate(-28.236 15.039)' fill={color} />
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default TruckIcon

TruckIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
