import { memo, useMemo, useState } from 'react'
import Widgets from './Widgets'
import PropTypes from 'prop-types'
import { Box, Collapse, Typography } from '@mui/material'
import TabFilters from './TabFilters'
import { listMenuFilter } from '../../../utils/filters'
import ArrowHideIcon from '../../../assets/icons/ArrowHideIcon'
import ArrowShowIcon from '../../../assets/icons/ArrowShowIcon'
import Operators from './Operators'
import useFlowZoneStore from '../../../store/useFlowZoneStore'

const SideBarFlow = memo(({ setIsFixed, isFixed, setIsDragging, position, setPosition }) => {
  const { isShowFull, updateStatusShowFull } = useFlowZoneStore()
  const [tabSelected, setTabSelected] = useState('operator')
  const [dragging, setDragging] = useState(false)
  const [isMouseReFull, setIsMouseReFull] = useState(false)

  const listMenu = useMemo(() => {
    return listMenuFilter.filter(item => item.tab === tabSelected)
  }, [tabSelected])

  const handleMouseDown = e => {
    if (isMouseReFull) return

    if (!dragging) return

    setIsFixed(true)
    const offsetX = e.clientX - position.left
    const offsetY = e.clientY - position.top

    document.onmouseup = handleMouseUp
    document.onmousemove = e => handleMouseMove(e, offsetX, offsetY)
  }

  const handleMouseMove = (e, offsetX, offsetY) => {
    setIsDragging(dragging)
    if (!dragging) return
    const newLeft = e.clientX - offsetX
    const newTop = e.clientY - offsetY

    setPosition({ top: newTop, left: newLeft })
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    if (!dragging) return

    document.onmouseup = null
    document.onmousemove = null
  }

  return (
    <Box
      className={`${isFixed && 'fixed'}  z-10 pb-5`}
      style={{
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#f5f5f5',
        top: isFixed ? `${position.top}px` : 'unset',
        left: isFixed ? `${position.left}px` : 'unset',
        zIndex: isFixed ? 100 : 'auto'
      }}
      onMouseDown={handleMouseDown}
    >
      <Box className='relative'>
        <Collapse in={isShowFull} orientation='horizontal' collapsedSize={67}>
          <Box style={{ width: '267px' }}>
            <Box
              style={{ cursor: 'move', userSelect: 'none', padding: '17px 10px' }}
              onMouseOver={() => setDragging(true)}
              onMouseOut={() => setDragging(false)}
              className='font-semibold flex items-center '
            >
              {!isShowFull && (
                <Box
                  onClick={e => {
                    e.stopPropagation()
                    updateStatusShowFull()
                  }}
                  style={{ minHeight: '28px' }}
                  className='cursor-pointer w-[24px] flex items-center justify-center ml-2'
                  onMouseOver={() => {
                    setIsMouseReFull(true)
                    setDragging(true)
                  }}
                  onMouseOut={() => setIsMouseReFull(false)}
                  onMouseUp={() => setIsMouseReFull(false)}
                >
                  <ArrowShowIcon />
                </Box>
              )}
              {isShowFull && (
                <>
                  <Typography fontSize={18} className='pr-2 text-[18px] text-[#444c4d] !font-semibold'>
                    Programming elements
                  </Typography>
                  <Box
                    onClick={() => updateStatusShowFull()}
                    style={{ minHeight: '28px' }}
                    className='cursor-pointer flex items-center justify-center w-[24px]'
                    onMouseOver={() => {
                      setIsMouseReFull(true)
                      setDragging(true)
                    }}
                    onMouseOut={() => setIsMouseReFull(false)}
                    onMouseUp={() => setIsMouseReFull(false)}
                  >
                    <ArrowHideIcon />
                  </Box>
                </>
              )}
            </Box>
            {tabSelected === 'operator' ? (
              <Operators listMenu={listMenu} isShowFull={isShowFull} />
            ) : (
              <Widgets listMenu={listMenu} isShowFull={isShowFull} />
            )}
          </Box>
        </Collapse>
        <TabFilters tabActive={tabSelected} setTabSelected={setTabSelected} />
      </Box>
    </Box>
  )
})

SideBarFlow.displayName = 'SideBarFlow'

SideBarFlow.propTypes = {
  setIsFixed: PropTypes.func,
  isFixed: PropTypes.bool,
  isInSide: PropTypes.bool,
  setIsDragging: PropTypes.func,
  position: PropTypes.object,
  setPosition: PropTypes.func,
  isShowFull: PropTypes.bool,
  setIsShowFull: PropTypes.func
}

export default SideBarFlow
