import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { routes } from './routes'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import 'reactflow/dist/style.css'
import { I18nextProvider } from 'react-i18next'
import i18n from './config/i18n'

const router = createBrowserRouter(routes)

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </I18nextProvider>
  )
}

export default App
