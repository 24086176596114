
import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import useVideoZoneStore from '../../../store/useVideoZoneStore'
import { Group, RegularPolygon } from 'react-konva'
import { useDragArea } from '../../../hooks/useDragArea'
import { getTriangles } from '../../../utils/common'
import TagNameArea from '../TagNameArea'
import { getPolygonPointCenter } from '../../../utils/zone'

const Movement = ({ zone, handleDragEnd }) => {
  const { drawType, hideZone } = useVideoZoneStore(state => state.paramsZone)
  const { editZone } = useVideoZoneStore(state => state.editZone)

  const {
    isMove,

    handleDragMove,
    handleMouseUp,
    handleDragStart
  } = useDragArea({ zone: zone })

  const listTriangles = useMemo(() => {
    const list = []

    for (let i = 0; i < zone.pointsLine.length - 1; i++) {
      list.push(
        getTriangles({
          startPoint: zone.pointsLine[i],
          endPoint: zone.pointsLine[i + 1]
        })
      )
    }

    return list
  }, [zone.pointsLine])

  const posTagName = useMemo(() => {
    if (!zone.pointsLine) return []

    if (zone.pointsLine.length <= 3) {
      return [zone.pointsLine[0], zone.pointsLine[zone.pointsLine.length - 1]]
    }

    return [
      zone.pointsLine[Math.floor(zone.pointsLine.length / 2) - 1],
      zone.pointsLine[Math.floor(zone.pointsLine.length / 2)]
    ]
  }, [zone.pointsLine])

  return (
    <Group
      onDragMove={handleDragMove}
      onMouseUp={handleMouseUp}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      draggable={drawType === ''}
      opacity={hideZone === zone.id || (editZone && editZone.id === zone.id) ? 0 : 1}
    >
      {listTriangles.map(item => {
        return item.list.map((triangle, index) => (
          <RegularPolygon
            key={index}
            x={triangle.x}
            y={triangle.y}
            dash={isMove ? [20, 3] : [0, 0]}
            sides={3}
            radius={8}
            fill='#fff'
            rotation={(item.lineAngle * 180) / Math.PI + (zone.direction !== 'right' ? 90 : 270)}
          />
        ))
      })}
      <TagNameArea zone={zone} centerPoint={getPolygonPointCenter(posTagName)} />
    </Group>
  )
}

export default memo(Movement)

Movement.displayName = 'Movement'

Movement.propTypes = {
  zone: PropTypes.object.isRequired,
  handleDragEnd: PropTypes.func.isRequired
}
