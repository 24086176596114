import PropTypes from 'prop-types'

const StationnaryDurationIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <g>
          <g>
            <path
              d='M126.06-31.563v1.747h1.747v.874a5.867 5.867 0 0 1 1.747 0v-.874h1.746v-1.747z'
              transform='translate(-112.673 37.089)'
              fill={color}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d='m134.357-25.709.612-.612.7.7 1.223-1.223-2.621-2.621-1.223 1.223.7.7-.786.786a8.7 8.7 0 0 0-12.062 2.969 8.768 8.768 0 0 0 2.971 11.97 8.616 8.616 0 0 0 11.97-2.971 8.634 8.634 0 0 0-1.484-10.921zm-5.941 12.493a6.078 6.078 0 0 1-6.116-6.116 6.079 6.079 0 0 1 6.116-6.116v6.116h6.116a6.078 6.078 0 0 1-6.116 6.116z'
              transform='translate(-112.408 37.002)'
              fill={color}
            />
          </g>
        </g>
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default StationnaryDurationIcon

StationnaryDurationIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
