import PropTypes from 'prop-types'

const LogOutIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <path
        d='M12.129 43.076a9.4 9.4 0 1 0 9.4 9.4 9.4 9.4 0 0 0-9.4-9.4zM11.274 57.6H9.565A2.564 2.564 0 0 1 7 55.041v-5.128a2.563 2.563 0 0 1 2.563-2.564h1.709a.855.855 0 1 1 0 1.709H9.565a.854.854 0 0 0-.854.855v5.127a.855.855 0 0 0 .854.855h1.709a.855.855 0 1 1 0 1.709zm5.732-4.524L15.3 54.791a.855.855 0 0 1-1.208-1.209l.25-.25h-3.923a.855.855 0 1 1 0-1.709h3.919l-.25-.251a.855.855 0 0 1 1.212-1.209l1.709 1.709a.855.855 0 0 1 0 1.208z'
        fill={color}
        transform='translate(3.871 -36.477)'
      />
    </svg>
  )
}

export default LogOutIcon

LogOutIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
