import PropTypes from 'prop-types'

const XeBaGacIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g transform='translate(-112.786)'>
        <circle cx='1.526' cy='1.526' r='1.526' transform='translate(121.197 14.921)' fill={color} />
        <path
          d='M128.52 7.808a1.012 1.012 0 0 1 1.035-1.12l3.3.143v-.554a.23.23 0 0 0-.215-.229l-11.337-.76a1.444 1.444 0 0 0-1.538 1.342V10.695l9.1.484z'
          fill={color}
        />
        <path
          d='m129.194 15.454-.573.05h.675l-.377-3.742-9.155-.489v4.421a.467.467 0 0 0 .467.466h.413a2.1 2.1 0 0 1 4.165 0h8.224l-.128-.706z'
          fill={color}
        />
        <path
          d='M133.433 10.73c0 5.078-.032 4.235.108 5a9.753 9.753 0 0 0 1.555-3.525 3.018 3.018 0 0 1-1.663-1.479z'
          fill={color}
        />
        <path
          d='M135.172 11.634a4.637 4.637 0 0 0 .013-.54 7.2 7.2 0 0 0-1.752-4.069v2.42c.236.61.922 1.955 1.739 2.189z'
          fill={color}
        />
        <path
          d='M136.761 14.5c.7-.444.786-.662.8-.7a2.473 2.473 0 0 0-2.285-.121 10.868 10.868 0 0 1-.607 1.315c-.147.272-.318.559-.476.8a20.263 20.263 0 0 0 2.57-1.3z'
          fill={color}
        />
        <path
          d='M136.94 15.071c-.238.145-.51.3-.756.423l.364.9a.288.288 0 0 1-.531.222v-.006l-.346-.858c-.278.136-.562.268-.888.414a1.527 1.527 0 1 0 2.16-1.1z'
          fill={color}
        />
        <path
          d='m132.613 10.914-.626-.429a.293.293 0 0 0-.162-.051h-.725a.288.288 0 1 0 0 .576h.635l.306.21a3.653 3.653 0 0 1 .572-.306z'
          fill={color}
        />
        <path d='m132.873 11.148-.638.446a.6.6 0 0 0-.258.5v2.933h.9z' fill={color} />
        <path
          d='M129 11.8h-.4a.265.265 0 0 0-.265.265v2.955h3.635a1.244 1.244 0 0 0-1.237-1.1h-1.249v-1.632A.485.485 0 0 0 129 11.8z'
          fill={color}
        />
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default XeBaGacIcon

XeBaGacIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
