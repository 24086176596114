import PropTypes from 'prop-types'

const CloseWindowIcon = ({ color = '#6d7374', width = 24, height = 24 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g>
        <path
          transform={`translate(${width / 4} ${height / 4})`}
          d={`m0 0 ${width / 2} ${height / 2}`}
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeWidth='2px'
        />
        <path
          transform={`translate(${width / 4} ${height / 4})`}
          d={`M${width / 2} 0 0 ${height / 2}`}
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeWidth='2px'
        />
      </g>
      <path fill='none' d='M0 0h24v24H0z' />
    </svg>
  )
}

export default CloseWindowIcon

CloseWindowIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.number,
  height: PropTypes.number
}
