import { Box, Collapse, Typography } from '@mui/material'
import { memo, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { mainTypes } from '../../../utils/filters'
import ArrowUpIcon from '../../../assets/icons/ArrowUpIcon'
import ArrowDownIcon from '../../../assets/icons/ArrowDownIcon'
import useFlowZoneStore from '../../../store/useFlowZoneStore'
import { v4 } from 'uuid'
import { HomeContextProvided } from '../../../screens/Home/Home'

export const BaseElement = ({ element, parentElement }) => {
  const { handleDragEnd } = useContext(HomeContextProvided)
  const { isShowFull, setSelectedOperatorElements } = useFlowZoneStore()

  const handleDragStart = () => {
    setSelectedOperatorElements({
      ...element,
      id: v4()
    })
  }

  const [classTitle, setClassTitle] = useState('!hidden')
  const [classIcon, setClassIcon] = useState('!rounded-md')

  useEffect(() => {
    if (isShowFull) {
      setClassTitle('')
      setClassIcon('')
    } else {
      setTimeout(() => {
        setClassTitle('!hidden')
        setClassIcon('!rounded-md')
      }, 300)
    }
  }, [isShowFull])

  return (
    <div
      className='flex items-center rounded-md overflow-hidden w-fit cursor-pointer'
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      draggable
    >
      <div
        className={`w-[46px] h-[46px] p-[7px] ${classIcon}`}
        style={{
          background: parentElement.color
        }}
      >
        {element.icon}
      </div>
      <div
        className={`h-[46px] px-[14px] leading-[46px] text-[15px] text-[#444c4d] rounded-tr-md rounded-br-md ${classTitle}`}
        style={{ background: parentElement.bgcolor }}
      >
        {element.label}
      </div>
    </div>
  )
}

export const BaseProgrammingElement = memo(({ parentType, listElement }) => {
  const { isShowFull } = useFlowZoneStore()
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Box>
      <Collapse in={isOpen} collapsedSize={30}>
        <div className='flex items-center cursor-pointer' onClick={() => setIsOpen(prev => !prev)}>
          {mainTypes[parentType].icon}
          {isShowFull && (
            <Typography fontSize={18} className='pr-2 !font-semibold text-[#6d7374] !text-[18px]'>
              {mainTypes[parentType].title}
            </Typography>
          )}
          {isOpen ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          {listElement.map(ele => (
            <BaseElement key={ele.key} element={ele} parentElement={mainTypes[parentType]} />
          ))}
        </Box>
      </Collapse>
    </Box>
  )
})

BaseProgrammingElement.displayName = 'BaseProgrammingElement'

BaseProgrammingElement.propTypes = {
  listElement: PropTypes.array.isRequired,
  parentType: PropTypes.string.isRequired
}

BaseElement.propTypes = {
  element: PropTypes.object.isRequired,
  parentElement: PropTypes.object.isRequired
}
