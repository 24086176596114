import PropTypes from 'prop-types'

const CategoryIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path
        d='M3.74 19.293H2.5a.619.619 0 0 1-.618-.619v-1.857H.645v-4.333a1.864 1.864 0 0 1 .141-.71L0 1.147a1.862 1.862 0 0 1 .4-.6A1.842 1.842 0 0 1 1.715 0h12.716a1.84 1.84 0 0 1 1.311.544 1.819 1.819 0 0 1 .4.6l-.664 8.98h-1.854L15 1.62a.618.618 0 0 0-.571-.382H1.715a.616.616 0 0 0-.437.181.632.632 0 0 0-.134.2L2.6 10.628h3.506v1.238H2.5a.621.621 0 0 0-.569.373 1.234 1.234 0 0 0-.049.245v3.1h4.224v1.238H4.36v1.857a.62.62 0 0 1-.62.614zm0-4.8a.774.774 0 1 1 .773-.774.775.775 0 0 1-.773.781zm2.366-.155H5.6V13.1h.51v1.24z'
        transform='translate(5.201 3.356)'
        stroke='transparent'
        strokeMiterlimit='10'
        fill={color}
      />
      <g>
        <path
          d='M-59.378-13.563a.773.773 0 0 1-.774.773.772.772 0 0 1-.773-.773.773.773 0 0 1 .773-.774.774.774 0 0 1 .774.774z'
          fill={color}
          transform='translate(75.191 36.636)'
        />
        <path d='M-58.295-14.182h4.952v1.237h-4.952z' fill={color} transform='translate(75.191 36.636)' />
        <path
          d='M-52.26-13.563a.773.773 0 0 0 .773.773.773.773 0 0 0 .774-.773.774.774 0 0 0-.774-.774.774.774 0 0 0-.773.774z'
          fill={color}
          transform='translate(75.191 36.636)'
        />
        <path
          d='m-48.533-15.512-2.052-4.952a1.87 1.87 0 0 0-.4-.6 1.853 1.853 0 0 0-1.315-.546h-7.038a1.851 1.851 0 0 0-1.313.544 1.87 1.87 0 0 0-.4.6l-2.049 4.954a1.849 1.849 0 0 0-.142.711v4.332h1.238v1.857a.621.621 0 0 0 .619.619h1.238a.621.621 0 0 0 .619-.619v-1.857h7.428v1.857a.62.62 0 0 0 .618.619h1.238a.62.62 0 0 0 .619-.619v-1.857h1.238V-14.8a1.848 1.848 0 0 0-.146-.712zm-11.376-4.478a.613.613 0 0 1 .133-.2.623.623 0 0 1 .438-.181h7.038a.62.62 0 0 1 .437.181.643.643 0 0 1 .134.2l1.381 3.332H-61.29zm10.279 8.283h-12.379V-14.8a1.2 1.2 0 0 1 .051-.246.619.619 0 0 1 .568-.374h11.141a.62.62 0 0 1 .569.374 1.215 1.215 0 0 1 .05.246z'
          fill={color}
          transform='translate(75.191 36.636)'
        />
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default CategoryIcon

CategoryIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
