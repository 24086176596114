import PropTypes from 'prop-types'

const GateZoneMovementSettingIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <path fill='none' d='M0 0h32v32H0z' />
        <g>
          <path
            d='M31.751-55.509v-3.343a.51.51 0 0 0-.768-.439l-2.745 1.618a.657.657 0 0 0 .023 1.145L31-55.06a.509.509 0 0 0 .751-.449z'
            fill={color}
            transform='translate(0 73.273)'
          />
          <g transform='translate(0 73.273)'>
            <circle cx='2.414' cy='2.414' r='2.414' transform='translate(5.084 -59.687)' fill={color} />
            <circle cx='2.414' cy='2.414' r='2.414' transform='translate(22.088 -59.687)' fill={color} />
            <path transform='translate(8.932 -58.172)' fill={color} d='M0 0h14.324v1.829H0z' />
          </g>
          <path
            d='m13.232-60.2-.018 6.029a.919.919 0 0 0 1.382.795l4.958-2.9a1.186 1.186 0 0 0-.036-2.066L14.586-61a.919.919 0 0 0-1.354.8z'
            fill={color}
            transform='translate(0 73.273)'
          />
          <g>
            <path
              d='M8.524-61.9c7.476-4.6 12.656-1.488 14.952-.171'
              stroke={color}
              strokeLinecap='round'
              strokeMiterlimit='10'
              strokeWidth='2px'
              transform='translate(0 73.273)'
            />
            <path
              d='M23.476-52.642c-7.476 4.6-12.656 1.484-14.952.167'
              stroke={color}
              strokeLinecap='round'
              strokeMiterlimit='10'
              strokeWidth='2px'
              transform='translate(0 73.273)'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default GateZoneMovementSettingIcon

GateZoneMovementSettingIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
