import PropTypes from 'prop-types'

const BicycleIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <g>
          <path
            d='M98.211 9.338a4.825 4.825 0 0 0-1.738.322A13.727 13.727 0 0 1 95.5 6.8a3.184 3.184 0 0 0-3.119-2.562.7.7 0 0 0 0 1.39 1.772 1.772 0 0 1 .48.066 1.8 1.8 0 0 1 1.093.869.673.673 0 0 0-.053.075l-.215.363L92.6 8.844h-5.839l-.332-.835h.138a.695.695 0 0 0 0-1.39h-2.4a.695.695 0 0 0 0 1.39h.762l.607 1.526a4.84 4.84 0 1 0 3.42 5.337h1.276a.694.694 0 0 0 .6-.34l3.652-6.147a18.5 18.5 0 0 0 .771 1.961 4.838 4.838 0 1 0 2.954-1.008zm-14.04 5.534h3.379a3.45 3.45 0 1 1 0-1.389h-3.379a.695.695 0 1 0 0 1.389zm5.67-1.389h-.881a4.836 4.836 0 0 0-1.493-2.846.806.806 0 0 0-.027-.083l-.127-.32h4.458zm8.37 4.145a3.449 3.449 0 0 1-2.324-6 30.487 30.487 0 0 0 1.752 2.942.692.692 0 0 0 .572.3.684.684 0 0 0 .394-.123.694.694 0 0 0 .178-.966 30.369 30.369 0 0 1-1.7-2.866 3.45 3.45 0 1 1 1.126 6.711z'
            fill={color}
            transform='translate(-75.191)'
          />
        </g>
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default BicycleIcon

BicycleIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
