import PropTypes from 'prop-types'

const EventsHistoryIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <path
        d='M8.066-27.641a2.483 2.483 0 0 0-2.483-2.483A2.483 2.483 0 0 0 3.1-27.641a2.483 2.483 0 0 0 2.483 2.483 2.483 2.483 0 0 0 2.483-2.483z'
        transform='translate(2.972 43.641)'
        fill={color}
        fillRule='evenodd'
      />
      <path
        d='M8.066-18.256a2.483 2.483 0 0 0-2.483-2.483A2.483 2.483 0 0 0 3.1-18.256a2.483 2.483 0 0 0 2.483 2.483 2.483 2.483 0 0 0 2.483-2.483z'
        transform='translate(2.972 41.479)'
        fill={color}
        fillRule='evenodd'
      />
      <path
        d='M8.066-37.026a2.483 2.483 0 0 0-2.483-2.483A2.483 2.483 0 0 0 3.1-37.026a2.483 2.483 0 0 0 2.483 2.483 2.483 2.483 0 0 0 2.483-2.483z'
        transform='translate(2.972 45.804)'
        fill={color}
        fillRule='evenodd'
      />
      <path
        d='M5.437-34.14v3.6a.677.677 0 0 0 .676.677.677.677 0 0 0 .677-.677v-3.6a.677.677 0 0 0-.677-.676.677.677 0 0 0-.676.676z'
        transform='translate(2.434 44.723)'
        fill={color}
        fillRule='evenodd'
      />
      <path
        d='M5.447-24.723v3.587a.677.677 0 0 0 .677.677.677.677 0 0 0 .677-.677v-3.587a.677.677 0 0 0-.677-.677.677.677 0 0 0-.677.677z'
        transform='translate(2.431 42.553)'
        fill={color}
        fillRule='evenodd'
      />
      <path
        d='M13.152-34.342h5.869a.677.677 0 0 0 .676-.676.677.677 0 0 0-.676-.677h-5.869a.677.677 0 0 0-.677.677.677.677 0 0 0 .677.676z'
        transform='translate(.812 44.925)'
        fill={color}
        fillRule='evenodd'
      />
      <path
        d='M13.152-24.957h5.869a.677.677 0 0 0 .676-.676.677.677 0 0 0-.676-.677h-5.869a.677.677 0 0 0-.677.677.677.677 0 0 0 .677.676z'
        transform='translate(.812 42.763)'
        fill={color}
        fillRule='evenodd'
      />
      <path
        d='M13.152-15.572h5.869a.678.678 0 0 0 .676-.677.677.677 0 0 0-.676-.677h-5.869a.677.677 0 0 0-.677.677.678.678 0 0 0 .677.677z'
        transform='translate(.812 40.6)'
        fill={color}
        fillRule='evenodd'
      />
      <path
        d='M13.152-37.275h11.285a.678.678 0 0 0 .677-.677.677.677 0 0 0-.677-.677H13.152a.677.677 0 0 0-.677.677.678.678 0 0 0 .677.677z'
        transform='translate(.812 45.601)'
        fill={color}
        fillRule='evenodd'
      />
      <path
        d='M13.152-27.89h11.285a.678.678 0 0 0 .677-.677.677.677 0 0 0-.677-.677H13.152a.677.677 0 0 0-.677.677.678.678 0 0 0 .677.677z'
        transform='translate(.812 43.439)'
        fill={color}
        fillRule='evenodd'
      />
      <path
        d='M13.152-18.5h11.285a.677.677 0 0 0 .677-.677.677.677 0 0 0-.677-.677H13.152a.677.677 0 0 0-.677.677.677.677 0 0 0 .677.677z'
        transform='translate(.812 41.276)'
        fill={color}
        fillRule='evenodd'
      />
    </svg>
  )
}

export default EventsHistoryIcon

EventsHistoryIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
