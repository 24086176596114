import PropTypes from 'prop-types'

const ArrowShowIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='13.682' height='12.535' viewBox='0 0 13.682 12.535'>
      <g data-name='Group 99'>
        <path
          data-name='Path 5'
          d='m1489.89 83.783 4.853 4.853 4.853-4.853'
          transform='rotate(-90 712.32 788.69)'
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeWidth='2px'
        />
        <path
          data-name='Path 6'
          d='m1489.89 83.783 4.853 4.853 4.853-4.853'
          transform='rotate(-90 709.32 791.69)'
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeWidth='2px'
        />
      </g>
    </svg>
  )
}

export default ArrowShowIcon

ArrowShowIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
