import { Box, Slider, SliderThumb } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { BaseInputSlider } from './BaseInputSlider'

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '13px 0',
  width: '350px',
  '& .MuiSlider-thumb': {
    width: '18px',
    height: '22px',
    backgroundColor: '#e03',
    border: '2px solid #fff',
    '&:hover': {
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: 'none'
    },
    '&:nth-child(4)': {
      borderBottomLeftRadius: '1px',
      borderTopLeftRadius: '1px'
    },
    '&:nth-child(3)': {
      borderBottomRightRadius: '1px',
      borderTopRightRadius: '1px'
    }
  },
  '& .MuiSlider-track': {
    height: 20,
    backgroundColor: '#e03',
    border: 'none'
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 20
  }
}))

function AirbnbThumbComponent(props) {
  // eslint-disable-next-line react/prop-types
  const { children, ...other } = props
  return <SliderThumb {...other}>{children}</SliderThumb>
}

const BaseSlider = ({ name, label }) => {
  const { control } = useFormContext()

  return (
    <Box>
      {label && <p className='text-[#444c4d] mt-[24px] font-bold text-[18px]'>{label}</p>}
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => {
          const handleChange = key => e => {
            const inputValue = e.target.value

            if (key === 'end') {
              if (Number(inputValue) > value.start) {
                onChange({
                  ...value,
                  [key]: Number(inputValue)
                })
              } else {
                onChange({
                  ...value,
                  [key]: value.start
                })
              }
            } else {
              if (Number(inputValue) < value.end) {
                onChange({
                  ...value,
                  [key]: Number(inputValue)
                })
              } else {
                onChange({
                  ...value,
                  [key]: value.end
                })
              }
            }
          }

          return (
            <div className='flex mt-[10px] gap-[10px] items-center'>
              <BaseInputSlider
                value={value.start}
                onChange={handleChange('start')}
                key='start'
                min={0}
                max={value.end}
              />
              <AirbnbSlider
                slots={{ thumb: AirbnbThumbComponent }}
                getAriaLabel={index => (index === 0 ? 'Minimum price' : 'Maximum price')}
                onChange={e => {
                  const slider = e.target.value
                  onChange({
                    start: slider[0],
                    end: slider[1]
                  })
                }}
                max={3000}
                min={0}
                value={[value.start, value.end]}
              />
              <BaseInputSlider
                value={value.end}
                onChange={handleChange('end')}
                key='end'
                min={value.start}
                max={3000}
              />
            </div>
          )
        }}
      />
    </Box>
  )
}

export default BaseSlider

BaseSlider.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
