import { Box } from "@mui/material"
import PropTypes from 'prop-types'

const TimeOfOccurrenceLabel = ({ data }) => {
  const { value } = data

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box sx={{
        p: 1,
        bgcolor: '#fff'
      }}>
        {value.time_mode}
      </Box>
    </Box>
  )
}

export default TimeOfOccurrenceLabel

TimeOfOccurrenceLabel.displayName = 'TimeOfOccurrenceLabel'

TimeOfOccurrenceLabel.propTypes = {
  data: PropTypes.object,
}
