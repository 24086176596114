import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { translationFiles } from './translation'
import { EN } from './const'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: EN,
    ns: translationFiles, // Namespaces
    defaultNS: 'common',
    backend: {
      loadPath: './locales/{{lng}}/{{ns}}.json' // Path to your translation files
    },
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
