import PropTypes from 'prop-types'

const MotorbikeIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <g>
          <g>
            <path d='M46.9 15.829a1.881 1.881 0 0 0 3.631 0z' fill={color} transform='translate(-37.595)' />
          </g>
        </g>
        <g>
          <g>
            <path d='M57.594 15.829a1.881 1.881 0 0 0 3.631 0z' fill={color} transform='translate(-37.595)' />
          </g>
        </g>
        <g>
          <g>
            <path
              d='m60.654 11.146-2.135-1.513V8.369a.593.593 0 0 0 .36.121.6.6 0 0 0 .6-.6V6.644a.6.6 0 0 0-1.048-.4.692.692 0 0 0-.417-.139 1.5 1.5 0 0 0-.885.287l-2.371.71a.6.6 0 0 0 .171 1.176.613.613 0 0 0 .173-.025l1.412-.422-.023 2.869-.383 1.091a3.307 3.307 0 0 1-3.051 2.033h-.97a3.69 3.69 0 0 1 .5-2.823h-5.38a3.085 3.085 0 0 0-1.965 2.874v.85a.569.569 0 0 0 .309.506h10.716a3.155 3.155 0 0 1 4.849-2.355l.016-.01a1.412 1.412 0 0 0-.478-1.72z'
              fill={color}
              transform='translate(-37.595)'
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d='M52.73 9.345h-3.714a.6.6 0 0 0-.4 1.053h4.5a.6.6 0 0 0-.4-1.053z'
              fill={color}
              transform='translate(-37.595)'
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d='M59.41 12.972a2.561 2.561 0 0 0-2.539 2.257h5.078a2.561 2.561 0 0 0-2.539-2.257z'
              fill={color}
              transform='translate(-37.595)'
            />
          </g>
        </g>
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default MotorbikeIcon

MotorbikeIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
