import PropTypes from 'prop-types'

const EnableAnonymizationIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <path
          d='M60.7-64.383a.665.665 0 0 0-.939 0l-2.106 2.107a9.6 9.6 0 0 0-4.055-.974c-5.392 0-9.635 5.336-9.813 5.563a.665.665 0 0 0 0 .819 18.384 18.384 0 0 0 4.528 3.937L46.49-51.11a.662.662 0 0 0-.016.938.662.662 0 0 0 .938.016l.016-.016L60.7-63.444a.665.665 0 0 0 0-.939zm-10.423 7.106A3.322 3.322 0 0 1 53.6-60.6a3.241 3.241 0 0 1 1.812.568l-4.562 4.561a3.251 3.251 0 0 1-.568-1.811z'
          fill={color}
          transform='translate(-37.595 73.273)'
        />
        <path
          d='M63.408-57.687a18.515 18.515 0 0 0-3.4-3.193l-3.146 3.146a3.187 3.187 0 0 1 .046.457 3.322 3.322 0 0 1-3.308 3.318 3.175 3.175 0 0 1-.456-.047l-2.257 2.257a9 9 0 0 0 2.713.444c5.393 0 9.635-5.336 9.813-5.563a.662.662 0 0 0 0-.819z'
          fill={color}
          transform='translate(-37.595 73.273)'
        />
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default EnableAnonymizationIcon

EnableAnonymizationIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
