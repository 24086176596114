import PropTypes from 'prop-types'

const StatisticalValueIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <g>
          <path
            d='M-184.975 991.153V977.2a.8.8 0 0 1 .8-.8h2.377a.8.8 0 0 1 .8.8v.042a.8.8 0 0 1-.8.8h-.254a.8.8 0 0 0-.8.8v10.68a.8.8 0 0 0 .8.8h.254a.8.8 0 0 1 .8.8v.043a.8.8 0 0 1-.8.8h-2.377a.8.8 0 0 1-.8-.812z'
            fill={color}
            transform='translate(188.975 -968.405)'
          />
          <path
            d='M-160.222 977.2v13.951a.8.8 0 0 1-.8.8h-2.378a.8.8 0 0 1-.8-.8v-.043a.8.8 0 0 1 .8-.8h.255a.8.8 0 0 0 .8-.8v-10.68a.8.8 0 0 0-.8-.8h-.255a.8.8 0 0 1-.8-.8v-.028a.8.8 0 0 1 .8-.8h2.378a.8.8 0 0 1 .8.8z'
            fill={color}
            transform='translate(188.975 -968.405)'
          />
        </g>
        <g transform='translate(188.975 -968.405)'>
          <circle cx='2.789' cy='2.789' r='2.789' transform='translate(-178.797 984.681)' fill={color} />
          <circle cx='2.789' cy='2.789' r='2.789' transform='translate(-178.797 978.095)' fill={color} />
          <circle cx='2.789' cy='2.789' r='2.789' transform='translate(-171.978 984.681)' fill={color} />
          <circle cx='2.789' cy='2.789' r='2.789' transform='translate(-171.978 978.095)' fill={color} />
        </g>
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default StatisticalValueIcon

StatisticalValueIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
