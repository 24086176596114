import { Box } from '@mui/material'
import BaseRadioGroup from '../../common/BaseRadioGroup'
import { listLightTraffic } from '../../utils/common'
import { BaseCheckBox } from '../../common/BaseCheckBox'

const TrafficContent = () => {
  return (
    <Box>
      <BaseRadioGroup listGroup={listLightTraffic} label='Directions' name='direction' />
      <BaseCheckBox name='time_mode' label='Time mode' />
    </Box>
  )
}

export default TrafficContent
