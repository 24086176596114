import useVideoZoneStore from '../../../store/useVideoZoneStore'
import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import BaseSetting from './BaseSetting'
import { listDraw, listMenuPause } from '../../../utils/sidebar'
import DisplaySetting from './Settings/DisplaySetting'
import FlagSetting from './Settings/FlagSetting'
import TrajectorySetting from './Settings/TrajectorySetting'
import useCommonStore from '../../../store/useCommonStore'
import ImageSetting from './Settings/ImageSetting'
import { Box } from '@mui/material'
import clsx from 'clsx'

const SideBarVideo = memo(({ utils }) => {
  const { zoomIn, zoomOut } = utils
  const { drawType, setDrawType } = useVideoZoneStore(state => state.paramsZone)
  const { isPointerEventNone } = useCommonStore()

  const menuDraw = useMemo(() => {
    return listDraw.map(itemDraw => ({
      ...itemDraw,
      active: drawType === itemDraw.key,
      type: 'hover',
      action: () => setDrawType(drawType === itemDraw.key ? '' : itemDraw.key)
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawType, listDraw])

  const handleChangeImageZoom = key => {
    if (key === 'zoomIn') {
      zoomIn()
    } else if (key === 'zoomOut') {
      zoomOut()
    }
  }

  return (
    <>
      <Box className={clsx('absolute top-[28px] left-[24px] z-10', { 'pointer-events-none': isPointerEventNone })}>
        <BaseSetting listData={menuDraw} />
      </Box>
      <Box className={clsx('absolute top-[192px] left-[24px] z-10', { 'pointer-events-none': isPointerEventNone })}>
        <ImageSetting handleChangeImageZoom={handleChangeImageZoom} />
      </Box>
      <Box className={clsx('absolute top-[356px] left-[24px] z-10', { 'pointer-events-none': isPointerEventNone })}>
        <BaseSetting listData={listMenuPause} />
      </Box>
      <Box className={clsx('absolute bottom-[283px] left-[24px] z-10', { 'pointer-events-none': isPointerEventNone })}>
        <DisplaySetting />
      </Box>
      <Box className={clsx('absolute bottom-[215px] left-[24px] z-10', { 'pointer-events-none': isPointerEventNone })}>
        <FlagSetting />
      </Box>
      <Box className={clsx('absolute bottom-[147px] left-[24px] z-10', { 'pointer-events-none': isPointerEventNone })}>
        <TrajectorySetting />
      </Box>
    </>
  )
})

export default SideBarVideo

SideBarVideo.displayName = 'SideBarVideo'

SideBarVideo.propTypes = {
  utils: PropTypes.object.isRequired
}
