import PropTypes from 'prop-types'

const FullScreenIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <path
          d='M24.144 23.361h4.106a1.026 1.026 0 0 1 0 2.053h-3.079v3.079a1.026 1.026 0 1 1-2.053 0v-4.1a1.026 1.026 0 0 1 1.026-1.032z'
          fill={color}
          transform='translate(0 -.239)'
        />
        <path
          d='M23.072 4.032v4.106A1.026 1.026 0 0 0 24.1 9.164h4.1a1.026 1.026 0 0 0 0-2.053h-3.075V4.032a1.026 1.026 0 0 0-2.053 0z'
          fill={color}
          transform='translate(0 -.239)'
        />
        <path
          d='M6.878 3.983v3.079H3.8a1.027 1.027 0 0 0 0 2.053h4.105a1.026 1.026 0 0 0 1.026-1.026V3.983a1.027 1.027 0 0 0-2.053 0z'
          fill={color}
          transform='translate(0 -.239)'
        />
        <path
          d='M2.724 24.383a1.026 1.026 0 0 0 1.026 1.026h3.079v3.08a1.027 1.027 0 0 0 2.053 0v-4.106a1.026 1.026 0 0 0-1.026-1.027H3.75a1.026 1.026 0 0 0-1.026 1.027z'
          fill={color}
          transform='translate(0 -.239)'
        />
      </g>
    </svg>
  )
}

export default FullScreenIcon

FullScreenIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
