import { Box } from '@mui/material'

export const FooterModalCommon = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '5px',
        color: '#fff',
        mt: '20px'
      }}
    >
      <button className='px-[22px] py-[11px] rounded-full bg-[#858d8e]'>Delete zone</button>
      <button className='px-[22px] py-[11px] rounded-full bg-[#e03]' type='submit'>
        Apply settings
      </button>
    </Box>
  )
}
