import PropTypes from 'prop-types'

const GateDisplayIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <circle cx='2.414' cy='2.414' r='2.414' transform='translate(5.084 13.586)' color={color} />
      <path
        d='M2.414 0A2.414 2.414 0 1 1 0 2.414 2.414 2.414 0 0 1 2.414 0z'
        transform='translate(22.088 13.586)'
        color={color}
      />
      <path transform='translate(8.932 15.101)' color={color} d='M0 0h14.324v1.829H0z' />
    </svg>
  )
}

export default GateDisplayIcon

GateDisplayIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
