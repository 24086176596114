import PropTypes from 'prop-types'

const WidgetIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <rect width='9.334' height='9.334' rx='2' transform='translate(5.999 16.667)' fill={color} />
        <circle cx='4.667' cy='4.667' r='4.667' transform='translate(16.667 16.667)' fill={color} />
        <circle cx='4.667' cy='4.667' r='4.667' transform='translate(5.999 5.999)' fill={color} />
        <rect width='9.334' height='9.334' rx='2' transform='translate(16.667 5.999)' fill={color} />
      </g>
    </svg>
  )
}

export default WidgetIcon

WidgetIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
