import { Box } from '@mui/material'
import BaseRadioGroup from '../../../common/BaseRadioGroup'
import {
  listChartType,
  listColorDisplayed,
  listHistoryMode,
  listOperatorAttributeDistribution,
  listTimeModeModal,
  listVehicleModal
} from '../../../utils/common'
import BaseCheckBoxGroup from '../../../common/BaseCheckBoxGroup'
import { useFormContext } from 'react-hook-form'
import { getTimeModeDescription } from '../../../utils/modal'
import BaseSwitchForm from '../../../common/BaseSwitchForm'
import BaseColorGroupForm from '../../../common/BaseColorGroupForm'
import BaseSetTextField from '../../../common/BaseSetTextField'

const DistributionContent = () => {
  const { watch } = useFormContext()

  const data = watch()
  const { time_mode, mode_window, history, operator_attribute } = data

  return (
    <Box>
      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listOperatorAttributeDistribution}
        name='operator_attribute'
        label='Operator attribute'
      />

      {operator_attribute === 'color_distribution' && (
        <BaseCheckBoxGroup
          width={600}
          childrenWidth={100}
          name='colors'
          label='Displayed classes/attributes'
          description='Select the classes/attributes to be displayed in the distribution widget.'
          listGroup={listColorDisplayed}
        />
      )}

      {operator_attribute === 'category_distribution' && (
        <BaseCheckBoxGroup
          width={500}
          childrenWidth={100}
          name='attributes'
          isIcon={true}
          label='Displayed classes/attributes'
          description='Select the classes/attributes to be displayed in the distribution widget.'
          listGroup={listVehicleModal}
        />
      )}

      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listTimeModeModal}
        name='time_mode'
        label='Time mode'
      />

      <p className='text-[#444c4d] mt-[12px]'>{getTimeModeDescription(time_mode).text}</p>
      {getTimeModeDescription(time_mode).content}

      <BaseSwitchForm
        name='history'
        title='History'
        description='Visualise widget history as chart.'
        label='Time series visualisation'
      />

      {history && (
        <BaseRadioGroup
          width={200}
          childrenWidth={200}
          listGroup={listHistoryMode}
          name='mode_window'
          description='Mode:'
        />
      )}

      {mode_window === 'slide' ? (
        <div className='flex items-end gap-[12px]'>
          <BaseSetTextField customClassNameInput='w-[200px]' label='Window length' direction='column' />
          <BaseSetTextField customClassNameInput='w-[200px]' label='Window’s end offset from now' direction='column' />
        </div>
      ) : (
        <div className='flex items-end gap-[4px]'>
          <BaseSetTextField customClassNameInput='w-[121px]' label='From' direction='column' />
          <BaseSetTextField customClassNameInput='w-[96px]' label='' direction='column' />
          <BaseSetTextField customClassNameInput='w-[140px]' label='Duration' direction='column' />
          <BaseSetTextField customClassNameInput='w-[121px]' label='To' direction='column' />
          <BaseSetTextField customClassNameInput='w-[96px]' label='' direction='column' />
        </div>
      )}

      <BaseRadioGroup
        width={300}
        description='Choose preferred chart type.'
        childrenWidth={100}
        listGroup={listChartType}
        name='chart'
        label='Chart type'
      />

      <BaseColorGroupForm
        name='colors'
        title='Coloring'
        description='Choose the desired color and range values of your widget.'
      />
    </Box>
  )
}

export default DistributionContent
