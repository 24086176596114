import PropTypes from 'prop-types'

const ZoneGateIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g transform='rotate(163 7.047 10.775)'>
        <circle cx='2.384' cy='2.384' transform='translate(0 9.468)' fill={color} r='2.384' />
        <circle cx='2.384' cy='2.384' transform='translate(7.469)' fill={color} r='2.384' />
        <path transform='rotate(-51.633 12.916 4.554)' fill={color} d='M0 0h13.129v1.737H0z' />
      </g>
      <g transform='rotate(-58 23.705 2.52)'>
        <circle cx='2.384' cy='2.384' transform='translate(0 3.526)' fill={color} r='2.384' />
        <circle cx='2.384' cy='2.384' transform='translate(6.489 9.468)' fill={color} r='2.384' />
        <circle cx='2.384' cy='2.384' transform='translate(13.958)' fill={color} r='2.384' />
        <path transform='rotate(42.35 -5.263 6.998)' fill={color} d='M0 0h9.474v1.737H0z' />
        <path transform='rotate(-51.633 16.16 -2.151)' fill={color} d='M0 0h13.129v1.737H0z' />
        <path transform='rotate(-16 18.534 -5.337)' fill={color} d='M0 0h13.129v1.737H0z' />
      </g>
    </svg>
  )
}

export default ZoneGateIcon

ZoneGateIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
