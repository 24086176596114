import PropTypes from 'prop-types'

const AddBackGroundIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Popup_License plate_Add license plate'
      width='40'
      height='40'
      viewBox='0 0 40 40'
    >
      <rect data-name='Rectangle 682' width='40' height='40' rx='4' style={{ fill: '#858d8e' }} />
      <g data-name='Group 24'>
        <path
          data-name='Line 4'
          transform='translate(13 19.995)'
          style={{ fill: 'none', stroke: '#fff', strokeLinecap: 'round', strokeWidth: '2.5px' }}
          d='M0 0h14.031'
        />
        <path
          data-name='Line 5'
          transform='rotate(90 3.517 16.499)'
          style={{ fill: 'none', stroke: '#fff', strokeLinecap: 'round', strokeWidth: '2.5px' }}
          d='M0 0h14.028'
        />
      </g>
    </svg>
  )
}

export default AddBackGroundIcon

AddBackGroundIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
