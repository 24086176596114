import { Box, Slider, Typography, styled } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import PropTypes from 'prop-types'

const PrettoSlider = styled(Slider)({
  color: '#808080',
  height: 20,
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: '#e03'
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#e03',
    border: '2px solid #fff',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    display: 'none'
  },
});

const BaseOneSlider = ({ label, name }) => {
  const { control, watch } = useFormContext()

  return (
    <Box>
      {label && <p className='text-[#444c4d] mt-[24px] font-bold text-[18px]'>{label}</p>}
      <Box mt="12px" display="flex" alignItems="center" gap="14px">
        <Typography width={20}>{watch(name)}</Typography>
        <Controller name={name} control={control} render={({ field: { value, onChange } }) => {
          return (
            <PrettoSlider
              valueLabelDisplay="auto"
              value={value}
              onChange={onChange}
            />
          )
        }} />
      </Box>
    </Box>
  )
}

export default BaseOneSlider

BaseOneSlider.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}