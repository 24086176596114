import { produce } from 'immer'

export const createStatusZone = set => ({
  isMoveZone: false,
  isEdit: false,

  setIsMoveZone: value =>
    set(
      produce(state => {
        state.statusZone.isMoveZone = value
      })
    ),
  setIsEdit: value =>
    set(
      produce(state => {
        state.statusZone.isEdit = value
      })
    )
})
