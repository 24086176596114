import { Box, Typography } from '@mui/material'
import { memo } from 'react'
import OperatorIcon from '../../../assets/icons/OperatorIcon'
import WidgetIcon from '../../../assets/icons/WidgetIcon'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const memuTab = [
  {
    icon: <OperatorIcon />,
    key: 'operator',
    title: 'Operators'
  },
  {
    icon: <WidgetIcon />,
    title: 'Widgets',
    key: 'widget'
  }
]

const TabFilters = memo(({ tabActive, setTabSelected }) => {
  return (
    <Box
      style={{
        position: 'absolute',
        top: '60px',
        right: '100%',
        writingMode: 'vertical-lr',
        display: 'flex',
        gap: '5px',
        zIndex: 12
      }}
    >
      {memuTab.map(itemTab => {
        const isSelect = tabActive === itemTab.key
        return (
          <Box
            key={itemTab.key}
            className='flex rounded-l-md items-center justify-center font- py-2 px-1 cursor-pointer'
            style={{
              backgroundColor: isSelect ? '#f5f5f5' : '#c1c1c1'
            }}
            onClick={() => setTabSelected(itemTab.key)}
          >
            {itemTab.icon}
            <Typography
              style={{
                fontSize: '18px',
                color: isSelect ? '#444c4d' : '#6d7374'
              }}
              className={clsx('p-1', { '!font-semibold': isSelect })}
            >
              {itemTab.title}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
})

TabFilters.propTypes = {
  setTabSelected: PropTypes.func,
  tabActive: PropTypes.string
}

TabFilters.displayName = 'TabFilters'

export default TabFilters
