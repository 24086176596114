import PropTypes from 'prop-types'

const BoundingBoxes = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <g>
          <path
            d='M200.326-19.367a.794.794 0 0 1-.793.794.794.794 0 0 1-.794-.794.793.793 0 0 1 .794-.793.793.793 0 0 1 .793.793z'
            fill={color}
            transform='translate(-187.977 36.636)'
          />
          <path d='M201.437-20h5.079v1.27h-5.079z' fill={color} transform='translate(-187.977 36.636)' />
          <path
            d='M207.627-19.367a.794.794 0 0 0 .794.794.793.793 0 0 0 .793-.794.792.792 0 0 0-.793-.793.793.793 0 0 0-.794.793z'
            fill={color}
            transform='translate(-187.977 36.636)'
          />
          <path
            d='m211.45-21.366-2.1-5.079a1.9 1.9 0 0 0-.412-.617 1.9 1.9 0 0 0-1.347-.558h-7.219a1.9 1.9 0 0 0-1.347.558 1.919 1.919 0 0 0-.412.617l-2.1 5.079a1.908 1.908 0 0 0-.145.729v4.445h1.27v1.9a.637.637 0 0 0 .635.635h1.27a.636.636 0 0 0 .634-.635v-1.9h7.619v1.9a.637.637 0 0 0 .635.635h1.269a.636.636 0 0 0 .635-.635v-1.9h1.27v-4.445a1.908 1.908 0 0 0-.155-.729zm-11.669-4.592a.64.64 0 0 1 .137-.206.633.633 0 0 1 .449-.186h7.219a.633.633 0 0 1 .449.186.64.64 0 0 1 .137.206l1.416 3.417h-11.223zm10.544 8.5h-12.7v-3.175a1.231 1.231 0 0 1 .052-.251.635.635 0 0 1 .583-.384h11.43a.635.635 0 0 1 .583.384 1.231 1.231 0 0 1 .052.251z'
            fill={color}
            transform='translate(-187.977 36.636)'
          />
        </g>
        <g>
          <path
            d='M215.638-7.993h-3.908a.977.977 0 0 1-.978-.977.977.977 0 0 1 .978-.977h2.931v-2.932a.977.977 0 0 1 .977-.977.977.977 0 0 1 .977.977v3.909a.976.976 0 0 1-.977.977z'
            fill={color}
            transform='translate(-187.977 36.636)'
          />
          <path
            d='M216.572-28.348v-3.908a.978.978 0 0 0-.978-.978h-3.908a.977.977 0 0 0-.977.978.977.977 0 0 0 .977.977h2.931v2.931a.977.977 0 0 0 .977.977.977.977 0 0 0 .978-.977z'
            fill={color}
            transform='translate(-187.977 36.636)'
          />
          <path
            d='M193.339-28.4v-2.931h2.931a.976.976 0 0 0 .977-.977.976.976 0 0 0-.977-.977h-3.908a.976.976 0 0 0-.977.977v3.908a.977.977 0 0 0 .977.978.978.978 0 0 0 .977-.978z'
            fill={color}
            transform='translate(-187.977 36.636)'
          />
          <path
            d='M197.2-8.975a.978.978 0 0 0-.977-.977h-2.932v-2.931a.976.976 0 0 0-.977-.977.976.976 0 0 0-.977.977v3.908a.976.976 0 0 0 .977.977h3.909a.977.977 0 0 0 .977-.977z'
            fill={color}
            transform='translate(-187.977 36.636)'
          />
        </g>
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default BoundingBoxes

BoundingBoxes.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
