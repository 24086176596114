import { Group, RegularPolygon, Shape } from 'react-konva'
import PropTypes from 'prop-types'
import useVideoZoneStore from '../../../store/useVideoZoneStore'
import { useDragArea } from '../../../hooks/useDragArea'
import TagNameArea from '../TagNameArea'
import { getPolygonPointCenter } from '../../../utils/zone'
import { useMemo } from 'react'

const Gate = ({ zone, handleDragEnd }) => {
  const { drawType, hideZone } = useVideoZoneStore(state => state.paramsZone)
  const { editZone } = useVideoZoneStore(state => state.editZone)

  const {
    isMove,

    handleDragMove,
    handleMouseUp,
    handleDragStart
  } = useDragArea({ zone: zone })

  const listDirection = useMemo(() => {
    const dx = zone.pointsZone[1][0] - zone.pointsZone[0][0]
    const dy = zone.pointsZone[1][1] - zone.pointsZone[0][1]

    const magnitude = Math.sqrt(dx * dx + dy * dy)
    const dirX = dx / magnitude
    const dirY = dy / magnitude
    const angle = Math.atan2(
      zone.pointsZone[1][1] - zone.pointsZone[0][1],
      zone.pointsZone[1][0] - zone.pointsZone[0][0]
    )

    const rotationInDegrees = angle * (180 / Math.PI)
    const perpX = -dirY
    const perpY = dirX

    const fraction = -18 // You can adjust this value as desired
    const moveX = fraction * dirX
    const moveY = fraction * dirY

    const triangleDistance = 17 // or some fraction of the line's length or a fixed value

    const bgColor1 = zone.direction === 'positive' ? '#000' : '#fff'
    const bgColor2 = zone.direction === 'positive' ? '#fff' : '#000'

    const triangle1 = {
      x: zone.pointsZone[0][0] + perpX * triangleDistance - moveX,
      y: zone.pointsZone[0][1] + perpY * triangleDistance - moveY,
      rotation: rotationInDegrees - 60,
      bgColor: bgColor2
    }

    const triangle2 = {
      x: zone.pointsZone[0][0] - perpX * triangleDistance - moveX,
      y: zone.pointsZone[0][1] - perpY * triangleDistance - moveY,
      rotation: rotationInDegrees - 120,
      bgColor: bgColor1
    }

    const triangle3 = {
      x: zone.pointsZone[1][0] + perpX * triangleDistance + moveX,
      y: zone.pointsZone[1][1] + perpY * triangleDistance + moveY,
      rotation: rotationInDegrees - 60,
      bgColor: bgColor2
    }

    const triangle4 = {
      x: zone.pointsZone[1][0] - perpX * triangleDistance + moveX,
      y: zone.pointsZone[1][1] - perpY * triangleDistance + moveY,
      rotation: rotationInDegrees - 120,
      bgColor: bgColor1
    }

    return [{ ...triangle1 }, { ...triangle2 }, { ...triangle3 }, { ...triangle4 }]
  }, [zone])

  return (
    <Group
      onDragMove={handleDragMove}
      onMouseUp={handleMouseUp}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      draggable={drawType === ''}
      opacity={hideZone === zone.id || (editZone && editZone.id === zone.id) ? 0 : 1}
    >
      <Shape
        sceneFunc={(context, shape) => {
          context.beginPath()
          context.moveTo(zone.pointsZone[0][0], zone.pointsZone[0][1])
          context.lineTo(zone.pointsZone[1][0], zone.pointsZone[1][1])
          context.fillStrokeShape(shape)
        }}
        dash={isMove ? [20, 3] : [0, 0]}
        fill='#00D2FF'
        stroke='#808080'
        strokeWidth={3}
      />
      {listDirection.map((direction, index) => (
        <RegularPolygon
          key={index}
          x={direction.x}
          y={direction.y}
          sides={3}
          radius={8}
          fill={direction.bgColor}
          rotation={direction.rotation}
        />
      ))}
      <TagNameArea zone={zone} centerPoint={getPolygonPointCenter(zone.pointsLine)} />
    </Group>
  )
}

export default Gate

Gate.propTypes = {
  zone: PropTypes.object.isRequired,
  handleDragEnd: PropTypes.func.isRequired
}
