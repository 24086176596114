import { Box, Typography } from "@mui/material"
import PropTypes from 'prop-types'
import { useMemo } from "react"
import { listDirectionGates } from "../../../utils/common"
import TimeOccurrenceNowIcon from "../../../assets/icons/TimeOccurrenceNowIcon"

const GateLabel = ({ data }) => {
  const { value } = data

  const getDirectionData = useMemo(() => {
    return listDirectionGates.find((direction) => direction.value === value.direction)
  }, [value])

  return (
    <Box sx={{
      width: '100%',
      gap: '4px',
      alignItems: 'center',
      bgcolor: '#fff',
    }}>
      {
        value.time_mode === 'now' && (
          <Box sx={{
            p: 1,
            bgcolor: '#fff',
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}>
            <TimeOccurrenceNowIcon />
            <Typography>
              {value.time_mode}
            </Typography>
          </Box>
        )
      }
      <Box sx={{
        p: 1,
        bgcolor: '#fff',
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        borderTop: '1px solid #808080'
      }}>
        {getDirectionData.icon}
        <Typography>
          {value.direction}
        </Typography>
      </Box>
    </Box>
  )
}

export default GateLabel

GateLabel.displayName = 'GateLabel'

GateLabel.propTypes = {
  data: PropTypes.object,
}
