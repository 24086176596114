import { Box } from "@mui/material"
import BaseRadioGroup from "../../../common/BaseRadioGroup"
import { listOperatorAttributeValue, listTimeModeModal } from "../../../utils/common"
import { useFormContext } from "react-hook-form"
import { getTimeModeDescription } from "../../../utils/modal"
import BaseSwitchForm from "../../../common/BaseSwitchForm"

const ValueContent = () => {
  const { watch } = useFormContext()

  const data = watch()
  const { time_mode } = data

  return (
    <Box>
      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listOperatorAttributeValue}
        name='operator_attribute'
        label='Operator attribute'
      />

      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listTimeModeModal}
        name='time_mode'
        label='Time mode'
      />

      <p className='text-[#444c4d] mt-[12px]'>{getTimeModeDescription(time_mode).text}</p>

      <BaseSwitchForm
        name='history'
        title='History'
        description='Visualise widget history as chart.'
        label='Time series visualisation'
      />

      <Box mt='24px'>
        <p className='text-[#444c4d] font-bold text-[18px]'>Coloring</p>
        <p className='text-[#444c4d] mt-[8px]'>
          Choose the desired color and range values of your widget.
        </p>
      </Box>
    </Box>
  )
}

export default ValueContent