import { TextField } from '@mui/material'
import useConfirmEditZone from '../../../hooks/useConfirmEditZone'
import useVideoZoneStore from '../../../store/useVideoZoneStore'
import { useMemo } from 'react'

const ConfirmEdit = () => {
  const { updateZone, deleteZone } = useConfirmEditZone()
  const { editZone, editNameZone, editDirectionGate, editMovementZone } = useVideoZoneStore(state => state.editZone)
  const { updateNameZone, updateDirectionGate, editMovementListZone } = useVideoZoneStore(state => state.listZone)

  const contentConfirm = useMemo(() => {
    switch (editZone.type) {
      case 'gate':
        return (
          <button
            className='px-[22px] py-[11px] rounded-full bg-[#ee0033] text-[#fff]'
            onClick={() => {
              editDirectionGate()
              updateDirectionGate({
                id: editZone.id
              })
            }}
          >
            Change direction
          </button>
        )
      case 'movement':
        return (
          <>
            <button
              className='px-[22px] py-[11px] rounded-full bg-[#ee0033] text-[#fff]'
              onClick={() => {
                editMovementZone({
                  key: 'direction'
                })
                editMovementListZone({
                  id: editZone.id,
                  key: 'direction'
                })
              }}
            >
              Change direction
            </button>
            <div className='flex-1 flex gap-[8px] items-center justify-center'>
              <p>Length:</p>
              <input
                className='w-[68px] h-[40px] rounded-md text-center'
                value={editZone.length}
                onChange={e => {
                  editMovementZone({
                    key: 'length',
                    value: Number(e.target.value)
                  })
                  editMovementListZone({
                    id: editZone.id,
                    key: 'length',
                    value: Number(e.target.value)
                  })
                }}
              />
              <p>m</p>
            </div>
          </>
        )
      default:
        return <></>
    }
  }, [
    editDirectionGate,
    editMovementListZone,
    editMovementZone,
    editZone.id,
    editZone.length,
    editZone.type,
    updateDirectionGate
  ])

  const onChangeNameZone = e => {
    editNameZone({ name: e.target.value })
    updateNameZone({
      id: editZone.id,
      name: e.target.value
    })
  }

  return (
    <div className='flex items-center justify-between w-full h-full gap-[10px]'>
      <div className='flex items-center gap-[10px] h-full'>
        <TextField variant='outlined' value={editZone.name} onChange={onChangeNameZone} />
      </div>
      <div className='flex-1 flex justify-between items-center'>{contentConfirm}</div>
      <div className='flex gap-[20px]'>
        <div className='flex items-center gap-[5px] cursor-pointer' onClick={deleteZone}>
          <p className='text-[#444c4d]'>Delete {editZone.type}</p>
        </div>
        <button className='px-[22px] py-[11px] rounded-full bg-[#ee0033] text-[#fff]' onClick={updateZone}>
          Finish editing
        </button>
      </div>
    </div>
  )
}

export default ConfirmEdit
