import { Box } from '@mui/material'
import { BaseCheckBox } from '../../common/BaseCheckBox'

const ZoneContent = () => {
  return (
    <Box>
      <BaseCheckBox name='time_mode' label='Time mode' />
    </Box>
  )
}

export default ZoneContent
