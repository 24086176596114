import PropTypes from 'prop-types'

const TimeOccurrenceOffsetIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Popup_Time of occurrence_Offset" width="28" height="28" viewBox="0 0 28 28">
      <g data-name="Group 1193">
        <path data-name="Path 500" d="M1.244 387.315v-11.34a.648.648 0 0 1 .647-.648h1.933a.648.648 0 0 1 .648.648v.035a.648.648 0 0 1-.648.647h-.207a.648.648 0 0 0-.648.648v8.68a.648.648 0 0 0 .648.648h.206a.647.647 0 0 1 .648.647v.035a.647.647 0 0 1-.648.647H1.891a.647.647 0 0 1-.647-.647" transform="translate(2.756 -367.327)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 501" d="M25.254 375.975v11.34a.648.648 0 0 1-.648.648h-1.932a.648.648 0 0 1-.648-.648v-.035a.647.647 0 0 1 .648-.647h.206a.648.648 0 0 0 .648-.648V377.3a.648.648 0 0 0-.648-.648h-.206a.647.647 0 0 1-.648-.647v-.035a.648.648 0 0 1 .648-.648h1.932a.648.648 0 0 1 .648.648" transform="translate(-1.134 -367.327)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 517" d="M16.631 385.032v-5.305a.474.474 0 0 1 .475-.474h.432a.474.474 0 0 1 .474.474v5.305a.475.475 0 0 1-.474.475h-.432a.475.475 0 0 1-.475-.475" transform="translate(-.124 -368.062)" style={{ fill: "#6d7374" }} />
        <path data-name="Path 518" d="M12.754 379.75a.473.473 0 0 1 0 .67l-1.074 1.075h4.331a.473.473 0 0 1 .474.473v.762a.473.473 0 0 1-.473.474H11.68l1.075 1.074a.474.474 0 0 1 0 .67l-.534.534a.473.473 0 0 1-.67 0L9.265 383.2a1.2 1.2 0 0 1 0-1.7l2.282-2.283a.474.474 0 0 1 .67 0z" transform="translate(1.321 -368.028)" style={{ fill: "#6d7374" }} />
      </g>
      <path data-name="Rectangle 667" style={{ fill: "none" }} d="M0 0h28v28H0z" />
    </svg>
  )
}

export default TimeOccurrenceOffsetIcon

TimeOccurrenceOffsetIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
