import BicycleIcon from '../assets/icons/BicycleIcon'
import BusIcon from '../assets/icons/BusIcon'
import CarIcon from '../assets/icons/CarIcon'
import DistributionIcon from '../assets/icons/DistributionIcon'
import GateBothDirectionIcon from '../assets/icons/GateBothDirectionIcon'
import GateNegativeDirectionIcon from '../assets/icons/GateNegativeDirectionIcon'
import GatePositiveDirectionIcon from '../assets/icons/GatePositiveDirectionIcon'
import HeatMapIcon from '../assets/icons/HeatMapIcon'
import MotorbikeIcon from '../assets/icons/MotorbikeIcon'
import MovementIcon from '../assets/icons/MovementIcon'
import PedestrianIcon from '../assets/icons/PedestrianIcon'
import TimeOccurrenceNowIcon from '../assets/icons/TimeOccurrenceNowIcon'
import TimeOccurrenceOffsetIcon from '../assets/icons/TimeOccurrenceOffset'
import TimeOccurrenceWholeIcon from '../assets/icons/TimeOccurrenceWholeIcon'
import TimeOfOccurrenceTime from '../assets/icons/TimeOfOccurrenceTime'
import TrafficIcon from '../assets/icons/TrafficIcon'
import TruckIcon from '../assets/icons/TruckIcon'
import UnknownVehicleIcon from '../assets/icons/UnknownVehicleIcon'
import XeBaGacIcon from '../assets/icons/XeBaGacIcon'
import ZoneGateIcon from '../assets/icons/ZoneGateIcon'
import BaseColorBoxSmall from '../common/BaseColorBoxSmall'

export const sideBarWidgetsList = [
  {
    id: 1,
    icon: <DistributionIcon />,
    label: 'Distribution',
    value: 'distribution'
  },
  {
    id: 2,
    icon: <HeatMapIcon />,
    label: 'Heat Map',
    value: 'heatmap'
  }
]

const getMaxListTriangles = ({ startPoint, endPoint }) => {
  const isMax = Math.abs(startPoint[0] - endPoint[0]) - Math.abs(startPoint[1] - endPoint[1])

  if (isMax > 0) {
    return Math.round(Math.abs(startPoint[0] - endPoint[0]) / 20)
  } else {
    return Math.round(Math.abs(startPoint[1] - endPoint[1]) / 20)
  }
}

export const getTriangles = ({ startPoint, endPoint }) => {
  const lineStartX = startPoint[0]
  const lineStartY = startPoint[1]
  const lineEndX = endPoint[0]
  const lineEndY = endPoint[1]

  // Calculate the length and angle of the line
  const dx = lineEndX - lineStartX
  const dy = lineEndY - lineStartY
  const lineLength = Math.sqrt(dx * dx + dy * dy)
  const lineAngle = Math.atan2(dy, dx)

  // Define the number of triangles and the distance between them
  const numberOfTriangles = getMaxListTriangles({ startPoint, endPoint })
  const distanceBetweenTriangles = lineLength / numberOfTriangles

  // Calculate and store the positions of the triangles
  const triangles = []

  for (let i = 1; i <= numberOfTriangles; i++) {
    const distance = i * distanceBetweenTriangles
    const x = lineStartX + Math.cos(lineAngle) * distance
    const y = lineStartY + Math.sin(lineAngle) * distance
    triangles.push({ x, y })
  }

  return {
    list: triangles,
    lineAngle,
    lineStartX,
    lineEndX,
    lineStartY,
    lineEndY
  }
}

export const getTextArea = (type, number) => {
  let nameType = 'Gate'

  if (type === 'movement') {
    nameType = 'Movement'
  } else if (type === 'zone') {
    nameType = 'Zone'
  } else if (type === 'traffic') {
    nameType = 'Traffic'
  }

  return `${nameType} ${number}`
}

export function isPointInPolygon(point, polygonPoints) {
  let x = point.x,
    y = point.y

  let inside = false
  for (let i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i++) {
    let xi = polygonPoints[i][0],
      yi = polygonPoints[i][1]
    let xj = polygonPoints[j][0],
      yj = polygonPoints[j][1]

    // eslint-disable-next-line no-mixed-operators
    let intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}

export function isPointGate(point, polygonPoints) {
  let x = point.x,
    y = point.y

  let inside = false

  const minX = Math.min(polygonPoints[0][0], polygonPoints[1][0])
  const maxX = Math.max(polygonPoints[0][0], polygonPoints[1][0])
  const minY = Math.min(polygonPoints[0][1], polygonPoints[1][1])
  const maxY = Math.max(polygonPoints[0][1], polygonPoints[1][1])

  if (x >= minX && x <= maxX && y >= minY && y <= maxY) {
    inside = true
  }

  return inside
}

export const getNodeAreaIcon = type => {
  if (type === 'zone' || type === 'gate') {
    return <ZoneGateIcon color='#fff' />
  } else if (type === 'movement') {
    return <MovementIcon color='#fff' />
  }

  return <TrafficIcon color='#fff' />
}

export const getDrawTypeIcon = type => {
  if (type === 'zoneGate') {
    return <ZoneGateIcon color='#fff' />
  } else if (type === 'movement') {
    return <MovementIcon color='#fff' />
  }

  return <TrafficIcon color='#fff' />
}

export const listDirectionGates = [
  {
    value: 'positive',
    icon: <GatePositiveDirectionIcon />,
    label: 'Positive direction'
  },
  {
    value: 'negative',
    icon: <GateNegativeDirectionIcon />,
    label: 'Negative direction'
  },
  {
    value: 'both',
    icon: <GateBothDirectionIcon />,
    label: 'Both direction'
  }
]

export const listTypesSpeedModal = [
  {
    value: 'now',
    label: 'Now'
  },
  {
    value: 'minium',
    label: 'Minium'
  },
  {
    value: 'maximum',
    label: 'Maximum'
  },
  {
    value: 'current',
    label: 'Current'
  },
  {
    value: 'section',
    label: 'Section speed'
  }
]

export const listInputTypesSpeedModal = [
  {
    value: 'interval',
    label: 'Interval'
  },
  {
    value: 'value',
    label: 'Value'
  }
]

export const listTimeModeModal = [
  {
    value: 'now',
    label: 'Now',
    icon: <TimeOccurrenceNowIcon />
  },
  {
    value: 'whole_history',
    label: 'Whole History',
    icon: <TimeOccurrenceWholeIcon />
  },
  {
    value: 'offset',
    label: 'Offset',
    icon: <TimeOccurrenceOffsetIcon />
  },
  {
    value: 'time_blocks',
    label: 'Time Blocks',
    icon: <TimeOfOccurrenceTime />
  }
]

export const listColorModal = [
  '#000',
  '#071afe',
  '#954c00',
  '#fed700',
  '#00fe01',
  '#808080',
  '#800000',
  '#fea501',
  '#c00',
  '#c0c0c0',
  '#fff',
  '#fefe01'
]

export const listVehicleModal = [
  {
    value: 'car',
    icon: <CarIcon />,
    label: 'car'
  },
  {
    value: 'truck',
    icon: <TruckIcon />,
    label: 'truck'
  },
  {
    value: 'bus',
    icon: <BusIcon />,
    label: 'bus'
  },
  {
    value: 'motorbike',
    icon: <MotorbikeIcon />,
    label: 'motorbike'
  },
  {
    value: 'bicycle',
    icon: <BicycleIcon />,
    label: 'bicycle'
  },
  {
    value: 'ba_gac',
    icon: <XeBaGacIcon />,
    label: 'ba gac'
  },
  {
    value: 'pedestrian',
    icon: <PedestrianIcon />,
    label: 'pedestrian'
  },
  {
    value: 'unknown',
    icon: <UnknownVehicleIcon />,
    label: 'unknown'
  }
]

export const listLevelOfService = [
  {
    label: '1-A: Free flow',
    value: 'levelA'
  },
  {
    label: '2-B: Reasonably free flow',
    value: 'levelB'
  },
  {
    label: '3-C: Stable operation',
    value: 'levelC'
  },
  {
    label: '4-D: Borderline unstable',
    value: 'levelD'
  },
  {
    label: '5-E: Extremely unstable',
    value: 'levelE'
  },
  {
    label: '6-F: Breakdown',
    value: 'levelF'
  }
]

export const listOperatorAttribute = [
  {
    value: 'current_speed',
    label: 'Current Speed'
  },
  {
    value: 'section_speed',
    label: 'Section Speed'
  },
  {
    value: 'duration_of_occurrences',
    label: 'Duration of occurrence'
  },
  {
    value: 'stationary_duration',
    label: 'Stationary duration'
  }
]

export const listHeatMapType = [
  {
    value: 'heatmap',
    label: 'Heatmap'
  },
  {
    value: 'gridmap',
    label: 'Gridmap'
  }
]

export const listOperatorAttributeHeatMap = [
  {
    value: 'occupancy',
    label: 'Occupancy map'
  },
  {
    value: 'speed',
    label: 'Speed map'
  }
]

export const listColorMapType = [
  {
    value: 'jet',
    label: 'Jet'
  },
  {
    value: 'ocean',
    label: 'Ocean'
  },
  {
    value: 'summer',
    label: 'Summer'
  },
  {
    value: 'parula',
    label: 'Parula'
  }
]

export const listValueRange = [
  {
    value: 'manual',
    label: 'Manual'
  },
  {
    value: 'adaptive',
    label: 'Adaptive'
  }
]

export const listOperatorAttributeDistribution = [
  {
    value: 'category_distribution',
    label: 'Category distribution'
  },
  {
    value: 'color_distribution',
    label: 'Color distribution'
  }
]

export const listChartType = [
  {
    value: 'bar',
    label: 'Bar'
  },
  {
    value: 'pie',
    label: 'Pie'
  }
]

export const listHistoryMode = [
  {
    value: 'slide',
    label: 'Sliding window'
  },
  {
    value: 'from_to',
    label: 'From -> to'
  }
]

export const listColorDisplayed = [
  {
    value: '#808080',
    icon: <BaseColorBoxSmall color='#808080' />,
    label: ''
  },
  {
    value: '#fda40a',
    icon: <BaseColorBoxSmall color='#fda40a' />,
    label: ''
  },
  {
    value: '#fda40a',
    icon: (
      <BaseColorBoxSmall
        color='transparent'
        customClassName='border-[1px] border-[#858d8e]'
        content={<p className='text-[17px] text-[#6d7374]'>?</p>}
      />
    ),
    label: ''
  },
  {
    value: '#fb0206',
    icon: <BaseColorBoxSmall color='#fb0206' />,
    label: ''
  },
  {
    value: '#010101',
    icon: <BaseColorBoxSmall color='#010101' />,
    label: ''
  },
  {
    value: '#0719f9',
    icon: <BaseColorBoxSmall color='#0719f9' />,
    label: ''
  },
  {
    value: '#c0c0c2',
    icon: <BaseColorBoxSmall color='#c0c0c2' />,
    label: ''
  },
  {
    value: '#fdfd11',
    icon: <BaseColorBoxSmall color='#fdfd11' />,
    label: ''
  },
  {
    value: '#fb0206',
    icon: <BaseColorBoxSmall color='transparent' customClassName='border-[1px] border-[#858d8e]' />,
    label: ''
  }
]

export const listOperatorAttributeTrajectory = [
  {
    value: 'trajectory',
    label: 'Trajectory view'
  }
]

export const listOperatorAttributeValue = [
  {
    value: 'object_count',
    label: 'Object count'
  }
]

export const listLightTraffic = [
  {
    value: 'red',
    label: 'Red',
    icon: <div className={`w-[18px] h-[18px] rounded-full bg-[#e60000]`} />
  },
  {
    value: 'green',
    label: 'Green',
    icon: <div className={`w-[18px] h-[18px] rounded-full bg-[#35e500]`} />
  },
  {
    value: 'yellow',
    label: 'Yellow',
    icon: <div className={`w-[18px] h-[18px] rounded-full bg-[#e5cb00]`} />
  },
  {
    value: 'off',
    label: 'OFF light',
    icon: <div className={`w-[18px] h-[18px] rounded-full bg-[#cecece]`} />
  }
]

export const listSelectionMode = [
  { value: 'matching', label: 'Matching' },
  { value: 'not_matching', label: 'Not matching' }
]
