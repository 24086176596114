import { Box, Checkbox, Typography } from '@mui/material'
import { listLevelOfService } from '../../../utils/common'

const LevelOfServiceContent = () => {
  return (
    <Box mt='24px'>
      <table className='table-auto'>
        <thead>
          <tr>
            <th className='font-bold text-[18px] text-left py-[10px]'>Level of service</th>
            <th className='font-bold text-[18px] text-left p-[10px]'>Duration of occurrence [s]</th>
            <th className='font-bold text-[18px] text-left p-[10px]'>Pass trajectories</th>
          </tr>
        </thead>
        <tbody>
          {listLevelOfService.map(level => (
            <tr key={level.value}>
              <td className='py-[10px]'>{level.label}</td>
              <td className='p-[10px] flex justify-center items-center gap-[10px]'>
                {level.value === 'levelF' ? (
                  <Typography>equal or more than</Typography>
                ) : (
                  <>
                    <Typography>less than</Typography>
                  </>
                )}
                <input
                  disabled={level.value === 'levelF'}
                  className='w-[60px] py-[10px] text-center border-[1px] border-[#c7cccc] rounded-md'
                />
              </td>
              <td className='p-[10px]'>
                <Box display='flex' justifyContent='center'>
                  <Checkbox />
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  )
}

export default LevelOfServiceContent
