import { Group, Line, RegularPolygon, Shape } from 'react-konva'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import useVideoZoneStore from '../../store/useVideoZoneStore'
import { minMax } from '../../utils/zone'
import { v4 } from 'uuid'
import MovementEdit from './Movement/MovementEdit'
import { useDragArea } from '../../hooks/useDragArea'
import Dot from './Dot'

const AreaEdit = ({ zone, handleDragEnd }) => {
  const { setMemoryEditZone, setEditZone, dragNewPointsZone, updatePointsZone } = useVideoZoneStore(
    state => state.editZone
  )
  const { selectedZone, hideZone, setSelectedZone } = useVideoZoneStore(state => state.paramsZone)
  const { setIsEdit } = useVideoZoneStore(state => state.statusZone)

  const { handleAddNewPoints } = useDragArea({ zone })

  const [minMaxX, setMinMaxX] = useState([0, 0]) //min and max in x axis
  const [minMaxY, setMinMaxY] = useState([0, 0]) //min and max in y axis
  const [stage, setStage] = useState()
  const [strokeWidth, setStrokeWidth] = useState(2)

  const idNewZone = useMemo(() => {
    return v4()
  }, [])

  const listDirectionGate = useMemo(() => {
    const mx = (zone.pointsZone[0][0] + zone.pointsZone[1][0]) / 2
    const my = (zone.pointsZone[1][1] + zone.pointsZone[0][1]) / 2

    const dx = zone.pointsZone[1][0] - zone.pointsZone[0][0]
    const dy = zone.pointsZone[1][1] - zone.pointsZone[0][1]

    const magnitude = Math.sqrt(dx * dx + dy * dy)

    const dirX = dx / magnitude
    const dirY = dy / magnitude
    const angle = Math.atan2(
      zone.pointsZone[1][1] - zone.pointsZone[0][1],
      zone.pointsZone[1][0] - zone.pointsZone[0][0]
    )

    const rotationInDegrees = angle * (180 / Math.PI)
    const perpX = -dirY
    const perpY = dirX

    const alongLineDistance = 15
    const bgColor1 = zone.direction === 'positive' ? '#000' : '#fff'
    const bgColor2 = zone.direction === 'positive' ? '#fff' : '#000'

    const triangle1 = {
      x: mx + alongLineDistance * perpX,
      y: my + alongLineDistance * perpY,
      rotation: rotationInDegrees - 60,
      bgColor: bgColor2
    }

    const triangle2 = {
      x: mx - alongLineDistance * perpX,
      y: my - alongLineDistance * perpY,
      rotation: rotationInDegrees - 120,
      bgColor: bgColor1
    }

    return [{ ...triangle1 }, { ...triangle2 }]
  }, [zone])

  const handleGroupMouseOver = e => {
    e.target.getStage().container().style.cursor = 'pointer'
    setStage(e.target.getStage())
  }

  const handleGroupMouseOut = e => {
    e.target.getStage().container().style.cursor = 'default'
  }

  const handleGroupDragStart = () => {
    let arrX = zone.pointsLine.map(p => p[0])
    let arrY = zone.pointsLine.map(p => p[1])
    setMinMaxX(minMax(arrX))
    setMinMaxY(minMax(arrY))

    setMemoryEditZone({
      ...zone,
      id: idNewZone
    })
    setSelectedZone({
      ...zone,
      id: v4()
    })
  }

  const handleGroupDragEnd = e => {
    if (e.target.name() === 'polygon' || e.target.name() === 'gate' || e.target.name() === 'movement') {
      dragNewPointsZone(
        {
          x: e.target.x(),
          y: e.target.y()
        },
        e.target.name()
      )

      handleDragEnd(e, () => {
        setEditZone(zone)
      })

      setMemoryEditZone({})
      e.target.position({ x: 0, y: 0 })
    }
  }

  const handlePointDragMove = (name, index) => e => {
    setMemoryEditZone({})
    setSelectedZone({})

    const stage = e.target.getStage()
    const pos = [e.target._lastPos.x, e.target._lastPos.y]
    if (pos[0] < 0) pos[0] = 0
    if (pos[1] < 0) pos[1] = 0
    if (pos[0] > stage.width()) pos[0] = stage.width()
    if (pos[1] > stage.height()) pos[1] = stage.height()

    updatePointsZone({
      indexPosition: index,
      value: pos,
      name: name
    })
  }

  const handleMouseOver = () => {
    setStrokeWidth(4)
  }

  const handleMouseLeave = () => {
    setStrokeWidth(2)
  }

  const groupDragBound = pos => {
    let { x, y } = pos

    const sh = stage.height()
    if (minMaxY[0] + y < 0) y = -1 * minMaxY[0]
    if (minMaxX[0] + x < 0) x = -1 * minMaxX[0]
    if (minMaxY[1] + y > sh) y = sh - minMaxY[1]
    return { x, y }
  }

  const nameGroup = useMemo(() => {
    if (zone.type === 'zone' || zone.type === 'traffic') {
      return 'polygon'
    } else if (zone.type === 'gate') {
      return 'gate'
    }

    return 'movement'
  }, [zone])

  const listStroke = useMemo(() => {
    let arr = []

    for (let i = 0; i < zone.pointsLine.length - 1; i++) {
      arr.push([zone.pointsLine[i], zone.pointsLine[i + 1]])
    }

    if (zone.type === 'zone' || zone.type === 'traffic') {
      arr.push([zone.pointsLine[zone.pointsLine.length - 1], zone.pointsLine[0]])
    }

    return arr
  }, [zone])

  return (
    <Group
      name={nameGroup}
      onDragEnd={handleGroupDragEnd}
      onDragStart={handleGroupDragStart}
      onMouseOver={handleGroupMouseOver}
      onMouseOut={handleGroupMouseOut}
      onMouseDown={() => setIsEdit(true)}
      dragBoundFunc={groupDragBound}
      opacity={hideZone === zone.id ? 0 : 1}
      perfectDrawEnabled={false}
      draggable
    >
      {zone.type === 'zone' || zone.type === 'traffic' ? (
        <Group onMouseLeave={handleMouseLeave} onMouseOver={handleMouseOver}>
          <Line name='zone_fill' points={zone.pointsZone} fill='#ffffff59' closed />
          {listStroke.map((stroke, index) => (
            <Line
              key={index}
              points={stroke.flat()}
              dash={selectedZone.id === zone.id ? [15, 5] : [0, 0]}
              stroke='#fff'
              strokeWidth={strokeWidth}
              onDblClick={zone.type === 'zone' && handleAddNewPoints(index)}
              fillEnabled={false}
            />
          ))}
        </Group>
      ) : zone.type === 'gate' ? (
        <Group onMouseLeave={handleMouseLeave} onMouseOver={handleMouseOver}>
          <Shape
            sceneFunc={(context, shape) => {
              context.beginPath()
              context.moveTo(zone.pointsZone[0][0], zone.pointsZone[0][1])
              context.lineTo(zone.pointsZone[1][0], zone.pointsZone[1][1])
              context.fillStrokeShape(shape)
            }}
            fill='#00D2FF'
            stroke='#ffffff59'
            strokeWidth={strokeWidth}
            dash={selectedZone.id === zone.id ? [15, 5] : [0, 0]}
          />
          {listDirectionGate.map((direction, index) => (
            <RegularPolygon
              key={index}
              x={direction.x}
              y={direction.y}
              sides={3}
              radius={8}
              fill={direction.bgColor}
              rotation={direction.rotation}
            />
          ))}
        </Group>
      ) : (
        <MovementEdit zone={zone} listStroke={listStroke} />
      )}
      {zone.pointsLine.map((point, index) => {
        return (
          <Dot
            key={`dot_${index}`}
            stage={stage}
            index={index}
            point={point}
            zone={zone}
            nameGroup={nameGroup}
            handlePointDragMove={handlePointDragMove}
          />
        )
      })}
    </Group>
  )
}

AreaEdit.propTypes = {
  zone: PropTypes.object.isRequired,
  handleDragEnd: PropTypes.func.isRequired
}

export default AreaEdit
