import { useContext } from 'react'
import useCommonStore from '../store/useCommonStore'
import useVideoZoneStore from '../store/useVideoZoneStore'
import { HomeContextProvided } from '../screens/Home/Home'

const useConfirmEditZone = () => {
  const { handleDeleteNode } = useContext(HomeContextProvided)

  const { editZone, setEditZone } = useVideoZoneStore(state => state.editZone)
  const { finishEditZone, deletePointsZone } = useVideoZoneStore(state => state.listZone)
  const { setStatus } = useCommonStore()

  const updateZone = () => {
    finishEditZone({
      id: editZone.id,
      value: editZone
    })

    setEditZone({})
    setStatus('default')
  }

  const deleteZone = () => {
    handleDeleteNode(editZone.id)

    deletePointsZone({
      id: editZone.id
    })

    setEditZone({})
    setStatus('default')
  }

  const redoZone = () => {
    setEditZone({})
    setStatus('default')
  }

  return {
    updateZone,
    deleteZone,
    redoZone
  }
}

export default useConfirmEditZone
