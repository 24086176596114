import PropTypes from 'prop-types'

const DashboardIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <path
          d='M9.585-119.984H4.022a1.3 1.3 0 0 0-1.3 1.3v3.338a1.3 1.3 0 0 0 1.3 1.3h5.563a1.3 1.3 0 0 0 1.3-1.3v-3.338a1.3 1.3 0 0 0-1.3-1.3z'
          transform='translate(4.424 127.132)'
          fill={color}
        />
        <path
          d='M9.585-108.92H4.022a1.3 1.3 0 0 0-1.3 1.3v7.789a1.3 1.3 0 0 0 1.3 1.3h5.563a1.3 1.3 0 0 0 1.3-1.3v-7.789a1.3 1.3 0 0 0-1.3-1.3z'
          transform='translate(4.424 123.486)'
          fill={color}
        />
        <path
          d='M23.968-102.282H18.4a1.3 1.3 0 0 0-1.3 1.3v3.338a1.3 1.3 0 0 0 1.3 1.3h5.563a1.3 1.3 0 0 0 1.3-1.3v-3.338a1.3 1.3 0 0 0-1.295-1.3z'
          transform='translate(-.315 121.299)'
          fill={color}
        />
        <path
          d='M23.968-119.984H18.4a1.3 1.3 0 0 0-1.3 1.3v7.789a1.3 1.3 0 0 0 1.3 1.3h5.563a1.3 1.3 0 0 0 1.3-1.3v-7.789a1.3 1.3 0 0 0-1.295-1.3z'
          transform='translate(-.315 127.132)'
          fill={color}
        />
      </g>
    </svg>
  )
}

export default DashboardIcon

DashboardIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
