import PropTypes from 'prop-types'

const PlayPauseIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <path
        d='m11 11.61-.026 8.653A1.318 1.318 0 0 0 12.96 21.4l7.116-4.165a1.7 1.7 0 0 0-.051-2.965l-7.079-3.82A1.318 1.318 0 0 0 11 11.61z'
        transform='translate(.052 .059)'
        fill={color}
      />
    </svg>
  )
}

export default PlayPauseIcon

PlayPauseIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
