import { BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow';
import PropTypes from 'prop-types'
import useFlowZoneStore from '../../../store/useFlowZoneStore';

const onEdgeClick = (evt, id) => {
  evt.stopPropagation();
  alert(`remove ${id}`);
};

const EdgeCustom = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
}) => {
  const { selectedDeleteEdgeId } = useFlowZoneStore()

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        {id === selectedDeleteEdgeId && (
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              pointerEvents: 'all',
            }}
          >
            <button className="edgebutton" onClick={(event) => onEdgeClick(event, id)}>
              ×
            </button>
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  );
}

export default EdgeCustom

EdgeCustom.displayName = "EdgeCustom"

EdgeCustom.propTypes = {
  id: PropTypes.string.isRequired,
  sourceX: PropTypes.string.isRequired,
  sourceY: PropTypes.string.isRequired,
  targetX: PropTypes.string.isRequired,
  targetY: PropTypes.string.isRequired,
  sourcePosition: PropTypes.any.isRequired,
  targetPosition: PropTypes.any.isRequired,
  style: PropTypes.object.isRequired,
  markerEnd: PropTypes.any.isRequired,
}