import { Box } from "@mui/material"
import PropTypes from 'prop-types'

const VolumeLabel = ({ data }) => {
  const { value } = data

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Box sx={{
        p: 1,
        bgcolor: '#fff'
      }}>
        {value.input_type === 'interval' ? value.input_type : value.val.type}
      </Box>
      <Box sx={{
        p: 1,
        bgcolor: '#fff',
        borderTop: '1px solid #808080'
      }}>
        {value.input_type === 'interval' ? `${value.interval.start} - ${value.interval.end}` : value.val.input} kpx/h
      </Box>
    </Box>
  )
}

export default VolumeLabel

VolumeLabel.displayName = 'VolumeLabel'

VolumeLabel.propTypes = {
  data: PropTypes.object,
}
