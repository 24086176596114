import PropTypes from 'prop-types'

const GateBothDirectionIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Popup_GATE_both directions'
      width='28'
      height='28'
      viewBox='0 0 28 28'
    >
      <g data-name='Group 1162'>
        <path
          data-name='Line 85'
          transform='translate(14 4)'
          style={{ stroke: '#6d7374', strokeWidth: '1.5px', fill: 'none' }}
          d='M0 0v20'
        />
        <path data-name='Polygon 4' d='m5 0 5 9H0z' transform='rotate(-90 10.5 8.5)' style={{ fill: '#6d7374' }} />
        <g data-name='Polygon 5' style={{ fill: '#fff' }}>
          <path d='M8.725 8.25h-7.45L5 1.544 8.725 8.25z' style={{ stroke: 'none' }} transform='rotate(90 8.5 17.5)' />
          <path
            d='M5 3.089 2.55 7.5h4.9L5 3.089M5 0l5 9H0l5-9z'
            style={{ fill: '#6d7374', stroke: 'none' }}
            transform='rotate(90 8.5 17.5)'
          />
        </g>
      </g>
      <path data-name='Rectangle 637' style={{ fill: 'none' }} d='M0 0h28v28H0z' />
    </svg>
  )
}

export default GateBothDirectionIcon

GateBothDirectionIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
