import { memo, useEffect, useState } from 'react'
import useFlowZoneStore from '../../../../store/useFlowZoneStore'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { v4 } from 'uuid'

const Widgets = memo(({ listMenu, isShowFull }) => {
  const { setSelectedWidgetElements } = useFlowZoneStore()
  const [classTitle, setClassTitle] = useState('')

  useEffect(() => {
    if (isShowFull) {
      setClassTitle('')
    } else {
      setTimeout(() => setClassTitle('hidden'), 500)
    }
  }, [isShowFull])

  const onDragStart = item => {
    setSelectedWidgetElements({
      ...item,
      id: v4()
    })
  }

  const onDragEnd = () => {
    setSelectedWidgetElements({})
  }

  return (
    <Box className='flex flex-col gap-2' style={{ padding: '0 11px' }}>
      {listMenu.map(item => (
        <Box
          key={item.id}
          className='flex items-center cursor-pointer'
          onDragStart={() => onDragStart(item)}
          onDragEnd={onDragEnd}
          draggable
          style={{ width: 'fitContent' }}
        >
          <Box className='p-[7px] rounded-[6px] bg-[#858d8e] overflow-hidden'>{item.icon}</Box>
          <p className={`text-[#6d7374] text-[15px] ml-[13px] ${classTitle}`}>{item.label}</p>
        </Box>
      ))}
    </Box>
  )
})

Widgets.propTypes = {
  listMenu: PropTypes.array,
  isShowFull: PropTypes.bool
}

Widgets.displayName = 'Widgets'

export default Widgets
