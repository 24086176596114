import PropTypes from 'prop-types'

const DurationOfOccurrenceIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <g transform='translate(13.155 5.854)'>
          <rect width='1.866' height='5.974' rx='.761' transform='rotate(90 2.987 2.987)' fill={color} />
          <rect width='1.866' height='4.162' rx='.666' transform='rotate(135 4.687 4.69)' fill={color} />
        </g>
        <g>
          <path
            d='M8.407 20.161a8.31 8.31 0 0 1-4.363-1.241A8.439 8.439 0 0 1 1.184 7.4 8.2 8.2 0 0 1 5.1 3.959v2.8a5.935 5.935 0 1 0 3.314-1.014V3.3a8.561 8.561 0 0 1 4.374 1.24l.757-.757-.673-.673 1.178-1.177 2.522 2.523-1.177 1.178-.673-.673-.588.589a8.253 8.253 0 0 1 2.607 4.972 8.49 8.49 0 0 1-1.177 5.539 8.243 8.243 0 0 1-3.092 3.039 8.343 8.343 0 0 1-4.065 1.061zm.85-17.638a5.608 5.608 0 0 0-1.682 0v-.842H5.894V0h5.045v1.681H9.257v.841z'
            transform='translate(7.6 5.986)'
            stroke='transparent'
            strokeMiterlimit='10'
            fill={color}
          />
          <path
            d='m82.249 196.72 2.429 3.768a.682.682 0 0 0 1.183-.063l1.919-3.817a.881.881 0 0 0-.858-1.274l-4.154.335a.683.683 0 0 0-.519 1.051z'
            transform='translate(-73.048 -185.615)'
            fill={color}
          />
          <path
            d='M89.812 204.361a1.075 1.075 0 0 0 2.15 0 39.15 39.15 0 0 0-.741-4.1.342.342 0 0 0-.667 0 39.148 39.148 0 0 0-.742 4.1z'
            transform='translate(-74.857 -186.713)'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
}

export default DurationOfOccurrenceIcon

DurationOfOccurrenceIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
