import { memo, useMemo } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { BaseProgrammingElement } from '../BaseProgrammingElement'

const validTypes = ['motion', 'property', 'groupOperator', 'setOperator', 'controlOperator']

const Operators = memo(({ listMenu }) => {
  const listElements = useMemo(() => {
    return listMenu.reduce(
      (acc, ele) => {
        if (validTypes.includes(ele.type)) {
          return {
            ...acc,
            [ele.type]: [...acc[ele.type], ele]
          }
        }
        return acc
      },
      {
        motion: [],
        property: [],
        groupOperator: [],
        setOperator: [],
        controlOperator: []
      }
    )
  }, [listMenu])

  return (
    <Box className='flex flex-col gap-2' style={{ padding: '0 11px' }}>
      {validTypes.map(type => (
        <BaseProgrammingElement key={type} parentType={type} listElement={listElements[type]} />
      ))}
    </Box>
  )
})

Operators.propTypes = {
  listMenu: PropTypes.array,
  isShowFull: PropTypes.bool
}

Operators.displayName = 'Operators'

export default Operators
