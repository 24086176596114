import PropTypes from 'prop-types'
import { Box, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'

const BaseTimeInput = ({ label }) => {
  return (
    <Box sx={{ display: 'flex', gap: '50px' }}>
      <Box display='flex' gap='5px' alignItems='center' alignContent='center' height='fit-content'>
        <Typography>{label}</Typography>
        <TextField placeholder='' className='w-[44px]' size='small' />
        <Select labelId='demo-simple-select-helper-label' size='small' id='demo-simple-select-helper' value='minutes'>
          <MenuItem value='minutes'>Minutes</MenuItem>
          <MenuItem value='hours'>Hours</MenuItem>
          <MenuItem value='days'>Days</MenuItem>
        </Select>
      </Box>
      <Box>
        <RadioGroup aria-labelledby='demo-radio-buttons-group-label' defaultValue='current' name='radio-buttons-group'>
          <FormControlLabel value='current' control={<Radio />} label='Current' />
          <FormControlLabel value='previous' control={<Radio />} label='Previous' />
          <FormControlLabel
            value='custom'
            control={<Radio />}
            label={
              <Box display='flex' alignItems='center' alignContent='center' gap='8px'>
                <Typography>Custom:</Typography>
                <TextField placeholder='' className='w-[44px]' size='small' />
                <Typography>blocks</Typography>
              </Box>
            }
          />
        </RadioGroup>
      </Box>
    </Box>
  )
}

export default BaseTimeInput

BaseTimeInput.propTypes = {
  label: PropTypes.string.isRequired
}
