import { Box, Modal } from '@mui/material'
import PropTypes from 'prop-types'
import useFlowZoneStore from '../../store/useFlowZoneStore'
import { FormProvider, useForm } from 'react-hook-form'
import useModalStore from '../../store/useModalStore'
import { HeaderModalCommon } from '../components/HeaderModalCommon'
import { getNodeAreaIcon } from '../../utils/common'
import { TitleContentModalCommon } from '../components/TitleContentModalCommon'
import { FooterModalCommon } from '../components/FooterModalCommon'
import { HomeContextProvided } from '../../screens/Home/Home'
import { useContext } from 'react'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '720px',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  overflow: 'hidden'
}

export const AreaModal = () => {
  const { nodes, setNodes } = useContext(HomeContextProvided)

  const { elementPopup, isDropElements, setIsDropElements } = useFlowZoneStore()
  const { statusModal, setStatusModal } = useModalStore()

  const methods = useForm({
    defaultValues: elementPopup.value
  })

  const { handleSubmit } = methods

  const handleUpdateArea = data => {
    setNodes(() =>
      nodes.map(node => {
        if (node.data.selectedZone && node.data.selectedZone.id === elementPopup.id) {
          return {
            ...node,
            data: {
              ...node.data,
              selectedZone: {
                ...node.data.selectedZone,
                value: data,
                name: data.name
              }
            }
          }
        } else {
          return { ...node }
        }
      })
    )

    setStatusModal('areaModal')
    if (isDropElements) {
      setIsDropElements(false)
    }
  }

  return (
    <Modal open={statusModal.areaModal} onClose={() => setStatusModal('areaModal')}>
      <FormProvider {...methods}>
        <Box sx={style}>
          <HeaderModalCommon
            icon={getNodeAreaIcon(elementPopup.type)}
            title={elementPopup.name}
            statusKeyModal='areaModal'
            typeModal='area'
          />
          <form onSubmit={handleSubmit(handleUpdateArea)}>
            <Box
              sx={{
                p: 3
              }}
            >
              <TitleContentModalCommon title={elementPopup.titleHeader} isFilterShow={true} />
              {elementPopup.content}
              <FooterModalCommon />
            </Box>
          </form>
        </Box>
      </FormProvider>
    </Modal>
  )
}

AreaModal.propTypes = {
  isOpenAreaModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}
