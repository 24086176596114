import useCommonStore from '../../../store/useCommonStore'
import ConfirmEdit from './ConfirmEdit'
import StatusVideo from './StatusVideo'

const contentFooter = type => {
  if (type === 'default') {
    return <StatusVideo />
  } else {
    return <ConfirmEdit />
  }
}

const Footer = () => {
  const { status } = useCommonStore()

  return <div className='w-full left-0 h-[65px] px-5 bg-[#dbdbdb]'>{contentFooter(status)}</div>
}

export default Footer
