import PropTypes from 'prop-types'

const ZoomIn = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <g transform='translate(0 203.81)'>
          <rect width='2.509' height='11.411' rx='.741' transform='rotate(180 8.627 -91.022)' fill={color} />
        </g>
        <g transform='translate(0 203.81)'>
          <rect width='2.509' height='11.411' rx='.741' transform='rotate(-90 -88.1 -98.395)' fill={color} />
        </g>
      </g>
    </svg>
  )
}

export default ZoomIn

ZoomIn.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
