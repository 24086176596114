import { useState } from 'react'
import simplify from 'simplify-js'
import useVideoZoneStore from '../store/useVideoZoneStore'
import { v4 } from 'uuid'
import { getTextArea } from '../utils/common'

export const useDrawZG = () => {
  const [lineDraw, setLineDraw] = useState([])
  const { listZones, setListZones } = useVideoZoneStore(state => state.listZone)
  const { drawType } = useVideoZoneStore(state => state.paramsZone)

  const handleFinishDraw = ({ isTraffic, mousePos }) => {
    let pointsZone, pointsLine, type, other

    if (!isTraffic) {
      pointsLine = simplify([...lineDraw, mousePos], 5, true).map(point => [point.x, point.y])

      if (drawType === 'movement') {
        type = 'movement'
        pointsZone = simplify([...lineDraw, mousePos], 5, true).flatMap(point => [point.x, point.y])
      } else {
        if (pointsLine.length > 5) {
          type = drawType === 'movement' ? 'movement' : 'zone'
          pointsZone = simplify([...lineDraw, mousePos], 5, true).flatMap(point => [point.x, point.y])
          other = {
            direction: 'right',
            length: 0
          }
        } else {
          type = 'gate'
          pointsZone = [pointsLine[0], pointsLine[pointsLine.length - 1]]
          pointsLine = [pointsLine[0], pointsLine[pointsLine.length - 1]]
          other = {
            direction: 'positive'
          }
        }
      }
    } else {
      type = 'traffic'
      pointsLine = mousePos.map(point => [point.x, point.y])
      pointsZone = pointsLine.flat()
    }

    setListZones({
      ...other,
      id: v4(),
      pointsZone,
      pointsLine,
      type,
      name: getTextArea(type, listZones.length + 1)
    })
  }

  return {
    lineDraw,

    handleFinishDraw,
    setLineDraw
  }
}
