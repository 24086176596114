import PropTypes from 'prop-types'

const TrafficIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g transform='translate(0 80.155)'>
        <path
          d='M2200.152-1722.876h-10.666a1.487 1.487 0 0 1-1.486-1.486v-20.308a1.487 1.487 0 0 1 1.486-1.485h10.666a1.487 1.487 0 0 1 1.486 1.485v20.308a1.487 1.487 0 0 1-1.486 1.486zm-8.76-20.876a1.105 1.105 0 0 0-1.1 1.1v16.329a1.105 1.105 0 0 0 1.1 1.1h6.856a1.105 1.105 0 0 0 1.1-1.1v-16.329a1.105 1.105 0 0 0-1.1-1.1z'
          transform='translate(-2178.819 1668.724)'
          stroke='transparent'
          fill={color}
          strokeMiterlimit='10'
        />
        <rect width='2.624' height='4.061' rx='.545' transform='translate(14.688 -54.94)' fill={color} />
        <circle cx='2.37' cy='2.37' r='2.37' transform='translate(13.63 -73.61)' fill={color} />
        <circle cx='2.37' cy='2.37' r='2.37' transform='translate(13.63 -68.059)' fill={color} />
        <circle cx='2.37' cy='2.37' r='2.37' transform='translate(13.63 -62.508)' fill={color} />
      </g>
    </svg>
  )
}

export default TrafficIcon

TrafficIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
