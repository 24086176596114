import { Box } from "@mui/material"
import BaseRadioGroup from "../../../common/BaseRadioGroup"
import { listInputTypesSpeedModal } from "../../../utils/common"
import BaseSlider from "../../../common/BaseSlider"
import { useFormContext } from "react-hook-form"
import BaseValueSelect from "../../../common/BaseValueSelect"

const DurationOfOccurrenceContent = () => {
  const { watch } = useFormContext()
  const type = watch('type')

  return (
    <Box>
      <BaseRadioGroup width={350} childrenWidth={120} listGroup={listInputTypesSpeedModal} name="type" label="Type" />
      {type === 'interval' ? (
        <BaseSlider name='interval' label='Interval' />
      ) : (
        <BaseValueSelect name='val' label='Value' />
      )}
    </Box>
  )
}

export default DurationOfOccurrenceContent