import { Box } from '@mui/material'
import BaseTimeInput from '../../common/BaseTimeInput'

const TimeBlockContent = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        gap: '50px',
        mt: '10px'
      }}
    >
      <BaseTimeInput label='Time block:' />
      <Box></Box>
    </Box>
  )
}

export default TimeBlockContent
