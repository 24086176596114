import { useMemo, useState } from 'react'
import useVideoZoneStore from '../store/useVideoZoneStore'
import { v4 } from 'uuid'

export const useDragArea = ({ zone }) => {
  const { drawType, setDrawType, setSelectedZone } = useVideoZoneStore(state => state.paramsZone)
  const { setListZones, deletePointsZone, moveToTopListZone } = useVideoZoneStore(state => state.listZone)
  const { setIsEdit } = useVideoZoneStore(state => state.statusZone)
  const { addNewPoint, setMemoryEditZone } = useVideoZoneStore(state => state.editZone)

  const [isMove, setIsMove] = useState(false)

  const idNewZone = useMemo(() => {
    return v4()
  }, [])

  const handleDragStart = () => {
    if (drawType !== '') return

    setIsEdit(true)
    setListZones({
      ...zone,
      id: idNewZone
    })

    setSelectedZone(zone)
  }

  const handleMouseUp = () => {
    if (drawType !== '') return

    moveToTopListZone({ id: zone.id })
    setDrawType('')

    if (isMove) {
      deletePointsZone({ id: zone.id })

      setIsMove(false)
      setSelectedZone({})
    } else {
      // setStatus('edit')
      // setEditZone(zone)
    }
  }

  const handleDragMove = () => {
    if (!isMove) {
      setIsMove(true)
    }
  }

  const handleAddNewPoints = index => e => {
    setMemoryEditZone({})
    setSelectedZone({})

    const { x, y } = e.target.getStage().getPointerPosition()

    addNewPoint({
      indexPosition: index + 1,
      value: [x, y],
      name: 'polygon'
    })
  }

  return {
    isMove,

    handleAddNewPoints,
    handleDragStart,
    handleMouseUp,
    handleDragMove
  }
}
