import PropTypes from 'prop-types'

const MotionFiltersIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <path
        d='M-2000.36-1586.507h-10.788a3.856 3.856 0 0 1-3.852-3.852v-10.788a3.856 3.856 0 0 1 3.852-3.852h10.788a3.856 3.856 0 0 1 3.852 3.852v10.788a3.856 3.856 0 0 1-3.852 3.852zm-7.8-13.662a4.435 4.435 0 0 0-4.443 4.416 4.435 4.435 0 0 0 4.443 4.416 4.434 4.434 0 0 0 4.442-4.416 4.434 4.434 0 0 0-4.444-4.416zm5.237.02a5.2 5.2 0 0 1 2.418 4.394 5.2 5.2 0 0 1-2.418 4.4 4.407 4.407 0 0 0 2.856-1.41 4.4 4.4 0 0 0 1.167-2.987 4.4 4.4 0 0 0-1.167-2.986 4.409 4.409 0 0 0-2.858-1.412zm-2.408 0a5.2 5.2 0 0 1 2.417 4.394 5.2 5.2 0 0 1-2.417 4.4 4.4 4.4 0 0 0 2.856-1.41 4.4 4.4 0 0 0 1.167-2.987 4.4 4.4 0 0 0-1.167-2.986 4.406 4.406 0 0 0-2.858-1.412z'
        transform='translate(2021.754 1611.754)'
        fill={color}
        stroke='transparent'
        strokeMiterlimit='10'
      />
    </svg>
  )
}

export default MotionFiltersIcon

MotionFiltersIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
