import PropTypes from 'prop-types'

const EnableScoreIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <g transform='translate(-263.167 36.637)'>
          <g>
            <path
              d='M-349.326-2678.393h-19.808a1.7 1.7 0 0 1-1.7-1.7v-14.483a1.7 1.7 0 0 1 1.7-1.7h19.808a1.7 1.7 0 0 1 1.7 1.7v14.483a1.7 1.7 0 0 1-1.7 1.7zm-17.913-14.882a1.264 1.264 0 0 0-1.263 1.263v9.36a1.264 1.264 0 0 0 1.263 1.263h15.954a1.264 1.264 0 0 0 1.263-1.263v-9.36a1.264 1.264 0 0 0-1.263-1.263z'
              transform='translate(638.397 2665.506)'
              stroke='transparent'
              strokeMiterlimit='10'
              fill={color}
            />
          </g>
          <circle cx='3.74' cy='3.74' r='3.74' transform='translate(270.595 -17.986)' fill={color} />
          <circle cx='3.74' cy='3.74' r='3.74' transform='translate(280.334 -17.986)' fill={color} />
          <g>
            <path
              d='M275.784-24.121a2.449 2.449 0 0 1 .413-1.3 3.175 3.175 0 0 1 1.2-1.093 3.8 3.8 0 0 1 1.848-.433 3.949 3.949 0 0 1 1.732.362 2.806 2.806 0 0 1 1.16.985 2.413 2.413 0 0 1 .409 1.353 2.1 2.1 0 0 1-.232 1.009 3.1 3.1 0 0 1-.556.747q-.321.315-1.153 1.059a4.543 4.543 0 0 0-.369.369 1.51 1.51 0 0 0-.206.291 1.321 1.321 0 0 0-.105.264 10.36 10.36 0 0 0-.112.463.753.753 0 0 1-.8.7.824.824 0 0 1-.592-.23.9.9 0 0 1-.241-.683 2.508 2.508 0 0 1 .177-.985 2.482 2.482 0 0 1 .467-.731 10.564 10.564 0 0 1 .784-.747q.434-.38.626-.572a1.939 1.939 0 0 0 .325-.43 1.038 1.038 0 0 0 .132-.514 1.2 1.2 0 0 0-.4-.914 1.469 1.469 0 0 0-1.038-.372 1.434 1.434 0 0 0-1.1.376 2.979 2.979 0 0 0-.6 1.106q-.23.765-.873.765a.855.855 0 0 1-.639-.268.816.816 0 0 1-.257-.577zm3.3 7.416a1.064 1.064 0 0 1-.721-.267.936.936 0 0 1-.308-.748.962.962 0 0 1 .3-.717 1 1 0 0 1 .731-.291.971.971 0 0 1 .716.291.971.971 0 0 1 .292.717.943.943 0 0 1-.3.744 1.026 1.026 0 0 1-.707.276z'
              fill={color}
            />
          </g>
        </g>
        <path fill='none' d='M0 0h32v32H0z' />
      </g>
    </svg>
  )
}

export default EnableScoreIcon

EnableScoreIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
