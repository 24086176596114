import PropTypes from 'prop-types'

const UnionIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g transform='translate(-235.691)'>
        <rect width='12.966' height='12.966' rx='1' transform='translate(242.244 6.542)' fill={color} />
        <rect width='12.966' height='12.966' rx='1' transform='translate(248.172 12.489)' fill={color} />
      </g>
      <path data-name='Rectangle 345' fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default UnionIcon

UnionIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
