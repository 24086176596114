import { produce } from 'immer'

export const createEditZone = set => ({
  editZone: {},
  memoryEditZone: {},

  setMemoryEditZone: value =>
    set(
      produce(state => {
        state.editZone.memoryEditZone = value
      })
    ),
  setEditZone: value =>
    set(
      produce(state => {
        state.editZone.editZone = value
      })
    ),
  dragNewPointsZone: (numberPos, name) =>
    set(
      produce(state => {
        const newPointLine = state.editZone.editZone.pointsLine.map(points => {
          return [points[0] + numberPos.x, points[1] + numberPos.y]
        })

        const pointFlat = name === 'gate' ? newPointLine : newPointLine.flat()

        state.editZone.editZone = {
          ...state.editZone.editZone,
          pointsLine: newPointLine,
          pointsZone: pointFlat
        }
      })
    ),
  updatePointsZone: ({ indexPosition, value, name }) =>
    set(
      produce(state => {
        const newPointLine = [
          ...state.editZone.editZone.pointsLine.slice(0, indexPosition),
          value,
          ...state.editZone.editZone.pointsLine.slice(indexPosition + 1)
        ]

        let flatPoints = newPointLine

        if (name === 'polygon' || name === 'movement') {
          flatPoints = newPointLine.flat()
        }

        state.editZone.editZone = {
          ...state.editZone.editZone,
          pointsLine: newPointLine,
          pointsZone: flatPoints
        }
      })
    ),
  editNameZone: ({ name }) =>
    set(
      produce(state => {
        state.editZone.editZone = {
          ...state.editZone.editZone,
          name
        }
      })
    ),
  addNewPoint: ({ indexPosition, value, name }) =>
    set(
      produce(state => {
        const newPointLine = [
          ...state.editZone.editZone.pointsLine.slice(0, indexPosition),
          value,
          ...state.editZone.editZone.pointsLine.slice(indexPosition)
        ]

        let flatPoints = newPointLine

        if (name === 'polygon' || name === 'movement') {
          flatPoints = newPointLine.flat()
        }

        state.editZone.editZone = {
          ...state.editZone.editZone,
          pointsLine: newPointLine,
          pointsZone: flatPoints
        }
      })
    ),
  deletePointDot: ({ indexPosition }) =>
    set(
      produce(state => {
        let arr = [...state.editZone.editZone.pointsLine]

        arr.splice(indexPosition, 1)

        state.editZone.editZone = {
          ...state.editZone.editZone,
          pointsLine: arr,
          pointsZone: arr.flat()
        }
      })
    ),
  editDirectionGate: () =>
    set(
      produce(state => {
        state.editZone.editZone = {
          ...state.editZone.editZone,
          direction: state.editZone.editZone.direction === 'positive' ? 'negative' : 'positive'
        }
      })
    ),
  editMovementZone: ({ key, value }) =>
    set(
      produce(state => {
        if (key === 'direction') {
          state.editZone.editZone = {
            ...state.editZone.editZone,
            direction: state.editZone.editZone.direction === 'right' ? 'reserve' : 'right'
          }
        } else {
          state.editZone.editZone = {
            ...state.editZone.editZone,
            [key]: value
          }
        }
      })
    )
})
