import PropTypes from 'prop-types'

const DistributionIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <path
          d='M-162.351 917.7h-.69v-13.671a.647.647 0 0 0-.647-.647h-2.588a.647.647 0 0 0-.647.647V917.7h-1.295v-9.792a.646.646 0 0 0-.647-.647h-2.588a.646.646 0 0 0-.647.647v9.792h-1.294v-4.616a.647.647 0 0 0-.647-.647h-2.589a.647.647 0 0 0-.647.647v4.616h-1.294v-7.2a.646.646 0 0 0-.647-.647h-2.588a.646.646 0 0 0-.647.647v7.2h-.69a.648.648 0 0 0-.648.647.647.647 0 0 0 .648.647h20.792a.646.646 0 0 0 .647-.647.647.647 0 0 0-.647-.647z'
          fill={color}
          transform='translate(188.748 -895.189)'
        />
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default DistributionIcon

DistributionIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
