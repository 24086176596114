import { useFormContext } from 'react-hook-form'
import BaseRadioGroup from '../../../common/BaseRadioGroup'
import BaseSlider from '../../../common/BaseSlider'
import { listInputTypesSpeedModal } from '../../../utils/common'
import BaseValueSelect from '../../../common/BaseValueSelect'

export const VolumeContent = () => {
  const { watch } = useFormContext()
  const input_type = watch('input_type')

  return (
    <>
      <BaseRadioGroup
        listGroup={listInputTypesSpeedModal}
        childrenWidth={120}
        label='Input Type'
        name='input_type'
        width={350}
      />
      {
        input_type === 'interval' ? (
          <BaseSlider name='interval' label='Interval' />
        ) : (
          <BaseValueSelect name="val" label='Value' />
        )
      }
    </>
  )
}
