import { createContext, useCallback, useRef, useState } from 'react'
import { ReactFlowProvider, useNodesState } from 'reactflow'
import { Box } from '@mui/material'
import VideoZone from '../../components/VideoZone'
import useVideoZoneStore from '../../store/useVideoZoneStore'
import Layout from '../../Layout/Layout'
import useCommonStore from '../../store/useCommonStore'
import useFlowZoneStore from '../../store/useFlowZoneStore'
import { FlowZone } from '../../components/FlowZone/FlowZoneContent'
import useStatusAutomatic from '../../store/useStatusAutomatic'
import AutomaticModal from '../../modals/AutomaticModal'
// import { getDrawTypeIcon } from '../../utils/common'

export const HomeContextProvided = createContext({})

const HomeScreen = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const { moveZone, setMoveZoneAuto } = useStatusAutomatic()
  const { setSelectedZone, selectedZone, setHideZone } = useVideoZoneStore(state => state.paramsZone)
  const { isEdit } = useVideoZoneStore(state => state.statusZone)
  const { setStatus } = useCommonStore()
  const { deletePointsZone } = useVideoZoneStore(state => state.listZone)
  const { selectedOperatorElements, setSelectedOperatorElements } = useFlowZoneStore()

  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [isOpenDragModal, setIsOpenDragModal] = useState(false)
  const [newNodeLabel, setNewNodeLabel] = useState()
  // const [positionMouse, setPositionMouse] = useState({
  //   x: 0,
  //   y: 0
  // })

  const divRef = useRef(null)

  // const handleMouseMove = e => {
  //   setPositionMouse({
  //     x: e.clientX,
  //     y: e.clientY
  //   })
  // }

  // useEffect(() => {
  //   if (drawType !== '') {
  //     window.addEventListener('mousemove', handleMouseMove)

  //     return () => window.removeEventListener('mousemove', handleMouseMove)
  //   }
  // }, [drawType])

  const handleDragEnd = useCallback(
    (event, callBack) => {
      let newNode

      const divRect = divRef.current.getBoundingClientRect()

      const mouseX = event.evt ? event.evt.clientX : event.clientX
      const mouseY = event.evt ? event.evt.clientY : event.clientY

      if (mouseX >= divRect.left) {
        const position = reactFlowInstance.project({
          x: mouseX - divRect.left,
          y: mouseY - divRect.top
        })

        if (selectedZone.id) {
          newNode = {
            id: selectedZone.id,
            type: 'nodeArea',
            position,
            data: {
              selectedZone,
              index: nodes.length + 1,
              widgets: []
            }
          }
        }

        if (selectedOperatorElements.id) {
          newNode = {
            id: selectedOperatorElements.id,
            type: 'nodeElement',
            position,
            data: { selectedOperatorElements, index: nodes.length + 1, widgets: [] }
          }
        }

        setNewNodeLabel(newNode)

        if (moveZone) {
          setNodes(nds => nds.concat(newNode))
        } else {
          setIsOpenDragModal(true)
        }

        if (!isEdit) {
          setStatus('default')
        }

        if (callBack) {
          callBack()
        }

        handleResetDataFlow()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isEdit,
      deletePointsZone,
      selectedOperatorElements,
      reactFlowInstance,
      selectedZone,
      setHideZone,
      setNodes,
      setSelectedZone
    ]
  )

  const handleResetDataFlow = () => {
    if (selectedZone.id) {
      deletePointsZone({ id: selectedZone.id })
      setSelectedZone({})
      setHideZone('')
    } else if (selectedOperatorElements.id) {
      setSelectedOperatorElements({})
    }
  }

  const handleDeleteNode = id => {
    setNodes(nds => nds.filter(node => node.idNode !== id))
  }

  const value = {
    nodes,

    handleDeleteNode,
    handleDragEnd,

    setNodes
  }

  // const iconMouse = useMemo(() => {
  //   if (drawType === '') {
  //     return <div></div>
  //   }

  //   return (
  //     <div
  //       style={{
  //         position: 'absolute',
  //         top: positionMouse.y + 8,
  //         left: positionMouse.x - 82
  //       }}
  //     >
  //       {getDrawTypeIcon(drawType)}
  //     </div>
  //   )
  // }, [drawType, positionMouse])

  return (
    <Layout>
      <HomeContextProvided.Provider value={value}>
        <ReactFlowProvider>
          <div className='flex relative w-full h-screen'>
            <div className='relative h-full flex-1 bg-slate-500'>
              <VideoZone handleDragEnd={handleDragEnd} />
            </div>
            <Box className='w-[8px] h-full bg-white ml-auto' />
            <FlowZone
              ref={divRef}
              nodes={nodes}
              onNodesChange={onNodesChange}
              setReactFlowInstance={setReactFlowInstance}
            />
            <AutomaticModal
              isOpen={isOpenDragModal}
              handleClose={() => {
                setIsOpenDragModal(false)
              }}
              title='Data loss warning'
              handleAccept={() => {
                console.log(newNodeLabel)
                setNodes(nds => nds.concat(newNodeLabel))
                setIsOpenDragModal(false)
              }}
              handleUndo={() => {
                setIsOpenDragModal(false)
              }}
              handleSaveAccept={() => {
                setMoveZoneAuto()
              }}
            />
            {/* {iconMouse} */}
          </div>
        </ReactFlowProvider>
      </HomeContextProvided.Provider>
    </Layout>
  )
}

export default HomeScreen
