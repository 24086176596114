import PropTypes from 'prop-types'

const LeftArrowIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.1'
      id='Capa_1'
      x='0px'
      y='0px'
      viewBox='0 0 490.787 490.787'
      style={{ enableBackground: 'new 0 0 490.787 490.787' }}
      xmlSpace='preserve'
    >
      <path
        style={{ fill: '#F44336' }}
        d='M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006  c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083  c-0.086,0.089-0.173,0.176-0.262,0.262L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085  C368.236,489.664,365.511,490.792,362.671,490.787z'
      />
      <path d='M362.671,490.787c-2.831,0.005-5.548-1.115-7.552-3.115L120.452,253.006c-4.164-4.165-4.164-10.917,0-15.083L355.119,3.256  c4.093-4.237,10.845-4.354,15.083-0.262c4.237,4.093,4.354,10.845,0.262,15.083c-0.086,0.089-0.173,0.176-0.262,0.262  L143.087,245.454l227.136,227.115c4.171,4.16,4.179,10.914,0.019,15.085C368.236,489.664,365.511,490.792,362.671,490.787z' />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default LeftArrowIcon

LeftArrowIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
