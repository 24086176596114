import { Box } from "@mui/material"
import PropTypes from 'prop-types'

const ColorLabel = ({ data }) => {
  const { value } = data

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      gap: '4px',
      alignItems: 'center',
      bgcolor: '#fff',
      p: value.colors.length === 0 ? 0 : 1
    }}>
      {
        value.colors.map((color) => {
          if (color.value === '?') {
            return (
              <div key={color.id} className="rounded-full text-[7px]">
                ?
              </div>
            )
          }
          return (
            <div key={color.id} className="w-[6px] h-[6px] rounded-full" style={{
              backgroundColor: color.value
            }} />
          )
        })
      }
    </Box>
  )
}

export default ColorLabel

ColorLabel.displayName = 'ColorLabel'

ColorLabel.propTypes = {
  data: PropTypes.object,
}
