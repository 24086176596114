import { Group, Line } from 'react-konva'
import PropTypes from 'prop-types'
import useVideoZoneStore from '../../../store/useVideoZoneStore'
import { useDragArea } from '../../../hooks/useDragArea'
import TagNameArea from '../TagNameArea'
import { getPolygonPointCenter } from '../../../utils/zone'

const Traffic = ({ zone, handleDragEnd }) => {
  const { drawType, hideZone } = useVideoZoneStore(state => state.paramsZone)
  const { editZone } = useVideoZoneStore(state => state.editZone)

  const {
    isMove,

    handleDragMove,
    handleMouseUp,
    handleDragStart
  } = useDragArea({ zone: zone })

  return (
    <Group
      onDragMove={handleDragMove}
      onMouseUp={handleMouseUp}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      draggable={drawType === ''}
      opacity={hideZone === zone.id || (editZone && editZone.id === zone.id) ? 0 : 1}
    >
      <Line
        points={zone.pointsZone}
        strokeWidth={3}
        dash={isMove ? [20, 3] : [0, 0]}
        closed
        stroke='#fff'
        fill='#ffffff59'
      />
      <TagNameArea zone={zone} centerPoint={getPolygonPointCenter(zone.pointsLine)} />
    </Group>
  )
}

export default Traffic

Traffic.propTypes = {
  zone: PropTypes.object.isRequired,
  handleDragEnd: PropTypes.func.isRequired
}
