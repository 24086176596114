import PropTypes from 'prop-types'

const ArrowDownIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12.535' height='7.682' viewBox='0 0 12.535 7.682'>
      <path
        data-name='Path 4'
        d='m1489.89 83.783 4.853 4.853 4.853-4.853'
        transform='translate(-1488.476 -82.369)'
        style={{ fill: 'none', stroke: color, strokeLinecap: 'round', strokeWidth: '2px' }}
      />
    </svg>
  )
}

export default ArrowDownIcon

ArrowDownIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
