import PropTypes from 'prop-types'

export const BaseInputSlider = ({ onChange, ...props }) => {
  return (
    <input
      className='w-[66px] py-[10px] text-center border-[1px] border-[#c7cccc] rounded-md'
      onChange={onChange}
      {...props}
    />
  )
}

BaseInputSlider.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]).isRequired,
  onChange: PropTypes.any
}
