import CategoryIcon from '../assets/icons/CategoryIcon'
import ColorIcon from '../assets/icons/ColorIcon'
import ComplementIcon from '../assets/icons/ComplementIcon'
import ControlOperatorsIcon from '../assets/icons/ControlOperatorsIcon'
import DistributionIcon from '../assets/icons/DistributionIcon'
import DurationOfOccurrenceIcon from '../assets/icons/DurationOfOccurrenceIcon'
import GroupOperatorsIcon from '../assets/icons/GroupOperatorsIcon'
import HeatMapIcon from '../assets/icons/HeatMapIcon'
import IntersectionIcon from '../assets/icons/IntersectionIcon'
import LevelOfServiceIcon from '../assets/icons/LevelOfServiceIcon'
import LicensePlateIcon from '../assets/icons/LicensePlateIcon'
import MotionFiltersIcon from '../assets/icons/MotionFiltersIcon'
import ODMatrixIcon from '../assets/icons/ODMatrixIcon'
import PropertyFiltersIcon from '../assets/icons/PropertyFiltersIcon'
import SetOperatorsIcon from '../assets/icons/SetOperatorsIcon'
import SpeedIcon from '../assets/icons/SpeedIcon'
import StationnaryDurationIcon from '../assets/icons/StationnaryDurationIcon'
import StatisticalValueIcon from '../assets/icons/StatisticalValueIcon'
import TableIcon from '../assets/icons/TableIcon'
import TimeOfOccurrenceIcon from '../assets/icons/TimeOfOccurrenceIcon'
import TrajectoryViewIcon from '../assets/icons/TrajectoryViewIcon'
import UnionIcon from '../assets/icons/UnionIcon'
import V3ValueIcon from '../assets/icons/V3ValueIcon'
import VolumeIcon from '../assets/icons/VolumeIcon'
import GateLabel from '../components/FlowZone/AreaLabel/GateLabel'
import TrafficLabel from '../components/FlowZone/AreaLabel/TrafficLabel'
import ZoneLabel from '../components/FlowZone/AreaLabel/ZoneLabel'
import CategoryLabel from '../components/FlowZone/InfomationLabel/CategoryLabel'
import ColorLabel from '../components/FlowZone/InfomationLabel/ColorLabel'
import DurationOfOccurrenceLabel from '../components/FlowZone/InfomationLabel/DurationOfOccurrenceLabel'
import LicensePlateLabel from '../components/FlowZone/InfomationLabel/LicensePlateLabel'
import SpeedLabel from '../components/FlowZone/InfomationLabel/SpeedLabel'
import StationeryDurationLabel from '../components/FlowZone/InfomationLabel/StationeryDurationLabel'
import TimeOfOccurrenceLabel from '../components/FlowZone/InfomationLabel/TimeOfOccurrenceLabel'
import VolumeLabel from '../components/FlowZone/InfomationLabel/VolumeLabel'
import { PROGRAMMING_ELEMENT, TYPE_AREA } from '../config/const'
import GateContent from '../modals/AreaModal/GateContent'
import TrafficContent from '../modals/AreaModal/TrafficContent'
import ZoneContent from '../modals/AreaModal/ZoneContent'
import CategoryContent from '../modals/ProgrammingElementModal/ElementsContent/CategoryContent'
import ColorContent from '../modals/ProgrammingElementModal/ElementsContent/ColorContent'
import DurationOfOccurrenceContent from '../modals/ProgrammingElementModal/ElementsContent/DurationOfOccurrenceContent'
import LevelOfServiceContent from '../modals/ProgrammingElementModal/ElementsContent/LevelOfServiceContent'

import { LicensePlateContent } from '../modals/ProgrammingElementModal/ElementsContent/LicensePlateContent'
import { SpeedContent } from '../modals/ProgrammingElementModal/ElementsContent/SpeedContent'
import StationeryDurationContent from '../modals/ProgrammingElementModal/ElementsContent/StationeryDurationContent'
import TimeOfOccurrenceContent from '../modals/ProgrammingElementModal/ElementsContent/TimeOfOccurrenceContent'
import { VolumeContent } from '../modals/ProgrammingElementModal/ElementsContent/VolumeContent'
import DistributionContent from '../modals/ProgrammingElementModal/WidgetsContent/DistributionContent'
import HeatMapContent from '../modals/ProgrammingElementModal/WidgetsContent/HeatMapContent'
import ODMatrixContent from '../modals/ProgrammingElementModal/WidgetsContent/ODMatrixContent'
import StaticalValueContent from '../modals/ProgrammingElementModal/WidgetsContent/StaticalValueContent'
import TableContent from '../modals/ProgrammingElementModal/WidgetsContent/TableContent'
import TrajectoryViewContent from '../modals/ProgrammingElementModal/WidgetsContent/TrajectoryViewContent'
import ValueContent from '../modals/ProgrammingElementModal/WidgetsContent/ValueContent'

export const mainTypes = {
  motion: {
    icon: <MotionFiltersIcon />,
    title: 'Motion Filters',
    color: '#e7585c',
    bgcolor: '#f4d4d5'
  },
  property: {
    icon: <PropertyFiltersIcon />,
    title: 'Property Filters',
    color: '#f79a52',
    bgcolor: '#f8d9af'
  },
  groupOperator: {
    icon: <GroupOperatorsIcon />,
    title: 'Group operators',
    color: '#018a5e',
    bgcolor: '#dce8bb'
  },
  setOperator: {
    icon: <SetOperatorsIcon />,
    title: 'Set operators',
    color: '#0497cf',
    bgcolor: '#c1e3eb'
  },
  controlOperator: {
    icon: <ControlOperatorsIcon />,
    title: 'Control operators',
    color: '#7540ea',
    bgcolor: '#cdc5e3'
  }
}

export const listMenuFilter = [
  {
    key: PROGRAMMING_ELEMENT.SPEED,
    label: 'Speed',
    tab: 'operator',
    type: 'motion',
    icon: <SpeedIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.STATIONERY,
    label: 'Stationery duration',
    tab: 'operator',
    type: 'motion',
    icon: <StationnaryDurationIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.TIME,
    label: 'Time of occurrence',
    tab: 'operator',
    type: 'motion',
    icon: <TimeOfOccurrenceIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.DURATION,
    label: 'Duration of occurrence',
    tab: 'operator',
    type: 'motion',
    icon: <DurationOfOccurrenceIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.LICENSE_PLATE,
    label: 'License plate',
    tab: 'operator',
    type: 'property',
    icon: <LicensePlateIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.COLOR,
    label: 'Color',
    tab: 'operator',
    type: 'property',
    icon: <ColorIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.CATEGORY,
    label: 'Category',
    tab: 'operator',
    type: 'property',
    icon: <CategoryIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.LEVEL,
    label: 'Level of service',
    tab: 'operator',
    type: 'groupOperator',
    icon: <LevelOfServiceIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.UNION,
    label: 'Union',
    tab: 'operator',
    type: 'setOperator',
    icon: <UnionIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.INTERSECTION,
    label: 'Intersection',
    tab: 'operator',
    type: 'setOperator',
    icon: <IntersectionIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.COMPLEMENT,
    label: 'Complement',
    type: 'setOperator',
    tab: 'operator',
    icon: <ComplementIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.VOLUME,
    label: 'Volume',
    type: 'controlOperator',
    tab: 'operator',
    icon: <VolumeIcon color={'#fff'} />
  },
  {
    key: PROGRAMMING_ELEMENT.DISTRIBUTION,
    label: 'Distribution',
    tab: 'widget',
    icon: <DistributionIcon />
  },
  {
    key: PROGRAMMING_ELEMENT.VALUE,
    tab: 'widget',
    label: 'Value',
    icon: <V3ValueIcon />
  },
  {
    key: PROGRAMMING_ELEMENT.STATISTICAL,
    tab: 'widget',
    label: 'Statistical value',
    icon: <StatisticalValueIcon />
  },
  {
    key: PROGRAMMING_ELEMENT.HEATMAP,
    tab: 'widget',
    label: 'Heatmap',
    icon: <HeatMapIcon />
  },
  {
    key: PROGRAMMING_ELEMENT.TRAJECTORY,
    tab: 'widget',
    label: 'Trajectory view',
    icon: <TrajectoryViewIcon />
  },
  {
    key: PROGRAMMING_ELEMENT.TABLE,
    tab: 'widget',
    label: 'Table',
    icon: <TableIcon />
  },
  {
    key: PROGRAMMING_ELEMENT.ODMATRIX,
    tab: 'widget',
    label: 'ODMatrix',
    icon: <ODMatrixIcon color={'#fff'} />
  }
]

export const getProgrammingElementData = key => {
  switch (key) {
    case PROGRAMMING_ELEMENT.SPEED:
      return {
        titleHeader: 'Choose the speed interval for selected trajectories in km/h.',
        type: 'operators',
        content: <SpeedContent />,
        value: {
          name: 'Speed',
          type: 'now',
          input_type: 'interval',
          val: {
            input: '20',
            type: 'less'
          },
          interval: {
            start: 400,
            end: 1000
          }
        }
      }
    case PROGRAMMING_ELEMENT.LICENSE_PLATE:
      return {
        titleHeader: 'Enter the license plates you want to select. You can use a regular expression in the definition.',
        type: 'operators',
        content: <LicensePlateContent />,
        value: {
          name: 'License plate',
          selection_mode: 'matching',
          plates: []
        }
      }
    case PROGRAMMING_ELEMENT.STATIONERY:
      return {
        titleHeader: 'Choose an interval for the duration for which traffic objects were stationery [s].',
        type: 'operators',
        content: <StationeryDurationContent />,
        value: {
          name: 'Stationery duration',
          input_type: 'interval',
          interval: {
            start: 300,
            end: 1000
          },
          val: {
            input: '20',
            type: 'less'
          },
          time_mode: ['now']
        }
      }
    case PROGRAMMING_ELEMENT.TIME:
      return {
        titleHeader: 'Choose a time of occurrence for trajectories selected by this filter.',
        type: 'operators',
        content: <TimeOfOccurrenceContent />,
        value: {
          name: 'Time of occurrence',
          time_mode: 'now'
        }
      }
    case PROGRAMMING_ELEMENT.DURATION:
      return {
        titleHeader: 'Choose an interval for trajectories’ duration of occurrence in the cube [s].',
        type: 'operators',
        content: <DurationOfOccurrenceContent />,
        value: {
          name: 'Duration of occurrence',
          type: 'interval',
          interval: {
            start: 300,
            end: 1000
          },
          val: {
            input: '20',
            type: 'less'
          },
        }
      }
    case PROGRAMMING_ELEMENT.COLOR:
      return {
        titleHeader: 'Choose which colours to include in the filter’s output.',
        type: 'operators',
        content: <ColorContent />,
        value: {
          name: 'Color',
          colors: []
        }
      }
    case PROGRAMMING_ELEMENT.CATEGORY:
      return {
        titleHeader: 'Choose which vehicle categories to include in the filter’s output.',
        type: 'operators',
        content: <CategoryContent />,
        value: {
          name: 'Category',
          selection_mode: 'matching',
          categories: []
        }
      }
    case PROGRAMMING_ELEMENT.UNION:
      return {
        titleHeader: 'Outputs a union of all input trajectories, creates a union of zone filter masks.',
        type: 'operators',
        value: {
          name: 'Union'
        }
      }
    case PROGRAMMING_ELEMENT.INTERSECTION:
      return {
        titleHeader: 'Outputs an intersection of all input sets of trajectories, creates a union of zone filter masks.',
        type: 'operators',
        value: {
          name: 'Intersection'
        }
      }
    case PROGRAMMING_ELEMENT.COMPLEMENT:
      return {
        titleHeader:
          'Outputs a complement of input trajectories relative to the set of all existing trajectories, creates a union of zone filter masks.',
        type: 'operators',
        value: {
          name: 'Complement'
        }
      }
    case PROGRAMMING_ELEMENT.LEVEL:
      return {
        titleHeader:
          'Passes trajectories whose level of service is one of the selected levels. Each trajectory’s LoS is decided by comparing its duration of occurrence with the specified LoS thresholds. Thresholds must have ascending values.',
        type: 'operators',
        content: <LevelOfServiceContent />,
        value: {
          name: 'Level of service'
        }
      }
    case PROGRAMMING_ELEMENT.VOLUME:
      return {
        titleHeader:
          'Passes trajectories whose level of service is one of the selected levels. Each trajectory’s LoS is decided by comparing its duration of occurrence with the specified LoS thresholds. Thresholds must have ascending values.',
        type: 'operators',
        content: <VolumeContent />,
        value: {
          name: 'Stationery duration',
          input_type: 'interval',
          interval: {
            start: 300,
            end: 1000
          },
          val: {
            input: '20',
            type: 'less'
          },
          time_mode: ['now']
        }
      }
    case PROGRAMMING_ELEMENT.STATISTICAL:
      return {
        titleHeader:
          'For selected trajectories, shows minimum, maximum, average, median and sample size of the chosen attribute.',
        type: 'widgets',
        content: <StaticalValueContent />,
        value: {
          name: 'Statistical',
          operator_attribute: 'current_speed',
          time_mode: 'now',
          history: false,
          colors: []
        }
      }
    case PROGRAMMING_ELEMENT.HEATMAP:
      return {
        titleHeader:
          'Video view overlaid with a representation of a cumulated value for each pixel. The value is determined by the chosen attribute. Based on the selected color scheme, high values appear in hot or more intensive colours and low values in cold or less intensive.',
        type: 'widgets',
        content: <HeatMapContent />,
        value: {
          name: 'Heatmap',
          heatmap_type: 'heatmap',
          operator_attribute: 'occupancy_map',
          time_mode: 'now',
          color_type: 'jet',
          value_range: 'manual',
          opacity: 3,
          dispersion: 3
        }
      }
    case PROGRAMMING_ELEMENT.DISTRIBUTION:
      return {
        titleHeader: 'Representation of a distribution of monitored data as a bar graph or a pie chart.',
        type: 'widgets',
        content: <DistributionContent />,
        value: {
          name: 'Distribution',
          operator_attribute: 'category_distribution',
          attributes: [],
          time_mode: 'now',
          history: false,
          chart: 'bar',
          colors: []
        }
      }
    case PROGRAMMING_ELEMENT.TRAJECTORY:
      return {
        titleHeader:
          'Representation of vehicle trajectories, where the color of a trajectory is determined by its category.',
        type: 'widgets',
        content: <TrajectoryViewContent />,
        value: {
          name: 'Trajectory view',
          operator_attribute: 'trajectory',
          time_mode: 'now'
        }
      }
    case PROGRAMMING_ELEMENT.TABLE:
      return {
        titleHeader:
          'Display details about the passing trajectories: ID, license plate, category, color, trajectory start/end timestamps, speed statistics, duration of occurrence, and stationary duration.',
        type: 'widgets',
        content: <TableContent />,
        value: {
          operator_attribute: 'trajectory',
          name: 'Table',
          time_mode: 'now'
        }
      }
    case PROGRAMMING_ELEMENT.ODMATRIX:
      return {
        titleHeader:
          'The origin-destination matrix displays the number of traffic objects passing from each chosen origin to each destination spatial elements.',
        type: 'widgets',
        content: <ODMatrixContent />,
        value: {
          name: 'ODMatrix',
          origin_element: [],
          destination_element: [],
          time_mode: 'now',
          colors: []
        }
      }
    case PROGRAMMING_ELEMENT.VALUE:
      return {
        titleHeader: 'Representation of a single value determined by the chosen attribute.',
        type: 'widgets',
        content: <ValueContent />,
        value: {
          name: 'Value',
          operator_attribute: 'object_count',
          time_mode: 'now',
          history: false
        }
      }
    default:
      return {
        titleHeader: '',
        type: 'operators',
        content: <></>,
        value: {
          name: ''
        }
      }
  }
}

export const getAreaData = data => {
  switch (data.type) {
    case TYPE_AREA.ZONE:
      return {
        ...data,
        titleHeader: 'Zone filter overview.',
        tab: 'area',
        content: <ZoneContent />,
        value: {
          name: data.name,
          time_mode: 'now'
        }
      }
    case TYPE_AREA.GATE:
      return {
        ...data,
        titleHeader: 'Gate filter overview.',
        tab: 'area',
        content: <GateContent />,
        value: {
          name: data.name,
          direction: 'both',
          time_mode: 'now'
        }
      }
    case TYPE_AREA.MOVEMENT:
      return {
        ...data,
        titleHeader: 'Movement filter overview.',
        tab: 'area',
        content: <></>,
        value: {
          name: data.name
        }
      }
    case TYPE_AREA.TRAFFIC:
      return {
        ...data,
        titleHeader: 'Traffic light detection zone filter overview.',
        tab: 'area',
        content: <TrafficContent />,
        value: {
          name: data.name,
          direction: 'off',
          time_mode: ''
        }
      }
    default:
      return {
        titleHeader: '',
        tab: 'area',
        content: <></>,
        value: {
          name: ''
        }
      }
  }
}

export const getAreaLabel = (data) => {
  switch (data.type) {
    case TYPE_AREA.GATE:
      return <GateLabel data={data} />
    case TYPE_AREA.ZONE:
      return <ZoneLabel data={data} />
    case TYPE_AREA.MOVEMENT:
      return <></>
    case TYPE_AREA.TRAFFIC:
      return <TrafficLabel data={data} />
    default:
      return <></>
  }
}

export const getElementLabel = (data) => {
  switch (data.key) {
    case PROGRAMMING_ELEMENT.SPEED:
      return <SpeedLabel data={data} />
    case PROGRAMMING_ELEMENT.STATIONERY:
      return <StationeryDurationLabel data={data} />
    case PROGRAMMING_ELEMENT.TIME:
      return <TimeOfOccurrenceLabel data={data} />
    case PROGRAMMING_ELEMENT.DURATION:
      return <DurationOfOccurrenceLabel data={data} />
    case PROGRAMMING_ELEMENT.LICENSE_PLATE:
      return <LicensePlateLabel data={data} />
    case PROGRAMMING_ELEMENT.COLOR:
      return <ColorLabel data={data} />
    case PROGRAMMING_ELEMENT.CATEGORY:
      return <CategoryLabel data={data} />
    case PROGRAMMING_ELEMENT.VOLUME:
      return <VolumeLabel data={data} />
    default:
      return <></>
  }
}

