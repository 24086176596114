import PropTypes from 'prop-types'

const TrajectoryTailsIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <path
          d='M-.257 10.612s9.574-.425 16.25-11.023'
          transform='rotate(-20 45.39 -15.43)'
          fill='none'
          strokeWidth='2px'
          strokeLinecap='round'
          stroke={color}
        />
        <path
          d='M0 0s9.551.2 16.053 11.432'
          transform='scale(-1) rotate(13 94.858 -106.352)'
          fill='none'
          strokeWidth='2px'
          strokeLinecap='round'
          stroke={color}
        />
        <path
          d='M0 2.976S9.443 4.63 13.5 0'
          transform='rotate(-20 41.227 -12.35)'
          fill='none'
          strokeWidth='2px'
          strokeLinecap='round'
          stroke={color}
        />
      </g>
    </svg>
  )
}

export default TrajectoryTailsIcon

TrajectoryTailsIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
