import { Box } from '@mui/material'
import { listOperatorAttribute, listTimeModeModal } from '../../../utils/common'
import BaseRadioGroup from '../../../common/BaseRadioGroup'
import { getTimeModeDescription } from '../../../utils/modal'
import { useFormContext } from 'react-hook-form'
import BaseSwitchForm from '../../../common/BaseSwitchForm'
import BaseColorGroupForm from '../../../common/BaseColorGroupForm'

const StaticalValueContent = () => {
  const { watch } = useFormContext()

  const time_mode = watch('time_mode')

  return (
    <Box>
      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listOperatorAttribute}
        name='operator_attribute'
        label='Operator attribute'
      />

      <BaseRadioGroup
        width={500}
        childrenWidth={200}
        listGroup={listTimeModeModal}
        name='time_mode'
        label='Time mode'
      />

      <p className='text-[#444c4d] mt-[12px]'>{getTimeModeDescription(time_mode).text}</p>
      {getTimeModeDescription(time_mode).content}

      <BaseSwitchForm
        name='history'
        title='History'
        description='Visualise widget history as chart.'
        label='Time series visualisation'
      />

      <BaseColorGroupForm name='colors' title='Color' description='Choose the desired color and range values of your widget.' />
    </Box>
  )
}

export default StaticalValueContent
