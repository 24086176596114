import PropTypes from 'prop-types'

const UnknownVehicleIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g transform='translate(-187.977)'>
        <g>
          <path
            d='M-355.778-2683.758h-13.865a1.19 1.19 0 0 1-1.189-1.188v-10.138a1.19 1.19 0 0 1 1.189-1.189h13.865a1.19 1.19 0 0 1 1.189 1.189v10.138a1.19 1.19 0 0 1-1.189 1.188zm-12.539-10.416a.885.885 0 0 0-.884.884v6.552a.885.885 0 0 0 .884.884h11.168a.885.885 0 0 0 .884-.884v-6.552a.885.885 0 0 0-.884-.884z'
            transform='translate(566.688 2700.812)'
            stroke='transparent'
            strokeMiterlimit='10'
            fill={color}
          />
        </g>
        <circle cx='2.618' cy='2.618' r='2.618' transform='translate(197.976 13.485)' fill={color} />
        <circle cx='2.618' cy='2.618' r='2.618' transform='translate(204.794 13.485)' fill={color} />
        <g>
          <path
            d='M201.608 9.191a1.719 1.719 0 0 1 .289-.912 2.225 2.225 0 0 1 .844-.765 2.651 2.651 0 0 1 1.293-.3 2.759 2.759 0 0 1 1.212.253 1.96 1.96 0 0 1 .812.689 1.692 1.692 0 0 1 .287.948 1.462 1.462 0 0 1-.163.7 2.172 2.172 0 0 1-.389.524c-.15.146-.419.394-.807.741a3.312 3.312 0 0 0-.259.258 1.077 1.077 0 0 0-.144.2.939.939 0 0 0-.073.184 6.54 6.54 0 0 0-.079.325.527.527 0 0 1-.563.493.579.579 0 0 1-.415-.161.635.635 0 0 1-.168-.479 1.68 1.68 0 0 1 .45-1.2c.136-.146.319-.321.55-.523q.3-.265.438-.4a1.36 1.36 0 0 0 .227-.3.723.723 0 0 0 .092-.36.837.837 0 0 0-.281-.639 1.029 1.029 0 0 0-.727-.261 1.009 1.009 0 0 0-.768.263 2.1 2.1 0 0 0-.417.774c-.107.357-.31.536-.611.536a.6.6 0 0 1-.447-.187.572.572 0 0 1-.183-.401zm2.312 5.191a.746.746 0 0 1-.5-.187.656.656 0 0 1-.216-.524.674.674 0 0 1 .208-.5.708.708 0 0 1 .512-.2.7.7 0 0 1 .706.7.661.661 0 0 1-.213.521.72.72 0 0 1-.497.19z'
            fill={color}
          />
        </g>
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default UnknownVehicleIcon

UnknownVehicleIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
