import { Fragment } from 'react'
import useModalStore from '../../store/useModalStore'
import AutomaticModal from '../../modals/AutomaticModal'
import useVideoZoneStore from '../../store/useVideoZoneStore'
import useCommonStore from '../../store/useCommonStore'
import useConfirmEditZone from '../../hooks/useConfirmEditZone'

const ModalArea = () => {
  const { statusModal, setStatusModal } = useModalStore()
  const { editZone, setEditZone } = useVideoZoneStore(state => state.editZone)
  const { deletePointsZone } = useVideoZoneStore(state => state.listZone)
  const { setStatus } = useCommonStore()
  const { updateZone, redoZone } = useConfirmEditZone()

  return (
    <Fragment>
      <AutomaticModal
        isOpen={statusModal.confirmEditZone}
        handleClose={() => {
          setStatusModal('confirmEditZone')
        }}
        title='Data loss warning'
        handleAccept={() => {
          updateZone()
          setStatusModal('confirmEditZone')
        }}
        handleUndo={() => {
          redoZone()
          setStatusModal('confirmEditZone')
        }}
      />

      <AutomaticModal
        isOpen={statusModal.confirmDeleteZone}
        handleClose={() => {
          setStatusModal('confirmDeleteZone')
        }}
        title='Data loss warning'
        handleAccept={() => {
          deletePointsZone({
            id: editZone.id
          })
          setEditZone({})
          setStatus('default')
          setStatusModal('confirmDeleteZone')
        }}
        handleUndo={() => setStatusModal('confirmDeleteZone')}
      />
    </Fragment>
  )
}

export default ModalArea
