import PropTypes from 'prop-types'

const TrajectorySettingsIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <path fill='none' d='M0 0h32v32H0z' />
        <path
          d='M31.751 17.672v-3.344a.509.509 0 0 0-.768-.438l-2.745 1.617a.657.657 0 0 0 .023 1.146L31 18.121a.51.51 0 0 0 .751-.449z'
          fill={color}
          transform='translate(0 .001)'
        />
        <g>
          <g>
            <g>
              <path
                d='m26.241 13.635-1.969-2.171a1.121 1.121 0 0 1-.242-.6l-.073-2.253v-.064a.63.63 0 0 0-.629-.629h-2.693a.937.937 0 0 0-.789.5l-2.6 5.512a.618.618 0 0 0 .312.83l.382.169a.631.631 0 0 0 .258.055.659.659 0 0 0 .587-.357L20 12.207v2.431c0 .014-.012.026-.017.041l-2.054 6.433a1.008 1.008 0 0 0 .7 1.285l.137.038a1.064 1.064 0 0 0 .277.036 1.1 1.1 0 0 0 1.045-.756l1.92-6.012.68 1.668a7.029 7.029 0 0 1 .392 1.555L23.41 22a.925.925 0 0 0 .924.848.986.986 0 0 0 .239-.029l.138-.033a1.177 1.177 0 0 0 .868-1.262l-.479-3.746a7.406 7.406 0 0 0-.456-1.66l-.652-1.5c-.008-.02-.02-.038-.029-.056v-1.017l1.172 1.225a.6.6 0 0 0 .883 0l.217-.231a.676.676 0 0 0 .006-.904z'
                transform='translate(.738 -2.825)'
                fill={color}
              />
              <path
                d='M21.176 9a2.1 2.1 0 1 0-2.1-2.1 2.1 2.1 0 0 0 2.1 2.1z'
                transform='translate(1.719 -4.446)'
                fill={color}
              />
            </g>
            <g>
              <g>
                <g>
                  <path
                    d='M14 10.983a4.3 4.3 0 1 0 4.3 4.3 4.3 4.3 0 0 0-4.3-4.3zm2.2 1.606a.272.272 0 0 1 .189-.088.265.265 0 0 1 .2.073 3.755 3.755 0 0 1 1.095 1.943.27.27 0 0 1-.14.295 1.941 1.941 0 0 1-.874.2 1.4 1.4 0 0 1-.973-.325c-.46-.455-.27-1.239.503-2.098zm-4.4 5.428a.267.267 0 0 1-.189.088H11.6a.269.269 0 0 1-.184-.075 3.736 3.736 0 0 1-1.095-1.942.267.267 0 0 1 .14-.295 1.745 1.745 0 0 1 1.847.123c.458.458.268 1.241-.508 2.1zm.509-3.33a1.4 1.4 0 0 1-.973.325 1.941 1.941 0 0 1-.874-.2.268.268 0 0 1-.14-.295 3.734 3.734 0 0 1 1.095-1.943.255.255 0 0 1 .195-.073.267.267 0 0 1 .189.088c.775.859.965 1.643.511 2.098zm2.836 4.178a3.773 3.773 0 0 1-2.29 0 .269.269 0 0 1-.187-.275c.067-.967.64-1.7 1.332-1.7s1.263.73 1.332 1.7a.273.273 0 0 1-.185.275zm-2.22-3.584A1.075 1.075 0 1 1 14 16.356a1.076 1.076 0 0 1-1.073-1.075zM15.334 12c-.068.965-.64 1.7-1.332 1.7s-1.265-.73-1.332-1.7a.269.269 0 0 1 .185-.275 3.778 3.778 0 0 1 2.291 0 .27.27 0 0 1 .188.275zm2.346 4.05a3.755 3.755 0 0 1-1.095 1.943.272.272 0 0 1-.185.073h-.011a.272.272 0 0 1-.189-.088c-.774-.859-.964-1.642-.508-2.1a1.745 1.745 0 0 1 1.847-.123.267.267 0 0 1 .141.291z'
                    transform='translate(-3.158 -1.231)'
                    fill={color}
                  />
                  <path
                    d='M14.738 9.57a6.447 6.447 0 1 0 6.448 6.447 6.454 6.454 0 0 0-6.448-6.447zm0 11.283a4.836 4.836 0 1 1 4.835-4.836 4.842 4.842 0 0 1-4.835 4.836z'
                    transform='translate(-3.894 -1.966)'
                    fill={color}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TrajectorySettingsIcon

TrajectorySettingsIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
