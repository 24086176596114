import PropTypes from 'prop-types'

const ComplementIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <rect width='22.383' height='3.433' rx='1.43' transform='rotate(-45 30.838 3.054)' fill={color} />
    </svg>
  )
}

export default ComplementIcon

ComplementIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
