import { Group, Image, Rect, Text } from 'react-konva'
import PropTypes from 'prop-types'
import useImage from 'use-image'
import { useMemo, useRef } from 'react'

const TagNameArea = ({ centerPoint, zone }) => {
  const iconType = useMemo(() => {
    if (zone.type === 'movement') {
      return {
        padding: 25,
        src: './src/assets/images/V2_Create_Movement.svg'
      }
    }

    return {
      padding: 20,
      src: './src/assets/images/V2_Create_Zone_Gate.svg'
    }
  }, [zone])

  const [img] = useImage(iconType.src)

  // Ref for the text component to measure its width dynamically
  const textRef = useRef()

  const [x, y] = centerPoint
  const iconWidth = 20
  const iconHeight = 20

  // Padding for layout
  const padding = 5

  const textWidth = useMemo(() => {
    if (!zone || !textRef.current) return 60

    return textRef.current.width()
  }, [zone])

  return (
    <Group x={x} y={y}>
      <Rect
        width={iconWidth + textWidth + iconType.padding} // Icon width + Text width + padding
        height={iconHeight + 2 * padding}
        fill='white'
        cornerRadius={5}
        shadowBlur={3}
      />
      <Image image={img} x={padding} y={padding} width={iconWidth} height={iconHeight} />
      <Text
        text={zone.name}
        ref={textRef}
        x={iconWidth + 2 * padding}
        y={padding}
        fontSize={12}
        fill='black'
        padding={5}
        verticalAlign='middle'
      />
    </Group>
  )
}

export default TagNameArea

TagNameArea.propTypes = {
  centerPoint: PropTypes.array.isRequired,
  zone: PropTypes.object.isRequired
}
