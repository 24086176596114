import PropTypes from 'prop-types'

const TableIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g transform='translate(188.658 -1076.164)'>
        <g>
          <rect width='6.958' height='3.558' rx='1.223' transform='translate(-180.658 1085.164)' fill={color} />
          <rect width='3.558' height='3.558' rx='1.223' transform='translate(-172.702 1085.164)' fill={color} />
          <rect width='3.558' height='3.558' rx='1.223' transform='translate(-168.096 1085.164)' fill={color} />
        </g>
        <g>
          <rect width='8.06' height='3.558' rx='1.223' transform='translate(-180.658 1095.722)' fill={color} />
          <rect width='3.558' height='3.558' rx='1.223' transform='translate(-172.114 1095.722)' fill={color} />
          <rect width='3.558' height='3.558' rx='1.223' transform='translate(-168.096 1095.722)' fill={color} />
        </g>
        <rect width='16.12' height='3.558' rx='1.223' transform='translate(-180.658 1090.443)' fill={color} />
      </g>
      <path data-name='Rectangle 410' fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default TableIcon

TableIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
