import PropTypes from 'prop-types'

const GateNegativeDirectionIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Popup_GATE_negaitive direction'
      width='28'
      height='28'
      viewBox='0 0 28 28'
    >
      <g data-name='Group 1161'>
        <path
          data-name='Line 85'
          transform='translate(13.5 4.5)'
          style={{ stroke: '#6d7374', strokeWidth: '1.5px', fill: 'none' }}
          d='M0 0v20'
        />
        <path data-name='Polygon 4' d='m5 0 5 9H0z' transform='rotate(-90 10.5 9)' style={{ fill: '#6d7374' }} />
      </g>
      <path data-name='Rectangle 638' style={{ fill: 'none' }} d='M0 0h28v28H0z' />
    </svg>
  )
}

export default GateNegativeDirectionIcon

GateNegativeDirectionIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
