import PropTypes from 'prop-types'

const V3ValueIcon = ({ color = '#fff' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g transform='translate(188.975 -932.39)'>
        <g>
          <path
            d='M-184.975 955.138v-13.951a.8.8 0 0 1 .8-.8h2.377a.8.8 0 0 1 .8.8v.042a.8.8 0 0 1-.8.8h-.254a.8.8 0 0 0-.8.8V953.5a.8.8 0 0 0 .8.8h.254a.8.8 0 0 1 .8.8v.043a.8.8 0 0 1-.8.8h-2.377a.8.8 0 0 1-.8-.805z'
            fill={color}
          />
          <path
            d='M-160.222 941.187v13.951a.8.8 0 0 1-.8.8h-2.378a.8.8 0 0 1-.8-.8v-.043a.8.8 0 0 1 .8-.8h.255a.8.8 0 0 0 .8-.8v-10.68a.8.8 0 0 0-.8-.8h-.255a.8.8 0 0 1-.8-.8v-.042a.8.8 0 0 1 .8-.8h2.378a.8.8 0 0 1 .8.814z'
            fill={color}
          />
        </g>
        <circle cx='4.702' cy='4.702' r='4.702' transform='translate(-177.3 943.46)' fill={color} />
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default V3ValueIcon

V3ValueIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
