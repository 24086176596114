import PropTypes from 'prop-types'

const GroupOperatorsIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <path fill='none' d='M0 0h32v32H0z' />
      <g>
        <path
          d='M-2000.36-1586.507h-10.788a3.856 3.856 0 0 1-3.852-3.852v-10.788a3.856 3.856 0 0 1 3.852-3.852h10.788a3.856 3.856 0 0 1 3.852 3.852v10.788a3.856 3.856 0 0 1-3.852 3.852zm-2.328-6.412a.439.439 0 0 0-.438.438v2.627a.438.438 0 0 0 .438.437h2.628a.438.438 0 0 0 .438-.437v-2.627a.439.439 0 0 0-.438-.438h-.876v-1.751a.439.439 0 0 0-.438-.438h-3.941v-1.29h3.941a.439.439 0 0 0 .438-.438v-1.752h.876a.438.438 0 0 0 .438-.437v-2.628a.439.439 0 0 0-.438-.438h-2.628a.439.439 0 0 0-.438.438v2.628a.438.438 0 0 0 .438.438h.876v1.314h-3.5v-1.314h.876a.438.438 0 0 0 .438-.437v-2.628a.439.439 0 0 0-.438-.438h-2.628a.438.438 0 0 0-.437.438v2.628a.438.438 0 0 0 .437.438h.877v1.314h-3.5v-1.314h.877a.438.438 0 0 0 .437-.437v-2.628a.438.438 0 0 0-.437-.438h-2.628a.439.439 0 0 0-.438.438v2.628a.438.438 0 0 0 .438.438h.876v1.752a.439.439 0 0 0 .438.438h3.941v1.29h-3.941a.439.439 0 0 0-.438.438v1.751h-.876a.439.439 0 0 0-.438.438v2.627a.438.438 0 0 0 .438.437h2.628a.438.438 0 0 0 .437-.437v-2.627a.438.438 0 0 0-.437-.438h-.877v-1.314h3.5v1.314h-.877a.438.438 0 0 0-.437.438v2.627a.438.438 0 0 0 .438.437h2.628a.438.438 0 0 0 .438-.437v-2.627a.439.439 0 0 0-.438-.438h-.876v-1.314h3.5v1.314z'
          transform='translate(2021.754 1611.753)'
          fill={color}
          stroke='transparent'
          strokeMiterlimit='10'
        />
      </g>
    </svg>
  )
}

export default GroupOperatorsIcon

GroupOperatorsIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
