import { Box, TextField } from '@mui/material'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'

export const TitleContentModalCommon = ({ title, isWidget, isFilterShow }) => {
  const { control } = useFormContext()
  return (
    <Box>
      <p className='text-[#444c4d]'>{title}</p>
      {isFilterShow && (
        <Box mt='24px'>
          <p className='text-[#444c4d] font-bold text-[18px]'>{isWidget ? 'Widget' : 'Filter'} name</p>
          <Controller
            control={control}
            name='name'
            render={({ field: { onChange, value, ref } }) => (
              <TextField
                variant='outlined'
                ref={ref}
                className='!mt-[10px]'
                size='small'
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Box>
      )}
    </Box>
  )
}

TitleContentModalCommon.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired,
  isWidget: PropTypes.bool.isRequired,
  isFilterShow: PropTypes.bool.isRequired
}
