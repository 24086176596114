import DistributionIcon from '../assets/icons/DistributionIcon'
import HeatMapIcon from '../assets/icons/HeatMapIcon'

export const getProgrammingElementIcon = icon => {
  switch (icon) {
    case 'distribution':
      return <DistributionIcon />
    case 'heatmap':
      return <HeatMapIcon />
    default:
      return ''
  }
}

export const getAvaragePoint = points => {
  let totalX = 0
  let totalY = 0
  for (let i = 0; i < points.length; i += 2) {
    totalX += points[i]
    totalY += points[i + 1]
  }
  return {
    x: totalX / (points.length / 2),
    y: totalY / (points.length / 2)
  }
}

export const getDistance = (node1, node2) => {
  let diffX = Math.abs(node1[0] - node2[0])
  let diffY = Math.abs(node1[1] - node2[1])
  const distaneInPixel = Math.sqrt(diffX * diffX + diffY * diffY)
  return Number.parseFloat(distaneInPixel).toFixed(2)
}

export const dragBoundFunc = (stageWidth, stageHeight, vertexRadius, pos) => {
  let x = pos.x
  let y = pos.y
  if (pos.x + vertexRadius > stageWidth) x = stageWidth
  if (pos.x - vertexRadius < 0) x = 0
  if (pos.y + vertexRadius > stageHeight) y = stageHeight
  if (pos.y - vertexRadius < 0) y = 0
  return { x, y }
}

export const dragBoundEditFunc = (stageHeight, vertexRadius, pos) => {
  let x = pos.x
  let y = pos.y

  if (pos.x - vertexRadius < 0) x = 0
  if (pos.y + vertexRadius > stageHeight) y = stageHeight
  if (pos.y - vertexRadius < 0) y = 0
  return { x, y }
}

export const minMax = points => {
  return points.reduce((acc, val) => {
    acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0]
    acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1]
    return acc
  }, [])
}

export function getPolygonPointCenter(points) {
  // Calculate geometric centroid
  let centroidX = 0
  let centroidY = 0
  points.forEach(point => {
    centroidX += point[0]
    centroidY += point[1]
  })
  centroidX /= points.length
  centroidY /= points.length

  // Calculate bounding box
  let minX = Infinity,
    maxX = -Infinity,
    minY = Infinity,
    maxY = -Infinity
  points.forEach(point => {
    minX = Math.min(minX, point[0])
    maxX = Math.max(maxX, point[0])
    minY = Math.min(minY, point[1])
    maxY = Math.max(maxY, point[1])
  })
  const boundingBoxCenterX = (minX + maxX) / 2
  const boundingBoxCenterY = (minY + maxY) / 2

  // Compute a weighted average of the centroid and bounding box center
  const weight = 1 // Adjust as needed. 0.5 gives equal weight to both.
  const labelX = centroidX * (1 - weight) + boundingBoxCenterX * weight
  const labelY = centroidY * (1 - weight) + boundingBoxCenterY * weight

  return [labelX - 40, labelY - 20]
}
