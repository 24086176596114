import { Typography } from '@mui/material'
import extendedDayJs from '../../../utils/dayjs'

const StatusVideo = () => {
  return (
    <div className='flex items-center justify-between w-full h-full'>
      <div className='flex items-center gap-[10px] h-full'>
        <div className='w-3 h-3 bg-blue-500 rounded-full' />
        <Typography>Running</Typography>
      </div>
      <Typography>1554 cached trajectories {extendedDayJs().format('HH:mm:ss DD/MM/YYYY')}</Typography>
    </div>
  )
}

export default StatusVideo
