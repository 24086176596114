import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import BaseSetting from '../BaseSetting'
import { listMenu2 } from '../../../../utils/sidebar'

const ImageSetting = ({ handleChangeImageZoom }) => {
  const menuSetting = useMemo(() => {
    return listMenu2.map(item => ({
      ...item,
      action: () => handleChangeImageZoom(item.key)
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <BaseSetting listData={menuSetting} />
}

ImageSetting.propTypes = {
  handleChangeImageZoom: PropTypes.func.isRequired
}

export default memo(ImageSetting)
