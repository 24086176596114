import { Box } from '@mui/material'
import BaseSetTextField from '../../common/BaseSetTextField'

const OffsetContent = () => {
  return (
    <Box mt='12px'>
      <BaseSetTextField label='Start (before now):' />
      <BaseSetTextField label='End (before now):' />
    </Box>
  )
}

export default OffsetContent
