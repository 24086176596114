import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Stage, Layer, Line, Image, Rect } from 'react-konva'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import useVideoZoneStore from '../../store/useVideoZoneStore'
import Zone from './Zone'
import Gate from './Gate'
import PropTypes from 'prop-types'
import useCommonStore from '../../store/useCommonStore'
import AreaEdit from './AreaEdit'
import Footer from './Footer'
import SideBarVideo from './SideBarVideo'
import { useDrawZG } from '../../hooks/useDrawZG'
import Movement from './Movement'
import { Box } from '@mui/material'
import Konvas from 'konva'
import useImage from 'use-image'
import useModalStore from '../../store/useModalStore'
import ModalArea from './ModalArea'
import Traffic from './Traffic'
import TrafficDraw from './Traffic/TrafficDraw'
import { isPointGate, isPointInPolygon } from '../../utils/common'
import SelectedZonePopUp from './SelectedZonePopUp'
import RightArrowIcon from '../../assets/icons/RightArrowIcon'
import LeftArrowIcon from '../../assets/icons/LeftArrowIcon'

const elementWidth = 1200
const elementHeight = 800

// const listImage = [
//   'https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg',
//   'https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg',
//   'https://imgv3.fotor.com/images/blog-cover-image/part-blurry-image.jpg',
//   'https://www.freecodecamp.org/news/content/images/2022/09/jonatan-pie-3l3RwQdHRHg-unsplash.jpg',
//   'https://statusneo.com/wp-content/uploads/2023/02/MicrosoftTeams-image551ad57e01403f080a9df51975ac40b6efba82553c323a742b42b1c71c1e45f1.jpg',
//   'https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg'
// ]

const VideoZone = props => {
  const [image] = useImage(
    'https://statusneo.com/wp-content/uploads/2023/02/MicrosoftTeams-image551ad57e01403f080a9df51975ac40b6efba82553c323a742b42b1c71c1e45f1.jpg',
    'Anonimus'
  )

  const { drawType, dotIndexDelete, setDotIndexDelete, hideZone, setHideZone, setDrawType, displaySetting } =
    useVideoZoneStore(state => state.paramsZone)
  const { listZones, listSelected, setListSelected } = useVideoZoneStore(state => state.listZone)
  const { isEdit, setIsEdit } = useVideoZoneStore(state => state.statusZone)
  const { editZone, memoryEditZone, setEditZone } = useVideoZoneStore(state => state.editZone)

  const {
    isOpenSideBarMenu,
    isPointerEventNone,
    updateStatusPointerEvent,
    setStatus,
    setIsOpenSideBarMenu,
    isFullFlow,
    setIsOpenFullFlow
  } = useCommonStore()
  const { setStatusModal } = useModalStore()

  const { setLineDraw, handleFinishDraw } = useDrawZG()

  const isDrawing = useRef(false)
  const layer1Ref = useRef()

  const [lines, setLines] = useState([])
  const [pointsTraffic, setPointsTraffic] = useState([])

  const listZoneFilter = useMemo(() => {
    return listZones.filter(zone => displaySetting[zone.type])
  }, [displaySetting, listZones])

  const handleMouseDown = event => {
    if (drawType === '' || drawType === 'traffic') return

    if (!isPointerEventNone) updateStatusPointerEvent(true)

    isDrawing.current = true
    const pos = event.target.getStage().getPointerPosition()

    setLines([...lines, { points: [pos.x, pos.y] }])
    setLineDraw([pos])
  }

  const handleMouseMove = e => {
    if (!isDrawing.current || drawType === '') return

    const stage = e.target.getStage()
    const point = stage.getPointerPosition()
    let lastLine = lines[lines.length - 1]

    lastLine.points = lastLine.points.concat([point.x, point.y])

    lines.splice(lines.length - 1, 1, lastLine)

    setLines(lines.concat())
    setLineDraw(prev => [...prev, point])
  }

  const handleMouseUp = e => {
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()

    if (isPointerEventNone) updateStatusPointerEvent(false)

    if (isEdit) {
      setIsEdit(false)
    }

    isDrawing.current = false
    if (drawType === '' && !editZone.id) {
      const list = []

      listZoneFilter.forEach(item => {
        const checkPolygon =
          item.type === 'gate' ? isPointGate(point, item.pointsLine) : isPointInPolygon(point, item.pointsLine)
        if (checkPolygon) {
          list.push(item)
        }
      })

      if (list.length === 1) {
        setStatus('edit')
        setEditZone(list[0])
        setListSelected({
          pos: { x: 0, y: 0 },
          list: []
        })
      } else {
        setListSelected({ pos: point, list: list })
      }

      return
    }

    if (drawType === '') return

    if (drawType === 'traffic') {
      setPointsTraffic(prev => [...prev, point])
      if (pointsTraffic.length === 3) {
        handleFinishDraw({
          isTraffic: true,
          mousePos: [...pointsTraffic, point]
        })
        setPointsTraffic([])
      }
      return
    }

    if (lines[0].points.length < 3) {
      setDrawType('')
      setLines([])
      return
    }

    if (lines.length > 0) setLines([])

    handleFinishDraw({
      isTraffic: false,
      mousePos: point
    })
  }

  const resizableElementRef = useRef(null)
  const imageRef = useRef()

  // const [image, setImage] = useState(null)
  // const [elementWidth, setElementWidth] = useState(1200)
  // const [elementHeight, setElementHeight] = useState(800)

  // useEffect(() => {
  //   const element = resizableElementRef.current

  //   const updateSize = () => {
  //     if (element) {
  //       const width = element.offsetWidth
  //       const height = element.offsetHeight
  //       setElementWidth(width)
  //       setElementHeight(height)
  //     }
  //   }

  //   updateSize()
  //   // window.addEventListener('resize', updateSize)

  //   // return () => {
  //   //   window.removeEventListener('resize', updateSize)
  //   // }
  // }, [isOpenSideBarMenu])

  // useEffect(() => {
  //   const image = new window.Image()
  //   const handleSetImate = () => {
  //     image.src = listImage[Math.floor(Math.random() * (listImage.length - 0 + 1)) + 0]
  //     image.style.filter = 'blur(5px)'
  //     image.onload = () => {
  //       // Khi hình ảnh đã tải, bạn có thể sử dụng nó trong canvas
  //       setImage(image)
  //     }
  //   }

  //   handleSetImate()
  // }, [])

  const handleMouseEnter = () => {
    if (hideZone !== '') {
      setHideZone('')
    }
  }

  useEffect(() => {
    if (editZone.id) {
      layer1Ref.current.moveToTop()
    } else {
      layer1Ref.current.moveToBottom()
    }
  }, [editZone])

  const handleLayerMouseUp = e => {
    if (dotIndexDelete !== -1) {
      setDotIndexDelete(-1)
      return
    }

    const clickedInsideLine = e.target.findAncestor('Group')

    if (!clickedInsideLine) {
      setStatusModal('confirmEditZone')
    }
  }

  const [scaleZoom, setScaleZoom] = useState(1)

  const rects = useMemo(() => {
    const rects = []
    for (let ix = 0; ix < elementWidth / 25; ix++) {
      for (let iy = 0; iy < (elementHeight - 300) / 25; iy++) {
        rects.push(
          <Rect
            key={`${ix}-${iy}`} // Đảm bảo có key duy nhất cho mỗi Rect
            fill='#00000038'
            x={ix * 25}
            y={iy * 25}
            width={25 - 1}
            height={25 - 1}
            stroke='white'
          />
        )
      }
    }
    return rects
  }, [])

  useEffect(() => {
    if (image) {
      imageRef.current.cache({ pixelRatio: displaySetting['anonymization'] ? 0.5 : 1 })
    }
  }, [image, displaySetting])

  const renderComponent = (
    <Stage
      width={elementWidth}
      height={elementHeight}
      style={{ backgroundColor: 'gray' }}
      onMouseDown={handleMouseDown}
      onMousemove={handleMouseMove}
      onMouseup={handleMouseUp}
      onMouseEnter={handleMouseEnter}
    >
      <Layer ref={layer1Ref} onMouseUp={handleLayerMouseUp}>
        <Rect perfectDrawEnabled={false} fill='#00000038' x={0} y={0} width={elementWidth} height={elementHeight} />
        {editZone.id && <AreaEdit zone={editZone} handleDragEnd={props.handleDragEnd} />}
        {memoryEditZone.id && <AreaEdit zone={memoryEditZone} handleDragEnd={props.handleDragEnd} />}
      </Layer>
      <Layer>
        <Image
          filters={[Konvas.Filters.Blur]}
          blurRadius={displaySetting['anonymization'] ? 10 : 0}
          image={image}
          ref={imageRef}
          x={0}
          y={0}
          width={elementWidth}
          height={elementHeight}
        />
        {displaySetting['grid'] && rects}

        {listZoneFilter.map(zone => {
          if (zone.type === 'gate') {
            return <Gate key={zone.id} zone={zone} handleDragEnd={props.handleDragEnd} />
          } else if (zone.type === 'zone') {
            return <Zone key={zone.id} zone={zone} handleDragEnd={props.handleDragEnd} />
          } else if (zone.type === 'movement') {
            return <Movement key={zone.id} zone={zone} handleDragEnd={props.handleDragEnd} />
          } else {
            return <Traffic key={zone.id} zone={zone} handleDragEnd={props.handleDragEnd} />
          }
        })}

        {pointsTraffic.length > 0 && <TrafficDraw pointsTraffic={pointsTraffic} />}

        {lines.map((line, i) => (
          <Line
            key={i}
            points={line.points}
            stroke='#ffffff59'
            strokeWidth={3}
            tension={0.5}
            lineCap='round'
            lineJoin='round'
            perfectDrawEnabled={false}
            globalCompositeOperation={line.tool === 'eraser' ? 'destination-out' : 'source-over'}
          />
        ))}

        {listSelected.list.length > 1 && <SelectedZonePopUp />}
      </Layer>
    </Stage>
  )

  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 })
  const [transformState, setTransformState] = useState({
    scale: 1,
    positionX: 0,
    positionY: 0
  })

  const [wrapperKey, setWrapperKey] = useState(Date.now())
  useEffect(() => {
    const handleResize = () => {
      const positionX = (resizableElementRef.current.offsetWidth - elementWidth) / 2
      const positionY = (resizableElementRef.current.offsetHeight - elementHeight) / 2
      setInitialPosition({
        x: (resizableElementRef.current.offsetWidth - elementWidth) / 2,
        y: (resizableElementRef.current.offsetHeight - elementHeight) / 2
      })
      setTransformState({
        scale: 1,
        positionX: positionX,
        positionY: positionY
      })
      setWrapperKey(Date.now())
    }

    handleResize()
  }, [])

  const handleToggleSideBar = useCallback(() => {
    const defChange = isOpenSideBarMenu ? 75 : -75

    setInitialPosition({
      x: transformState.positionX + defChange,
      y: transformState.positionY
    })
    setTransformState({
      ...transformState,
      positionX: transformState.positionX + defChange
    })
    setWrapperKey(Date.now())
  }, [transformState, isOpenSideBarMenu])

  const handleToggleFlow = useCallback(() => {
    setIsOpenFullFlow(!isFullFlow)
    const defChange = isFullFlow ? 250 : -250

    setInitialPosition({
      x: transformState.positionX + defChange,
      y: transformState.positionY
    })
    setTransformState({
      ...transformState,
      positionX: transformState.positionX + defChange
    })
    setWrapperKey(Date.now())
  }, [transformState, isFullFlow, setIsOpenFullFlow])

  return (
    <div className='w-full overflow-hidden h-full relative'>
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#000',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box className='mb-auto relative' style={{ flex: 1 }} ref={resizableElementRef}>
          <Box className='inset-0 absolute'>
            <TransformWrapper
              key={wrapperKey}
              panning={{ disabled: isEdit || drawType !== '', lockAxisY: scaleZoom <= 1 }}
              minScale={0.5}
              initialScale={scaleZoom}
              initialPositionY={initialPosition.y}
              initialPositionX={initialPosition.x}
              onZoomStop={ref => {
                setScaleZoom(ref.state.scale)
                setTransformState({
                  scale: ref.state.scale,
                  positionX: ref.state.positionX,
                  positionY: ref.state.positionY
                })
              }}
              doubleClick={{ disabled: true }}
              centerZoomedOut={true}
            >
              {(
                utils // Read property from package https://www.npmjs.com/package/react-zoom-pan-pinch
              ) => (
                <>
                  {!editZone.id && <SideBarVideo utils={utils} />}
                  <TransformComponent
                    wrapperStyle={{ width: '100%', height: '100%' }}
                    contentStyle={{ width: `${elementWidth}px`, height: `${elementHeight}px` }}
                  >
                    {renderComponent}
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          </Box>
        </Box>
        <Footer />
      </div>
      <div
        className='absolute cursor-pointer z-50 top-[50%] left-0 px-1 py-5 bg-white -translate-y-[50%] shadow-md transform rounded-tr-md rounded-br-md'
        onClick={() => {
          setIsOpenSideBarMenu(!isOpenSideBarMenu)
          handleToggleSideBar()
        }}
      >
        {!isOpenSideBarMenu ? <RightArrowIcon width={10} height={10} /> : <LeftArrowIcon width={10} height={10} />}
      </div>
      <div
        className='absolute cursor-pointer z-50 top-[50%] right-0 px-1 py-5 bg-white -translate-y-[50%] shadow-md transform rounded-tl-md rounded-bl-md'
        onClick={() => {
          handleToggleFlow()
        }}
      >
        {isFullFlow ? <RightArrowIcon width={10} height={10} /> : <LeftArrowIcon width={10} height={10} />}
      </div>
      <ModalArea />
    </div>
  )
}

export default VideoZone

VideoZone.propTypes = {
  handleDragEnd: PropTypes.func
}
