import { produce } from 'immer'

export const createListZone = set => ({
  listZones: [],
  listSelected: {
    pos: { x: 0, y: 0 },
    list: []
  },

  setListSelected: value =>
    set(
      produce(state => {
        state.listZone.listSelected = value
      })
    ),
  setListZones: value =>
    set(
      produce(state => {
        state.listZone.listZones = [{ ...value }, ...state.listZone.listZones]
      })
    ),
  deletePointsZone: ({ id }) =>
    set(
      produce(state => {
        state.listZone.listZones = [...state.listZone.listZones].filter(item => item.id !== id)
      })
    ),
  moveToTopListZone: ({ id }) =>
    set(
      produce(state => {
        const items = [...state.listZone.listZones].slice()
        const item = items.find(i => i.id === id)
        const index = items.indexOf(item)

        items.splice(index, 1)
        // add to the top
        items.push(item)

        state.listZone.listZones = items
      })
    ),
  finishEditZone: ({ id, value }) =>
    set(
      produce(state => {
        state.listZone.listZones = [...state.listZone.listZones].map(item => {
          if (item.id === id) {
            return value
          }

          return item
        })
      })
    ),
  updateNameZone: ({ id, name }) =>
    set(
      produce(state => {
        state.listZone.listZones = [...state.listZone.listZones].map(item => {
          if (item.id === id) {
            return {
              ...item,
              name
            }
          }

          return item
        })
      })
    ),
  updateDirectionGate: ({ id }) =>
    set(
      produce(state => {
        state.listZone.listZones = [...state.listZone.listZones].map(item => {
          if (item.id === id) {
            return {
              ...item,
              direction: item.direction === 'positive' ? 'negative' : 'positive'
            }
          }

          return item
        })
      })
    ),
  editMovementListZone: ({ id, key, value }) =>
    set(
      produce(state => {
        state.listZone.listZones = [...state.listZone.listZones].map(item => {
          if (item.id === id) {
            if (key === 'direction') {
              return {
                ...item,
                direction: item.direction === 'right' ? 'reserve' : 'right'
              }
            } else {
              return {
                ...item,
                [key]: value
              }
            }
          }

          return item
        })
      })
    )
})
