import DashboardIcon from './assets/icons/DashboardIcon'
import DefinitionIcon from './assets/icons/DefinitionIcon'
import EventsHistoryIcon from './assets/icons/EventsHistoryIcon'
import LogOutIcon from './assets/icons/LogOutIcon'
import NotificationIcon from './assets/icons/NotificationIcon'
import ParametersConfigIcon from './assets/icons/ParametersConfigIcon'
import Definition from './screens/Definition/Definition'
import HomeScreen from './screens/Home/Home'

export const routes = [
  {
    id: 1,
    label: 'definition',
    icon: color => <DefinitionIcon color={color} />,
    path: '/definition',
    element: <Definition />
  },
  {
    id: 2,
    label: 'dashboard',
    element: <HomeScreen />,
    icon: color => <DashboardIcon color={color} />,
    path: '/'
  },
  {
    id: 3,
    label: 'parameters_config',
    element: <HomeScreen />,
    icon: color => <ParametersConfigIcon color={color} />,
    path: '/config'
  },
  {
    id: 4,
    label: 'events_history',
    element: <HomeScreen />,
    icon: color => <EventsHistoryIcon color={color} />,
    path: '/eventsHistory'
  },
  {
    id: 5,
    label: 'Notification',
    element: <HomeScreen />,
    icon: color => <NotificationIcon color={color} />,
    path: '/notification'
  },
  {
    id: 6,
    label: 'Log out',
    element: <HomeScreen />,
    icon: color => <LogOutIcon color={color} />,
    path: '/logout'
  }
]
