import PropTypes from 'prop-types'

const PedestrianIcon = ({ color = '#6d7374' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g>
        <path
          d='m169.87 12.117-1.547-1.706a.878.878 0 0 1-.189-.469l-.057-1.771V8.12a.5.5 0 0 0-.495-.495h-2.116a.738.738 0 0 0-.62.393l-2.039 4.331a.486.486 0 0 0 .244.653l.3.132a.5.5 0 0 0 .2.043.52.52 0 0 0 .461-.281l.955-1.9V12.9c0 .011-.01.021-.014.033l-1.614 5.054a.791.791 0 0 0 .551 1.01l.108.03a.834.834 0 0 0 .217.028.867.867 0 0 0 .821-.594l1.508-4.723.535 1.31a5.481 5.481 0 0 1 .307 1.223l.256 2.412a.727.727 0 0 0 .726.666.8.8 0 0 0 .188-.023l.109-.026a.923.923 0 0 0 .681-.991l-.379-2.941a5.793 5.793 0 0 0-.359-1.305l-.508-1.173c-.006-.015-.016-.029-.023-.044v-.8l.922.963a.475.475 0 0 0 .694 0l.17-.181a.531.531 0 0 0 .007-.711z'
          fill={color}
          transform='translate(-150.381)'
        />
        <path
          d='M166.661 7.206a1.651 1.651 0 1 0-1.65-1.651 1.652 1.652 0 0 0 1.65 1.651z'
          fill={color}
          transform='translate(-150.381)'
        />
      </g>
      <path fill='none' d='M0 0h32v32H0z' />
    </svg>
  )
}

export default PedestrianIcon

PedestrianIcon.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
